<template>
    <div
        v-if="Show"
        class="banner"
        id="notice-tick"
        @click="$bvModal.show('update-sponsor')"
    >
        <div
            :style="{ '--moveIt': isOverflow }"
            :class="{ isOverflow: isOverflow }"
        >
            Update your sponsor now to get the most out of our website
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'StringBanner',
    data() {
        return {
            width: 0,
            scrollWidth: 0,
        };
    },
    computed: {
        ...mapGetters({
            UserInfo: 'info/UserInfo',
        }),
        isOverflow() {
            if (this.width < this.scrollWidth) {
                return `translateX(-${this.scrollWidth - this.width}px)`;
            }
            return false;
        },
        Show() {
            return this.UserInfo && this.UserInfo.sponsors && this.UserInfo.sponsors.length === 1;
        },
    },
    methods: {
        handleResize() {
            const el = document.getElementById('notice-tick');
            if (el) {
                this.width = el.offsetWidth;
                this.scrollWidth = el.scrollWidth;
            }
        },
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    unmounted() {
        window.removeEventListener('resize', this.handleResize);
    },
};
</script>

<style lang="scss" scoped>
.banner {
    background-color: #fecaca;
    color: #431407;
    font-weight: 600;
    text-align: center;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    > div {
        display: inline-block;
        white-space: nowrap;
        padding-right: 40px;
        right: 0px;
    }
}

.isOverflow {
    animation: scroll 10s linear infinite;
}
@keyframes scroll {
    20% {
        transform: translateX(0);
    }
    100% {
        transform: var(--moveIt);
    }
}
</style>
