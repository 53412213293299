<template>
  <div class="container py-5">
    <div class="presale">
      <div class="main-content">
        <div class="main-content__header">
          <div v-if="PresaleInfo" class="heading__primary">
            <h1>
              {{ PresaleInfo.name }}
            </h1>
            <div class="price">
              <span class="d-none d-sm-inline">
                {{ $t('currentPrice') }}:

              </span>
              {{
                TruncateToDecimals2(
                  Number(CheckPresale.price) /
                  Number(CheckPresale.token),
                  '',
                  5,
                )
              }}
              <img width="30px" src="@/assets/images/logo/usdt.png" />
              = 1
              <img width="30px" src="@/assets/images/logo/ech.png" />
            </div>
          </div>
          <h1 v-else class="heading__primary">N/A</h1>

          <!-- <h1 class="heading__primary">
            {{ PresaleInfo.name }}
          </h1> -->

          <!-- <h2 class="heading__secondary">{{ $t('presale.day') }} 1: {{ $t('presale.startIn') }}</h2> -->
        </div>

        <CountDown />

        <BuyPackage />

        <BenefitPackage />

        <NoteBuy />

        <img src="@/assets/images/background/presale_coin_3.png" alt="presale" class="decoration decoration__coin3" />
        <img src="@/assets/images/background/presale_coin_5.png" alt="presale" class="decoration decoration__coin5" />
        <img src="@/assets/images/background/presale_coin_2.png" alt="presale" class="decoration decoration__coin2" />
        <img src="@/assets/images/background/presale_coin_4.png" alt="presale" class="decoration decoration__coin4" />
        <img src="@/assets/images/background/presale_coin_6.png" alt="presale" class="decoration decoration__coin6" />
      </div>

      <XNode />

      <PurchaseHistory />
      <Revenue />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CountDown from '@/components/presale/CountDown.vue';
import BuyPackage from '@/components/presale/BuyPackage.vue';
import BenefitPackage from '@/components/presale/BenefitPackage.vue';
import NoteBuy from '@/components/presale/NoteBuy.vue';
import PurchaseHistory from '@/components/presale/PurchaseHistory.vue';
import Revenue from '@/components/presale/Revenue.vue';
import XNode from '@/components/presale/XNode.vue';

export default {
  data() {
    return {
      usdtValue: 0,
    };
  },
  created() { },
  methods: {},
  computed: {
    ...mapGetters({
      UserInfo: 'info/UserInfo',
      PresaleInfo: 'presale/PresaleInfo',
      CheckPresale: 'presale/CheckPresale',
    }),
  },
  watch: {
    UserInfo: {
      handler() {
        this.$store.dispatch('member/req_getDownline');
      },
    },
  },
  mounted() {
    const self = this;
    if (self.UserInfo) {
      this.$store.dispatch('member/req_getDownline');
    }

    this.$store.dispatch('presale/req_getInfoPreSales').then(() => {
      if (this.PresaleInfo._id) {
        this.$store.dispatch('presale/req_getPostCheckToken', {
          id: this.PresaleInfo._id,
        });
      }
    });
  },
  components: {
    CountDown,
    BuyPackage,
    BenefitPackage,
    NoteBuy,
    PurchaseHistory,
    Revenue,
    XNode
  },
};
</script>

<style scoped lang="scss">
.heading__primary {
  font-size: 18px;
  font-weight: 700;
  color: #aaff95;
  text-transform: uppercase;
  text-align: center;

  h1 {
    font-size: 1.25em;
  }

  .price {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  @media (min-width: 768px) {
    font-size: 30px;
  }
}

.heading__secondary {
  font-size: 22px;
  font-weight: 600;
  color: #fff;
}

.heading__tertiary {
  font-size: 18px;
  font-weight: 600;
  color: #fff;
}

.presale {
  display: flex;
  flex-direction: column;
  gap: 44px;
  width: 100%;
}

.main-content {
  padding: 32px 74px;
  display: grid;
  gap: 40px;
  justify-items: center;
  background: linear-gradient(#2c572d, #2c572d) padding-box,
    linear-gradient(to left, #a6ed8d, #078c04) border-box;
  border-radius: 8px;
  border: 2px solid transparent;
  position: relative;

  &__header {
    display: grid;
    justify-items: center;
    gap: 4px;
  }

  .decoration {
    position: absolute;
    pointer-events: none;

    &__coin3 {
      top: 0;
      left: 0;
    }

    &__coin2 {
      top: 0;
      right: 5%;
    }

    &__coin5 {
      bottom: 0;
      right: 0;
    }

    &__coin4 {
      bottom: 35%;
      right: 5%;
    }

    &__coin6 {
      bottom: 10%;
      left: 0;
    }
  }
}

.btn__primary {
  background: linear-gradient(to left, #8bf04d, #2e6d08);
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  padding: 12px 24px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  outline: none;

  p {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 500px) {
  .presale {
    gap: 24px;
  }

  .main-content {
    padding: 12px 24px;
    gap: 24px;
  }
}
</style>
