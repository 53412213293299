const axios = require('../axios.config').default;

export default {
    namespaced: true,
    state: () => ({
        nodeList: [],
        nodeStat: {},
        userNode: [],
        userGift: [],
        giftFilter: {
            page: 1,
            limit: 10,
            type: 'nodegift',
        },
        nodeFilter: {
            page: 1,
            limit: 10,
        },
        maxOut: null,
        user: null
    }),
    getters: {
        NodeList: (state) => state.nodeList,
        NodeStat: (state) => state.nodeStat,
        UserNode: (state) => state.userNode,
        UserGift: (state) => state.userGift,
        GiftFilter: (state) => state.giftFilter,
        NodeFilter: (state) => state.nodeFilter,
        MaxOut: (state) => state.maxOut
    },
    actions: {
        async get_nodeInfo({ commit }) {
            return axios.get('/mining/node-settings').then((res) => {
                if (res && res.data) {
                    commit('SET_NODE_LIST', res.data);
                }
            });
        },
        async post_buyNode(context, input) {
            console.log('input', input);
            return axios.post('/mining/buy-node', input).then((res) => {
                console.log('in the zone', res.data);
                return res.data;
            });
        },
        async get_nodeStat({ commit }) {
            return axios.get('/mining/statictical-node').then((res) => {
                if (res && res.data) {
                    commit('SET_NODE_STAT', res.data);
                }
            });
        },
        async get_userNodes({ commit, getters }) {
            return axios
                .get('/mining/user-node', { params: getters.NodeFilter })
                .then((res) => {
                    if (res && res.data) {
                        commit('SET_USER_NODE', res.data);
                    }
                });
        },
        async post_buySlot(context, input) {
            return axios.post('/user/buy-slot', input).then((res) => {
                return res.data;
            });
        },
        async get_userGift({ getters, commit }) {
            return axios
                .get('/user/activity-history', {
                    params: getters.GiftFilter,
                })
                .then((res) => {
                    if (res.status && res.data?.results) {
                        commit('SET_USER_GIFT', res.data);
                    }
                });
        },
        async post_gift(context, input) {
            return axios.post('/mining/send-node-gift', input).then((res) => {
                console.log(res);
                return res;
            });
        },
        async send_node(context, input) {
            return axios.post('/mining/send-node', input).then((res) => {
                return res;
            });
        },
        async activate_node(context, input) {
            return axios.post('/mining/active-node', input).then((res) => {
                return res;
            });
        },
        async check_promotion() {
            return axios.get('/mining/check-buy-promotion');
        },
        async claim() {
            return axios.get('/mining/claim')
        },
        async max_out({ commit }) {
            return axios.get('/mining/max-out').then((res) => {
                if(res && res.status) {
                    commit('SET_MAX_OUT', res.data)
                }
            })
        },
        async register_user({ commit }, payload) {
          return axios.post('/masternode/register', {
            email: payload.email,
            phone: payload.phone,
            address: payload.address,
            description: payload.description
          }).then((res) => {
            if (res) {
              if (res.data) {
                commit('SET_USER', res.data);
              }
            } else {
              console.error('Request failed');
            }
            return res;
          }).catch((error) => {
            console.error(error);
          });
        },
    },
    mutations: {
        SET_NODE_LIST(state, data) {
            state.nodeList = data;
        },
        SET_NODE_STAT(state, data) {
            state.nodeStat = data;
        },
        SET_USER_NODE(state, data) {
            state.userNode = data;
        },
        SET_USER_GIFT(state, data) {
            state.userGift = data;
        },
        SET_GIFT_FILTER(state, data) {
            state.giftFilter = { ...state.giftFilter, ...data };
        },
        SET_NODE_FILTER(state, data) {
            state.nodeFilter = { ...state.nodeFilter, ...data };
        },
        SET_MAX_OUT(state, data) {
            console.log('Max out:',data)
            state.maxOut = data
        },
        SET_USER(state, payload) {
          state.user = payload;
        },
    },
};
