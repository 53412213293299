<template>
    <b-modal id="language-modal" centered hide-footer hide-header>
        <svg
            @click="$bvModal.hide('language-modal')"
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            viewBox="0 0 24 24"
        >
            <path
                fill="currentColor"
                d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12z"
            />
        </svg>
        <h3>{{ $t('language') }}</h3>
        <div
            class="input-container"
            v-for="lang in AvailableLangs"
            :key="lang.value"
        >
            <label>
                <strong>
                    <img :src="icons[lang.value]" alt="" />
                    {{ lang.text }}
                </strong>
                <span>
                    <input
                        v-model="$root.$i18n.locale"
                        @change="setLocale"
                        :value="lang.value"
                        type="radio"
                    />
                </span>
            </label>
        </div>
    </b-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import ru from '@/assets/images/icons/lang/ru.png';
import ko from '@/assets/images/icons/lang/ko.png';
import ja from '@/assets/images/icons/lang/ja.png';
import en from '@/assets/images/icons/lang/en.png';
import vi from '@/assets/images/icons/lang/vi.png';
import zh from '@/assets/images/icons/lang/zh.png';

export default {
    name: 'LanguageModal',
    data: () => ({
        icons: {
            ru,
            ko,
            ja,
            en,
            zh,
            vi,
        },
    }),
    computed: {
        ...mapGetters({
            AvailableLangs: 'core/AvailableLangs',
        }),
    },
    methods: {
        setLocale() {
            window.$cookies.set('lang', this.$root.$i18n.locale);
        },
    },
};
</script>

<style lang="scss">
#language-modal {
    .modal-content {
        position: relative;

        .modal-body {
            border-radius: 12px;

            h3 {
                color: #fff;
                text-align: center;
                font-size: 30px;
                margin-bottom: 15px;
            }

            .input-container {
                label {
                    display: flex;
                    justify-content: space-between;
                    cursor: pointer;
                    padding: 5px;
                    border-radius: 4px;
                    transition: 0.05s;
                    color: #fff;

                    &:hover {
                        background-color: rgba(0, 136, 204, 0.2);
                    }

                    input {
                        margin: 0;
                        min-width: 30px;
                        width: unset;
                        cursor: pointer;
                        height: 20px;
                    }

                    > span {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        font-weight: 500;
                    }

                    strong {
                        display: flex;
                        align-items: center;
                        gap: 8px;

                        img {
                            height: 30px;
                        }
                    }
                }
            }

            svg {
                position: absolute;
                font-size: 30px;
                top: 10px;
                right: 10px;
                cursor: pointer;
                color: #fff;
            }
        }
    }

    @media (max-width: 375px) {
        .modal-content {
            .modal-body {
                h3 {
                    margin-top: 15px;
                }
            }
        }
    }
}
</style>
