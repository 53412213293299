<template>
  <div class="container py-5">
    <div class="dashboard">
      <!-- <div class="banner" @click="redirectPresale">
        <img src="@/assets/images/background/banner-new.png" alt="banner" />
      </div> -->
      <Banner />
      <div class="main-content">
        <div class="detail-container">
          <Detail title="USDT" :numberValue="BalanceStatistic.usdt" icon="usdt" link="#" />
          <Detail :title="this.$i18n.t('dashboardPage.nftPackage')" :numberValue="BalanceStatistic.nft" icon="none" link="#" />
          <Detail title="ECH" :numberValue="BalanceStatistic.total" icon="token" link="#" />
          <Detail :title="this.$i18n.t('dashboardPage.commission')" :numberValue="BalanceStatistic.commission" icon="token" link="#" />
        </div>

        <Statistic />
      </div>

      <div class="list-buyers">
        <ListBuyers />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';


export default {
  data() {
    return {
      usdtValue: 0,
    };
  },
  created() {
  },
  methods: {
    redirectPresale() {
      this.$router.push({ name: 'Presale' })
    },
  },
  computed: {
    ...mapGetters({
      BalanceStatistic: 'presale/BalanceStatistic',
    }
    ),
  },
  watch: {
  },
  mounted() {
    this.$store.dispatch('presale/req_getBalanceStatistic');
  },
  components: {
    Detail: () => import('@/components/dashboard/Detail.vue'),
    Statistic: () => import('@/components/dashboard/Statistic.vue'),
    ListBuyers: () => import('@/components/dashboard/ListBuyers.vue'),
    Banner: () => import('@/components/dashboard/Banner.vue'),
  },
};
</script>

<style scoped lang="scss">
.dashboard {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;

  .banner {
    cursor: pointer;

    img {
      width: -webkit-fill-available;
    }
  }

  .main-content {
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 16px;
  }

  .detail-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
  }
}

@media screen and (max-width: 1024px) {
  .dashboard {
    gap: 16px;

    .main-content {
      grid-template-columns: 1fr;
    }

    .detail-container {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media screen and (max-width: 500px) {
  .dashboard {
    .detail-container {
      grid-template-columns: 1fr;
    }
  }
}
</style>
