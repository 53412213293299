<template>
  <div class="container py-5">
    <div class="dashboard">
      <div class="main-content">
        <Vesting :title="this.$i18n.t('vesting.totalBought')" :numberValue="VestingInfo.claimable" icon="usdt" link="#" />
        <Vesting :title="this.$i18n.t('vesting.totalClaimable')" :numberValue="VestingInfo.amountClaim" icon="usdt" link="#" />
        <Vesting :title="this.$i18n.t('vesting.totalClaimed')" :numberValue="VestingInfo.claimed" icon="usdt" link="#" />
      </div>

      <div class="list-buyers">
        <VestingTable />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';


export default {
  data() {
    return {
      usdtValue: 0,
    };
  },
  created() {
  },
  methods: {
  },
  computed: {
    ...mapGetters({
      BalanceStatistic: 'presale/BalanceStatistic',
      VestingInfo: 'presale/VestingInfo',
    }
    ),
  },
  watch: {
  },
  mounted() {
    this.$store.dispatch('presale/req_getBalanceStatistic');
    this.$store.dispatch('presale/req_getVestingInfo');
  },
  components: {
    VestingTable: () => import('@/components/vesting/VestingTable.vue'),
    Vesting: () => import('@/components/vesting/VestingItem.vue')
  },
};
</script>

<style scoped lang="scss">
.dashboard {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;

  .banner {
    img {
      width: -webkit-fill-available;
    }
  }

  .main-content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
  }

}

@media screen and (max-width: 1024px) {
  .dashboard {
    gap: 16px;

    .main-content {
      grid-template-columns: 1fr;
    }

    .detail-container {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@media screen and (max-width: 500px) {
  .dashboard {
    .detail-container {
      grid-template-columns: 1fr;
    }
  }
}
</style>
