<template>
  <div class="vesting">
    <div class="vesting__body">
      <div class="vesting__body--head">
        <label for="usdt">
          {{ title }}
        </label>

        <!-- <button class="btn__icon" @click="$bvModal.show('claim-confirm-old')" v-if="title === this.$i18n.t('vesting.totalClaimable')">
          <p>
            {{ $t('vesting.claimECH') }}
          </p>
        </button> -->
      </div>

      <div class="input-wrapper">
        <img class="icon" src="@/assets/images/icons/buy_token.png" />

        <input id="usdt" type="string" placeholder="0.0" :value="TruncateToDecimals2(numberValue)" disabled />
      </div>
    </div>

    <b-modal v-if="title === this.$i18n.t('vesting.totalClaimable')" id="claim-confirm-old" centered hide-footer hide-header size="md">
      <svg @click="$bvModal.hide('claim-confirm-old')" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
        viewBox="0 0 24 24" class="icon-close">
        <path fill="currentColor"
          d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12z" />
      </svg>
      <div class="buy__confirm">
        <p>Are you sure?</p>

        <div class="buy__buttons">
          <button class="btn__primary" @click="claimAction">Yes</button>
          <button class="btn__primary" @click="$bvModal.hide('claim-confirm')">No</button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    numberValue: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    link: {
      type: String,
      default: '',
    },
  },
  methods: {
    claimAction() {
      this.$store.dispatch('presale/req_getClaimToken');
      this.$bvModal.hide('claim-confirm');
    },
  },
};
</script>

<style scoped lang="scss">
.vesting {
  padding: 16px;
  color: white;
  background: linear-gradient(#2c572d, #2c572d) padding-box,
    linear-gradient(to left, #A6ED8D, #078C04) border-box;
  border-radius: 8px;
  border: 2px solid transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 32px;

    &--head {
      display: flex;
      justify-content: space-between;
    }

    label {
      color: #fff;
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 0;
    }
  }
}

.img-hidden {
  display: none;
  visibility: hidden;
}

.btn__icon {
  background: linear-gradient(to left, #8BF04D, #2E6D08);
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  padding: 6px 12px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  display: flex;
  gap: 6px;
  align-items: center;
  justify-content: center;
  outline: none;

  p {
    margin-bottom: 0;
  }

  img {
    transform: translate(0, 20%);
  }
}

.btn__primary {
  background: linear-gradient(to left, #8BF04D, #2E6D08);
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  padding: 12px 24px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  outline: none;

  p {
    margin-bottom: 0;
  }
}

.input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  background-color: #16381A;
  border-radius: 8px;
  padding: 2px 10px;
}

.input-wrapper .icon {
  margin-right: 10px;
}

input {
  background-color: transparent;
  border: none;
  color: white;
  flex-grow: 1;
  text-align: end;
}

input:focus {
  border: none;
  outline: none;
}

input::placeholder {
  color: white;
  font-weight: 600;
  font-size: 16px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>


<style lang="scss">
#claim-confirm {
  .modal-content .modal-body {
  position: relative;
  border-radius: 8px;
  background-color: white;
  .icon-close {
    position: absolute;
    top: 5%;
    right: 3%;
    cursor: pointer;
  }

  .buy {
    &__confirm {
      p {
        font-size: 18px;
        font-weight: 600;
      }
    }

    &__buttons {
      display: flex;
      gap: 16px;
      justify-content: center;
    }
  }
}
}
</style>
