<script>
import { mapGetters, mapMutations } from 'vuex';

const axios = require('@/store/axios.config').default;

export default {
    data() {
        return {
            search: {
                page: 1,
                limit: 10,
            },
            withdrawAmount: 0,
            fields: [
                {
                    key: 'id',
                    sortable: false,
                    label: 'Hash',
                    class: 'text-center',
                },
                {
                    key: 'code',
                    label: 'Method',
                    sortable: false,
                    class: 'text-center',
                },
                {
                    key: 'loginTime',
                    label: 'dd/mm/yyy , time',
                },
            ],
            currentPage: 1,
            totalPages: 10,
            address: '0xc6ed69214CE2884d6C4D620CB1916321c4190865',
            isUpdateAvatar: false,
            qrActive: 1,
            isChose: '',
            currentPassword: null,
            newPassword: null,
            showCurrentPass: false,
            showNewPass: false,
            sponsor: null,
            confirmPassword: null,
            showConfirmPassword: null,
            masternodeId: null,
            fieldsMasterNode: [
                {
                    key: 'userId',
                    label: 'userId',
                },
                {
                    key: 'email',
                    label: 'email',
                },
                {
                    key: 'showDetail',
                    label: 'more',
                },
            ],
            unsubscribe: null,
            walletAddress: [],
        };
    },
    computed: {
        ...mapGetters({
            UserInfo: 'info/UserInfo',
            BalanceSystem: 'info/Balance',
            Rates: 'wallet/Rates',
            Login: 'history/Login',
            WalletConfig: 'core/WalletConfig',
            AvailableLangs: 'core/AvailableLangs',
            Master: 'info/Master',
            MasterNodeList: 'masternode/MasterNodeList',
            MasterNode: 'masternode/MasterNode',
            Address: 'info/Address',
            isNetWork: 'contract/isNetWork',
        }),
        ChainID() {
            if (this.isNetWork === 'testnet') {
                return 97;
            }
            return 56;
        },
        netWorkConfig() {
            const testNet = {
                chainId: '0x61',
                chainName: 'Smart Chain - Testnet',
                nativeCurrency: {
                    name: 'Binance Coin',
                    symbol: 'BNB',
                    decimals: 18,
                },
                rpcUrls: [
                    'https://data-seed-prebsc-2-s2.binance.org:8545/',
                    'https://data-seed-prebsc-1-s1.binance.org:8545/',
                    'https://data-seed-prebsc-5-s5.binance.org:8545/',
                    'https://data-seed-prebsc-3-s3.binance.org:8545/',
                    'https://data-seed-prebsc-4-s4.binance.org:8545/',
                ],
                blockExplorerUrls: ['https://testnet.bscscan.com'],
            };
            const mainNet = {
                chainId: '0x38',
                chainName: 'Smart Chain',
                nativeCurrency: {
                    name: 'Binance Coin',
                    symbol: 'BNB',
                    decimals: 18,
                },
                rpcUrls: [
                    'https://bsc-dataseed.binance.org/',
                    'https://bsc-dataseed1.defibit.io/',
                    'https://bsc-dataseed1.ninicoin.io/',
                ],
                blockExplorerUrls: ['https://bscscan.com'],
            };
            if (this.isNetWork === 'testnet') {
                return testNet;
            }
            return mainNet;
        },
        AddressShow() {
            const { address } = this.UserInfo;
            // if (!address) return null;
            // const stringShort = `${address.slice(0, 7)}...${address.slice(
            //     address.length - 10,
            //     address.length,
            // )}`;
            return address;
        },
        NoSponsor() {
            return this.UserInfo.sponsors && this.UserInfo.sponsors.length <= 1;
        },
        NoMasterXNode() {
            return !this.UserInfo.masterId && !this.Master;
        },
    },
    methods: {
        ...mapMutations(['onLoad', 'outLoad']),
        onCopy() {
            this.$toastr.s('Copy Link Referral Success', 'Successfully');
        },
        onError() {
            this.$toastr.e(
                'Copy Link Referral Fail,Please Try Again',
                'Failed',
            );
        },
        onChangePage(payload) {
            this.search.page = payload;
            this.$store.dispatch('history/req_getHistoryLogin', this.search);
        },
        resetWithdraw() {
            this.withdrawAmount = 0;
        },
        openWithdraw() {
            this.$bvModal.show('withdraw');
        },
        async onWithdraw() {
            this.onLoad();
            if (this.withdrawAmount < 1) {
                this.$toastr.w('Please enter amount greater than 0', 'Error');
                this.outLoad();
                return;
            }
            try {
                const txData = await window.ethereum.request({
                    method: 'personal_sign',
                    params: [this.UserInfo.address, 'Confirm Withdraw'],
                });
                await axios.post('/balance/withdraw', {
                    amount: this.withdrawAmount,
                    sign: txData,
                });
                this.$store.dispatch('info/req_getInfo');
                this.$bvModal.hide('withdraw');
            } catch (error) {
                this.$toastr.e(error, 'Error');
            }
            // this.submitted = false;

            this.outLoad();
        },
        updatePassword() {
            if (this.confirmPassword === this.newPassword) {
                this.$store
                    .dispatch('auth/updatePassword', {
                        currentPassword: this.currentPassword,
                        newPassword: this.newPassword,
                    })
                    .then((res) => {
                        if (res.status) {
                            this.$store.commit('auth/LOGOUT_SUCCESS');
                            this.$router.push({ name: 'Login' });
                        }
                    });
            } else {
                this.$toastr.e(this.$i18n.t('passwordNotMatch'));
            }
        },
        toggleShowCurrent() {
            this.showCurrentPass = !this.showCurrentPass;
        },
        toggleShowNew() {
            this.showNewPass = !this.showNewPass;
        },
        async onConnectMetaMask() {
            this.$bvModal.show('update-wallet-modal');
            try {
                this.walletAddress = await window.ethereum.request({
                    method: 'eth_requestAccounts',
                });
            } catch (error) {
                this.outLoad();
                if (
                    error.message ===
                    'Already processing eth_requestAccounts. Please wait.'
                ) {
                    this.$toastr.e('Please Connect Metamask To Join', 'Oops!');
                    return false;
                }
                this.$toastr.e(error.message, 'Oops!');
                return false;
            }
            if (window.ethereum.chainId !== this.ChainID) {
                const params = this.netWorkConfig;
                window.ethereum
                    .request({
                        method: 'wallet_addEthereumChain',
                        params: [params],
                    })
                    .then(() => console.log('add network success'))
                    .catch(() => {
                        this.showNotification = true;
                        this.message = {
                            content:
                                'Please switch To The  Binance Smartchain Network!',
                            failed: true,
                            title: 'Oops...',
                        };
                        window.ethereum.request({
                            method: 'wallet_switchEthereumChain',
                            params: [{ chainId: this.ChainID }],
                        });
                    });
            }
            if (this.walletAddress.length < 0) {
                return false;
            }
            this.$store.commit('info/SET_ADDRESS', {
                address: this.walletAddress[0],
            });
            this.$store.dispatch('auth/updateWallet', {
                address: this.walletAddress[0],
            });
            console.log('Address: ', this.walletAddress);
            return true;
        },
        async updateWallet() {
            const txData = await window.ethereum.request({
                method: 'personal_sign',
                params: [this.Address, this.$store.state.auth.sign],
            });
            console.log(this.$store.state.auth.sign);
            this.$store.dispatch('auth/updateWallet', {
                address: this.Address,
                sign: txData,
                password: this.currentPassword,
            });
        },
    },
};
</script>
<template>
    <b-container class="wallet-tab">
        <!-- change avatar -->
        <div
            class="avatar"
            @mouseenter="isUpdateAvatar = true"
            @mouseleave="isUpdateAvatar = false"
        >
            <img src="~@/assets/images/logo/logoV3.png" width="100%" alt="" />
        </div>
        <!-- information user -->
        <div class="info-user">
            <div class="name">
                <span>ID: {{ UserInfo.refId }}</span>
            </div>
            <div
                class="phone"
                v-if="UserInfo.phone && UserInfo.phone.length > 0"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    viewBox="0 0 256 256"
                >
                    <path
                        fill="currentColor"
                        d="m221.59 160.3l-47.24-21.17a14 14 0 0 0-13.28 1.22a4.81 4.81 0 0 0-.56.42l-24.69 21a1.88 1.88 0 0 1-1.68.06c-15.87-7.66-32.31-24-40-39.65a1.91 1.91 0 0 1 0-1.68l21.07-25a6.13 6.13 0 0 0 .42-.58a14 14 0 0 0 1.12-13.27L95.73 34.49a14 14 0 0 0-14.56-8.38A54.24 54.24 0 0 0 34 80c0 78.3 63.7 142 142 142a54.25 54.25 0 0 0 53.89-47.17a14 14 0 0 0-8.3-14.53M176 210c-71.68 0-130-58.32-130-130a42.23 42.23 0 0 1 36.67-42h.23a2 2 0 0 1 1.84 1.31l21.1 47.11a2 2 0 0 1 0 1.67l-21.11 25.06a4.73 4.73 0 0 0-.43.57a14 14 0 0 0-.91 13.73c8.87 18.16 27.17 36.32 45.53 45.19a14 14 0 0 0 13.77-1c.19-.13.38-.27.56-.42l24.68-21a1.92 1.92 0 0 1 1.6-.1l47.25 21.17a2 2 0 0 1 1.21 2A42.24 42.24 0 0 1 176 210"
                    />
                </svg>
                <span>{{ UserInfo.phone || 'N/A' }}</span>
            </div>
            <div class="email">
                <img src="~@/assets/images/icons/email.svg" alt="" />
                <span>{{ UserInfo.email || 'N/A' }}</span>
            </div>
            <div class="email" v-if="UserInfo.masterId">
                Master XNode:
                <span>{{ UserInfo.masterId }}</span>
            </div>
            <b-button
                variant="info"
                class="button-qrLogin"
                @click="onGetQR"
                v-if="false"
            >
                <img src="~@/assets/images/icons/show-qr.svg" alt="" /> Show QR
            </b-button>
        </div>
        <div class="update-btns">
            <button
                class="open-modal-btn nice-gradient"
                v-b-modal.modal-update-pass
            >
                {{ $t('updatePassword') }}
            </button>
            <button
                v-if="NoSponsor"
                class="open-modal-btn nice-gradient"
                v-b-modal.update-sponsor
            >
                {{ $t('updateSponsor') }}
            </button>
            <b-modal
                dialog-class="update-pass-modal"
                id="modal-update-pass"
                :title="$t('updatePassword')"
                centered
                hide-footer
                hide-header
            >
                <h3>{{ $t('updatePassword') }}</h3>

                <!-- Current password -->
                <form @submit.prevent="updatePassword">
                    <div class="current-pass">
                        <input
                            :type="showCurrentPass ? 'text' : 'password'"
                            :placeholder="$t('currentPassword')"
                            v-model="currentPassword"
                            autocomplete="current-password"
                            required
                        />
                        <img
                            v-if="!showCurrentPass"
                            @click="toggleShowCurrent"
                            class="eye-open"
                            src="../../assets/images/icons/ph--eye-fill.svg"
                            alt=""
                        />
                        <img
                            v-else
                            @click="toggleShowCurrent"
                            class="eye-close"
                            src="../../assets/images/icons/ph--eye-slash-fill.svg"
                            alt=""
                        />
                    </div>

                    <!-- New password -->
                    <div class="new-pass">
                        <input
                            :type="showNewPass ? 'text' : 'password'"
                            :placeholder="$t('newPassword')"
                            v-model="newPassword"
                            autocomplete="new-password"
                            required
                        />
                        <img
                            v-if="!showNewPass"
                            @click="toggleShowNew"
                            class="eye-open"
                            src="../../assets/images/icons/ph--eye-fill.svg"
                            alt=""
                        />
                        <img
                            v-else
                            @click="toggleShowNew"
                            class="eye-close"
                            src="../../assets/images/icons/ph--eye-slash-fill.svg"
                            alt=""
                        />
                    </div>

                    <div class="new-pass">
                        <input
                            :type="showConfirmPassword ? 'text' : 'password'"
                            :placeholder="$t('confirmNewPassword')"
                            v-model="confirmPassword"
                            autocomplete="new-password"
                            required
                        />
                        <img
                            v-if="!showConfirmPassword"
                            @click="showConfirmPassword = !showConfirmPassword"
                            class="eye-open"
                            src="../../assets/images/icons/ph--eye-fill.svg"
                            alt=""
                        />
                        <img
                            v-else
                            @click="showConfirmPassword = !showConfirmPassword"
                            class="eye-close"
                            src="../../assets/images/icons/ph--eye-slash-fill.svg"
                            alt=""
                        />
                    </div>
                    <div class="update-btn">
                        <button class="nice-gradient" type="submit">{{ $t('update') }}</button>
                    </div>
                </form>

                <!-- Close button x -->
                <button
                    class="close"
                    @click="$bvModal.hide('modal-update-pass')"
                    type="button"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="1em"
                        height="1em"
                        viewBox="0 0 24 24"
                    >
                        <path
                            fill="currentColor"
                            d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z"
                        />
                    </svg>
                </button>
            </b-modal>
            <b-modal
                dialog-class="update-wallet-modal"
                id="update-wallet-modal"
                centered
                hide-footer
                hide-header
            >
                <h3>Update Wallet</h3>
                <!-- Current password -->
                <form @submit.prevent="updateWallet">
                    <div class="address">
                        <strong>Address</strong>: {{ this.walletAddress[0] }}
                    </div>
                    <!-- <div class="current-pass">
                        <input v-model="currentPassword" type="password" placeholder="Fill Password" />
                    </div> -->
                    <div class="input-pw">
                        <input
                            :type="showCurrentPass ? 'text' : 'password'"
                            :placeholder="$t('password')"
                            id="password"
                            v-model="currentPassword"
                            autocomplete="current-password"
                        />
                        <img
                            v-if="!showCurrentPass"
                            @click="toggleShowCurrent"
                            class="eye-open"
                            src="../../assets/images/icons/ph--eye-fill.svg"
                            alt=""
                        />
                        <img
                            v-else
                            @click="toggleShowCurrent"
                            class="eye-close"
                            src="../../assets/images/icons/ph--eye-slash-fill.svg"
                            alt=""
                        />
                    </div>
                    <div class="update-btn">
                        <button class="nice-gradient" type="submit">Update</button>
                    </div>
                </form>
                <!-- Close button x -->
                <button
                    class="close"
                    @click="$bvModal.hide('update-wallet-modal')"
                    type="button"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="1em"
                        height="1em"
                        viewBox="0 0 24 24"
                    >
                        <path
                            fill="currentColor"
                            d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z"
                        />
                    </svg>
                </button>
            </b-modal>
        </div>
        <div class="wallet">
            <div class="heading-section mb-4">
                <div class="text">{{ $t('wallet') }}</div>
            </div>
            <div class="wallet-address">
                <span class="address">{{ AddressShow }}</span>
            </div>
        </div>
        <div class="user-balance">
            <div class="user-balance-box">
                <div class="user-balance-box_title">
                    <img src="@/assets/images/logo/usdt.png" />
                    USDT
                </div>
                <div class="user-balance-box_body">
                    <div class="balance-box-main">
                        <span class="balance-box-main-amount">{{
                            TruncateToDecimals2(UserInfo.USDT, '', 4)
                        }}</span>
                        <span class="balance-box-main-symbol">USDT</span>
                    </div>
                    <div class="balance-box-sub">
                        <span
                            v-if="Rates[0].rate"
                            class="balance-box-sub-amount"
                        >
                            ~${{
                                TruncateToDecimals2(
                                    UserInfo.USDT * Rates[0].rate,
                                    '',
                                    4,
                                )
                            }}
                        </span>
                        <span class="balance-box-sub-symbol">ECH</span>
                    </div>
                </div>
                <!-- <div class="user-balance-box_footer"></div> -->
            </div>
            <div class="user-balance-box">
                <div class="user-balance-box_title">
                    <img src="@/assets/images/logo/ech.png" />
                    ECH
                </div>
                <div class="user-balance-box_body">
                    <div class="balance-box-main">
                        <span class="balance-box-main-amount">{{
                            TruncateToDecimals2(UserInfo.ech, '', 4)
                        }}</span>
                        <span class="balance-box-main-symbol">ECH</span>
                    </div>
                    <div class="balance-box-sub">
                        <span v-if="Rates[1]" class="balance-box-sub-amount">
                            ~${{
                                TruncateToDecimals2(
                                    UserInfo.ech * Rates[1].rate,
                                    '',
                                    4,
                                )
                            }}
                        </span>
                        <span class="balance-box-sub-symbol">USDT</span>
                    </div>
                </div>
            </div>
        </div>
    </b-container>
</template>
<style lang="scss" scoped>
.user-balance {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    overflow: hidden;
    gap: 15px;

    &-box {
        border-radius: 22px;
        background: linear-gradient(#2c572d, #2c572d) padding-box,
            linear-gradient(to left, #a6ed8d, #078c04) border-box;
        border: 2px solid transparent;
        flex: 1 1 0%;
        width: 100%;
        max-width: 465px;
        min-width: min(300px, 100%);
        padding: 15px 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        &_title {
            color: #fff;
            padding-bottom: 10px;
            border-bottom: 1px solid #fff;
            font-size: 22px;
            font-style: italic;
            font-weight: 500;
            line-height: 150%;
            /* 33px */
            letter-spacing: 0.3px;

            img {
                height: 25px;
                width: 25px;
            }
        }

        &_body {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .balance-box {
                &-main {
                    color: #aaff87;
                    font-size: 24px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 150%;
                    /* 36px */
                    letter-spacing: 0.3px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    &-amount {
                        flex: 1 1 0%;
                        text-align: left;
                    }

                    &-symbol {
                        flex: 1 1 0%;
                        text-align: right;
                    }
                }

                &-sub {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    &-amount {
                        flex: 1 1 0%;
                        text-align: left;
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 150%;
                        /* 27px */
                        letter-spacing: 0.3px;
                        color: #a6a6a6;
                    }

                    &-symbol {
                        flex: 1 1 0%;
                        text-align: right;
                        color: #aaff87;
                        font-size: 16px;
                        font-style: italic;
                        font-weight: 600;
                        line-height: 150%;
                        /* 36px */
                        letter-spacing: 0.3px;
                    }
                }
            }
        }

        &_footer {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 50px;

            button {
                background: linear-gradient(
                    50deg,
                    #0087c8 14.98%,
                    #00d1ff 106.07%
                );
                color: #ffffff;
                max-width: 170px;
                width: 100%;
                height: 42px;
                border-radius: 267px;
                font-weight: 600;
                font-size: 18px;
                white-space: nowrap;
                display: -webkit-box;
                display: -webkit-flex;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -webkit-align-items: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: center;
                -webkit-justify-content: center;
                -ms-flex-pack: center;
                justify-content: center;
                text-transform: capitalize;
                border: none;
            }
        }

        .text-content {
            font-size: 1.125em;
            color: #078c04;
            font-weight: 700;
            display: flex;
            justify-content: space-between;
            gap: 5px;

            > span {
                min-width: 0;
                word-wrap: break-word;
            }

            > div:last-child {
                flex-shrink: 0;
            }
        }
    }
}

.left-cnt {
    position: relative;
    width: 100%;
    background: transparent;
    border: 1px solid #aaff87;
    margin: 15px 0;
    padding: 10px 10px;
    display: flex;
    justify-content: space-between;

    &::before {
        content: '';
        position: absolute;
        width: 6px;
        height: 6px;
        top: 0;
        right: 0;
        z-index: 5;
        background: #aaff87;
    }

    &::after {
        z-index: 5;
        content: '';
        position: absolute;
        width: 6px;
        height: 6px;
        bottom: 0;
        left: 0;
        background: #aaff87;
    }

    .land-hero {
        padding-right: 2rem !important;
        padding: 10px 0 10px 10%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        a {
            text-align: center;
        }

        .divide-line {
            position: relative;
            width: 31px;
            height: 1px;
            background: #aaff87;
            margin: 35px auto;
        }

        .land {
            max-width: 65px;
            width: 100%;
            height: 35px;
            position: relative;
        }

        .hero {
            position: relative;
            max-width: 46px;
            width: 100%;
            height: 56px;
        }

        .text {
            position: relative;

            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 26px;
            margin-bottom: 0;
            color: #dcdcdc;
            text-align: center;
        }
    }

    .bottom {
        color: white;
    }

    .balance-body {
        position: relative;
        display: block;
        width: 100%;
        align-items: center;
        padding: 20px 10px;
        min-height: calc(100% - 85px);
        color: #aaff87;

        .balance-body-left {
            flex: 0 0 calc(100% - 120px);

            .balance-deposit {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 5px 10%;

                button {
                    background: linear-gradient(
                        90deg,
                        #00ffa9 0%,
                        #00b1ff 100%
                    );
                    border-radius: 0;
                    width: 150px;
                    height: 45px;
                    padding: 5px;
                    color: #000;
                    font-weight: 600;
                    text-transform: capitalize;
                }
            }
        }
    }

    .title-img {
        width: 29px;
        height: 29px;

        img {
            width: 100%;
            height: auto;
            object-fit: scale-down;
        }
    }

    .balance-title {
        color: #ffff;
        padding: 0 0 10px 10%;
        font-weight: 600;
        font-size: 1.2em;
        font-family: 'HHBI';
        position: relative;

        // text-align: center;
        &::after {
            content: '';
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2px;
            background: #aaff87;
            position: absolute;
            font-weight: 600;
        }
    }
}

.wallet-tab {
    .info-user {
        margin: 20px auto 20px;
        text-align: center;

        .name {
            span {
                color: #fff;
                font-weight: 600;
                font-size: clamp(1em, 2.5vw, 1.5em);
            }
        }

        .phone {
            margin: 20px 0;

            svg {
                color: #01c2fe;
                font-size: 30px;
            }

            span {
                text-align: center;
                color: #fff;
                font-size: 20px;
                font-weight: 550;
                font-family: 'helvetica';
            }
        }

        .email {
            margin: 20px 0px;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                margin-right: 10px;
            }

            span {
                text-align: center;
                color: #fff;
                font-size: clamp(1em, 2.5vw, 1.25em);
                font-weight: 550;
                font-family: 'helvetica';
                letter-spacing: 1px;
                display: block;
                word-wrap: break-word;
            }
        }

        .button-qrLogin {
            background: rgba(0, 255, 255, 0.16);
            border: none;
            border-radius: 30px;
            width: 150px;
            height: 45px;
            padding: 5px;
            margin: 0px auto 0;
            color: #aaff87;
            text-transform: capitalize;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                margin-right: 10px;
            }

            &:hover {
                transform: scale(1.05);
            }
        }
    }

    .avatar {
        width: 150px;
        height: 150px;
        background: #fff;
        border-radius: 50%;
        margin: 10px auto 0;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px;
        position: relative;

        img {
            object-fit: contain;
            border-radius: 100vw;
            height: 100%;
            width: 100%;
            border: 2px solid #aaff87;
            background-color: #032f47;
        }

        .update-avatar {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
            transition: all 0.3s ease-in-out;
            width: 100%;
            height: 100%;
            border: 2px solid #00b1ff;
            border-radius: 50%;
            background: rgba($color: #000000, $alpha: 0.7);
            display: flex;
            align-items: center;
            justify-content: center;

            button {
                margin: 0 auto;
                width: 90px;
                display: flex;
                justify-content: center;
                height: 30px;
                align-items: center;
                font-size: 18px;
                font-family: 'HHBI';
                color: #aaff87;
                background: #00172a;
                border: 1px solid #aaff87;
                border-radius: 10px;
                padding: 5px;

                &:hover {
                    background: #aaff87;
                    color: #00172a;
                }
            }
        }

        .camera {
            position: absolute;
            bottom: 0;
            right: 0;

            img {
                border: none;
            }
        }
    }

    .title {
        color: #aaff87;
        font-family: 'Helvetica';
        font-size: clamp(1.6em, 4vw, 1.9em);
        padding-bottom: 10px;
        position: relative;
        letter-spacing: 0.6px;
        width: max-content;
        margin: 25px 0;
        font-weight: 900;

        &::after {
            content: '';
            bottom: 0;
            left: 0;
            width: calc(80% + 10px);
            height: 2px;
            background: #aaff87;
            position: absolute;
            font-weight: 600;
        }
    }

    .wallet-address {
        margin-bottom: 25px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        @media (max-width: 991px) {
            flex-wrap: wrap;
        }

        .address {
            color: #fff;
            font-size: 1.2em;
            font-weight: bold;
            margin-right: 15px;

            @media (max-width: 991px) {
                text-align: center;
                margin-bottom: 10px;
                width: 100%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }

        a {
            margin: 0 5px;
            cursor: pointer;
            background: linear-gradient(180deg, #0effff 0%, #124de3 100%);
            color: #fff;

            img {
                height: 1.2em;
                width: auto;
            }

            &:hover {
                transform: scale(1.1);
            }
        }
    }

    .list-box-wallet {
        display: flex;
        width: 100%;

        .box-dark {
            background: linear-gradient(180deg, #00172a61 0%, #00000b29 100%);
            // border: 1px solid #aaff87;
            -webkit-backdrop-filter: blur(19px);
            backdrop-filter: blur(19px);
            flex: 1;
            margin: 15px;

            .balance-title {
                color: #ffff;
                padding: 10px 0 10px 10%;
                font-weight: 600;
                font-size: 1.2em;
                position: relative;

                // text-align: center;
                &::after {
                    content: '';
                    bottom: 0;
                    left: 0;
                    width: calc(80% + 10px);
                    height: 2px;
                    background: #aaff87;
                    position: absolute;
                    font-weight: 600;
                }
            }

            .balance-detail {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                padding: 20px 10%;

                .top {
                    color: #aaff87;
                    font-weight: 600;
                    font-size: 1.5em;
                }

                .bottom {
                    color: #ffff;
                    font-weight: 600;
                    font-size: 0.9em;
                    font-style: italic;
                }
            }
        }

        .balance-currencies {
            // margin-left: 0;
            margin: 15px;
            width: max-content;
            flex: 0 0 350px;
        }

        .balance-hgb {
            .balance-body {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 20px 10px;
                min-height: calc(100% - 85px);
            }
        }

        .balance-body-left {
            flex: 0 0 calc(100% - 120px);

            .balance-deposit {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 5px 10%;

                button {
                    background: linear-gradient(
                        90deg,
                        #00ffa9 0%,
                        #00b1ff 100%
                    );
                    border-radius: 0;
                    width: 150px;
                    height: 45px;
                    padding: 5px;
                    color: #000;
                    font-weight: 600;
                    text-transform: capitalize;
                }
            }
        }

        .balance-body-right {
            flex: 0 0 120px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px;

            img {
                width: 100px;
                height: 100px;
            }
        }

        .balance-game {
            flex: 0 0 150px;
            width: 150px;
            margin: 15px;

            // height: 150px;
            .land {
                margin: 0;
                margin-bottom: 15px;
                padding: 15px;
                text-align: center;
                display: flex;
                flex-direction: column;
                text-decoration: none;
            }

            .hero {
                margin: 0;
                padding: 15px;
                text-align: center;
                display: flex;
                flex-direction: column;
                text-decoration: none;
            }

            img {
                width: 50px;
                margin: auto;
            }

            .text {
                color: #aaff87;
                font-weight: 600;
                font-size: 1.2em;
                margin-top: 5px;
            }
        }

        @media (max-width: 991px) {
            flex-wrap: wrap;

            .balance-game {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                width: 100%;
                flex: unset;
                margin: 0;

                .box-dark {
                    width: 150px;
                    height: 130px;
                    max-width: 150px;
                    margin: 15px;

                    &:first-child {
                        margin-left: 0;
                    }
                }
            }
        }

        @media (max-width: 991px) {
            .balance-currencies,
            .balance-hgb {
                flex: unset;
                width: 100%;
                max-width: 500px;
                margin: 15px 0;
            }

            .balance-game {
                justify-content: center;
            }
        }
    }

    .special-font {
        font-style: normal;
        font-weight: 600;
        font-size: 22px;

        span {
            font-family: 'HHBI';
        }

        &:last-child {
            font-size: 16px;
        }
    }

    .activity-list {
        margin-top: 20px;
    }

    @media (max-width: 991px) {
        .title {
            margin: 25px auto;
        }

        .wallet-address {
            justify-content: center;
            margin-bottom: 15px;
        }

        .list-box-wallet {
            justify-content: center;
        }

        .balance-game {
            justify-content: center;
        }
    }
}

.wallet {
    margin-top: 2rem;
}

.preferences {
    margin-top: 20px;
    max-width: 800px;
    color: #fff;
    .option {
        display: flex;
        justify-content: space-between;

        .tit {
            font-size: 20px;
        }

        .opt {
            flex-basis: 250px;
        }
    }
}

.btn-primary {
    padding: 8px 16px;
    font-weight: 500;
    font-size: 16px;
    border-radius: 8px;
    background: linear-gradient(to right, #1775b8, #91c4e9);
}

@media (max-width: 993px) {
    .btn-primary {
        padding: 4px 8px;
    }
}
</style>

<style lang="scss">
.update-btns {
    display: flex;
    justify-content: center;
    gap: 15px;
}

.open-modal-btn {
    border-radius: 6px;
    color: #fafafa;
    font-size: 16px;
    padding: 0.5rem 1.5rem;
    outline: none;
}

.update-pass-modal {
    position: relative;

    h3 {
        font-size: 30px;
        font-weight: 600;
        color: #078c04;
        text-align: center;
        margin-top: 1.5rem;
    }

    .current-pass,
    .new-pass {
        position: relative;

        input {
            width: 100%;
            padding: 0 1rem;
            border: 1px solid #e4e4e4;
            border-radius: 8px;
            height: 40px;
            color: #000;
            background-color: #fafafa;
            &::placeholder {
                color: #262626;
            }
        }

        input:focus-visible {
            outline: none;
        }

        input::placeholder {
            color: #a6a6a6;
            font-size: 18px;
            font-style: italic;
            font-weight: 400;
            text-align: left;
        }

        img {
            position: absolute;
            top: 50%;
            right: 1rem;
            transform: translateY(-50%);
            width: 24px;
            cursor: pointer;
        }
    }

    .update-btn {
        display: flex;
        justify-content: center;

        button {
            border-radius: 6px;
            color: white;
            font-size: 16px;
            padding: 0.5rem 2rem;
        }
    }

    .modal-content {
        .modal-body {
            display: flex;
            border-radius: 22px;
            flex-direction: column;
        }
    }

    .close {
        position: absolute;
        top: 10px;
        right: 10px;
    }
}
.update-wallet-modal {
    .input-pw {
        position: relative;

        img {
            position: absolute;
            top: 50%;
            right: 5px;
            transform: translate(-50%, -50%);
            cursor: pointer;
        }
    }
}
.update-sponsor-modal,
.update-master-node,
.update-wallet-modal {
    position: relative;

    .modal-body {
        border-radius: 22px;
    }

    h3 {
        font-size: 30px;
        font-weight: 600;
        color: #078c04;
        text-align: center;
        margin-top: 1.5rem;
    }

    input {
        width: 100%;
        padding: 0 1rem;
        border: 1px solid #e4e4e4;
        border-radius: 8px;
        height: 40px;
        color: #000;
        background-color: #fafafa;
    }

    input:focus-visible {
        outline: none;
    }

    input::placeholder {
        color: #a6a6a6;
        font-size: 18px;
        font-style: italic;
        font-weight: 400;
        text-align: left;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .update-btn {
        display: flex;
        justify-content: center;

        button {
            background: linear-gradient(180deg, #00d1ff 0%, #0087c8 100%);
            border-radius: 72px;
            color: white;
            font-size: 16px;
            padding: 0.5rem 2rem;
        }
    }

    .close {
        position: absolute;
        top: 10px;
        right: 10px;
    }
}

.modal-open-master-list {
    .modal-content {
        .modal-body {
            display: flex;
            border-radius: 22px;
            flex-direction: column;
            padding: 44px 32px;
            position: relative;

            .master-table {
                table {
                    margin-bottom: 0;
                    thead {
                        tr {
                            th {
                            }
                        }
                    }
                    tbody {
                        tr {
                            td {
                            }
                        }
                    }
                }
            }

            @media (max-width: 575px) {
                .master-table {
                    overflow-x: hidden;
                }
            }

            .btn-close {
                position: absolute;
                top: 0;
                right: 0;
                transform: translate(-8%, 8%);
            }
        }
    }

    .close {
        position: absolute;
        top: 10px;
        right: 10px;
    }
}

.modal-open-master-detail {
    .modal-content {
        .modal-body {
            display: flex;
            border-radius: 22px;
            flex-direction: column;
            padding: 24px 32px;

            .btn-close {
                position: absolute;
                top: 0;
                right: 0;
                transform: translate(-8%, 8%);
            }

            .master {
                display: flex;
                flex-direction: column;
                gap: 32px;
                padding: 0 8px;

                &__header {
                    text-align: center;

                    h3 {
                        text-transform: uppercase;
                        font-size: 28px;
                        font-weight: 700;
                        color: #078c04;
                    }
                }

                &__body {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    gap: 8px;
                    padding: 16px 32px;
                    border-radius: 8px;
                }

                &__footer {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 8px 0;
                }

                &__item {
                    &--heading {
                        font-weight: 600;
                        font-size: 18px;
                        color: #078c04;
                    }
                }
            }

            p {
                margin-bottom: 0;
            }
        }
    }

    .close {
        position: absolute;
        top: 10px;
        right: 10px;
    }
}
</style>
