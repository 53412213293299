<template>
  <div class="buy">
    <div class="buy__heading">
      <div class="heading__secondary">
        <div>

          {{ $t('presale.buyPackage') }}
        </div>
      </div>
    </div>

    <div class="buy__content">
      <div class="buy__item">
        <div class="buy__item--left">
          <div>
            <img src="@/assets/images/background/buy-package.png" />
          </div>

          <div class="buy__item--label">
            <p>${{ TruncateToDecimals2(CheckPresale.price) }} ~ {{ TruncateToDecimals2(CheckPresale.token) }} Token </p>
          </div>
        </div>

        <div class="buy__item--right">
          <div class="buy__item--heading">
            <p>
              {{ $t('presale.packageInformation') }}
            </p>
          </div>

          <div>
            <ul>
              <li>
                {{ $t('presale.info1') }}
              </li>
              <li>
                {{ $t('presale.info2') }}
              </li>
              <li>
                {{ $t('presale.info3') }}
              </li>
              <li>
                {{ $t('presale.info4') }}
              </li>
              <li>
                {{ $t('presale.info5') }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div class="buy__footer">
      <button class="btn__primary" @click="$bvModal.show('buy-confirm')">
        {{ $t('presale.buyNow') }}
      </button>
    </div>

    <b-modal id="buy-confirm" centered hide-footer hide-header size="md">
      <svg @click="$bvModal.hide('buy-confirm')" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
        viewBox="0 0 24 24" class="icon-close">
        <path fill="currentColor"
          d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12z" />
      </svg>
      <div class="buy__confirm">
        <p>{{ $t('vesting.areYouSure') }} (${{ TruncateToDecimals2(CheckPresale.price) }} ~ {{
          TruncateToDecimals2(CheckPresale.token) }} Token)</p>

        <div class="buy__buttons">
          <button class="btn__primary" @click="buyPackage">
            {{ $t('presale.confirm') }}
          </button>
          <button class="btn__primary" @click="$bvModal.hide('buy-confirm')">
            {{ $t('presale.cancel') }}
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  methods: {
    buyPackage() {
      this.$store.dispatch('presale/req_postBuyToken', {
        id: this.PresaleInfo._id,
      });

      this.$bvModal.hide('buy-confirm');
    },
    openModalBuy() {
      this.$bvModal.show('buy-confirm');

      this.$store.dispatch('presale/req_postBuyToken', {
        id: this.PresaleInfo._id,
      });
    },
  },
  computed: {
    ...mapGetters({
      UserInfo: 'info/UserInfo',
      PresaleInfo: 'presale/PresaleInfo',
      CheckPresale: 'presale/CheckPresale',
    }),
  },
};
</script>

<style lang="scss" scoped>
.heading__secondary {
  font-size: 22px;
  font-weight: 600;
  color: #fff;
}

.buy {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  &__content {
    display: flex;
    justify-content: center;
  }

  &__footer {
    margin-top: 24px;
    display: flex;
    justify-content: center;
  }

  &__item {
    background: linear-gradient(#234524, #234524) padding-box,
      linear-gradient(to bottom, #7FDF44, #36790E) border-box;
    border-radius: 8px;
    border: 2px solid transparent;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;

    display: flex;
    color: white;

    &--heading {
      p {
        position: relative;
        font-size: 18px;
        font-weight: 700;
        text-align: center;
      }

      p::before {
        content: '';
        display: inline;
        width: 25%;
        height: 2px;
        background: linear-gradient(to bottom, #7FDF44, #36790E);
        position: absolute;
        top: 50%;
        right: 0;
      }

      p::after {
        content: '';
        display: inline;
        width: 25%;
        height: 2px;
        background: linear-gradient(to bottom, #7FDF44, #36790E);
        position: absolute;
        top: 50%;
        left: 0;
      }
    }

    &--left {
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient(to bottom, #7FDF44, #36790E);
      padding: 12px;

      position: relative;

      img {}
    }

    &--right {
      padding: 16px 32px;
      display: flex;
      flex-direction: column;
      gap: 8px;

      ul {
        padding-left: 16px;
      }
    }

    &--label {
      padding: 4px;
      font-size: 16px;
      font-weight: 600;
      color: #328400;
      background-color: white;
      position: absolute;
      bottom: 5%;
      left: 0;
      border-radius: 4px;
      transform: translate(-5%, 0);

      p {
        margin-bottom: 0;
      }
    }
  }
}

.btn__primary {
  background: linear-gradient(to left, #8BF04D, #2E6D08);
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  padding: 12px 24px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  outline: none;

  p {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 1024px) {
  .buy {
    &__item {
      flex-direction: column;
    }
  }
}

@media screen and (max-width: 500px) {
  .buy {
    gap: 8px;

    &__item {
      &--heading {
        p {
          font-size: 16px;
        }

        p::after {
          width: 15%;
        }

        p::before {
          width: 15%;
        }
      }

      &--right {
        ul {
          font-size: 14px;
        }
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .buy {
    &__item {
      &--heading {
        p {
          font-size: 16px;
        }

        p::after {
          width: 10%;
        }

        p::before {
          width: 10%;
        }
      }

      &--right {
        ul {
          font-size: 14px;
        }
      }
    }
  }
}
</style>

<style lang="scss">
#buy-confirm {
  .modal-content .modal-body {
    position: relative;
    border-radius: 8px;
    background-color: white;

    .icon-close {
      position: absolute;
      top: 5%;
      right: 3%;
      cursor: pointer;
    }

    .buy {
      &__confirm {
        p {
          font-size: 18px;
          font-weight: 600;
        }
      }

      &__buttons {
        display: flex;
        gap: 16px;
        justify-content: center;
      }
    }
  }
}
</style>
