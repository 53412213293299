<template>
  <div class="detail">
    <div class="detail__body">
      <div class="detail__body--head">
        <label for="usdt">
          {{ title }}
        </label>

        <button class="btn__icon" @click="buyToken">
          <p>{{ $t('dashboardPage.detail') }}</p>
          <img src="@/assets/images/icons/btn-detail.svg" />
        </button>
      </div>

      <div class="input-wrapper">
        <img v-if="icon === 'usdt'" class="icon" src="@/assets/images/icons/buy_usdt.png" />
        <img v-else-if="icon === 'token'" class="icon" src="@/assets/images/icons/buy_token.png" />
        <img v-else class="icon img-hidden" src="@/assets/images/icons/buy_usdt.png" />

        <input id="usdt" type="string" placeholder="0.0" :value="TruncateToDecimals2(numberValue)" disabled />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    numberValue: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    link: {
      type: String,
      default: '',
    },
  },
  methods: {
    buyToken() {
      console.log('Buy token');
    },
  },
};
</script>

<style scoped lang="scss">
.detail {
  padding: 16px;
  color: white;
  background: linear-gradient(#2c572d, #2c572d) padding-box,
    linear-gradient(to left, #A6ED8D, #078C04) border-box;
  border-radius: 8px;
  border: 2px solid transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__body {
    display: flex;
    flex-direction: column;
    gap: 32px;

    &--head {
      display: flex;
      justify-content: space-between;
    }

    label {
      color: #fff;
      font-weight: 600;
      font-size: 16px;
      margin-bottom: 0;
    }
  }
}

.img-hidden {
  display: none;
  visibility: hidden;
}

.btn__icon {
  background: linear-gradient(to left, #8BF04D, #2E6D08);
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  padding: 6px 12px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  display: flex;
  gap: 6px;
  align-items: center;
  justify-content: center;
  outline: none;

  p {
    margin-bottom: 0;
  }

  img {
    transform: translate(0, 20%);
  }
}

.input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  background-color: #16381A;
  border-radius: 8px;
  padding: 2px 10px;
}

.input-wrapper .icon {
  margin-right: 10px;
}

input {
  background-color: transparent;
  border: none;
  color: white;
  flex-grow: 1;
  text-align: end;
}

input:focus {
  border: none;
  outline: none;
}

input::placeholder {
  color: white;
  font-weight: 600;
  font-size: 16px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
