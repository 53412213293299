<template>
  <div class="revenue">
    <div class="revenue__heading">
      <p class="heading__secondary">
        {{ $t('presale.groupRevenueList') }}
      </p>

      <p class="revenue__heading--sub">${{ TruncateToDecimals2(RevenueHistory.totalRevenue) }}</p>
    </div>

    <div class="revenue__cont">
      <b-table id="table-revenue" striped hover :items="RevenueHistory.results" borderless responsive :fields="fields" show-empty>
        <template #head(email)="data">
          <div>
            {{ $t('dashboardPage.email') }}
          </div>
        </template>

        <template #head(packageName)="data">
          <div>
            {{ $t('dashboardPage.packageName') }}
          </div>
        </template>

        <template #head(status)="data">
          <div>
            {{ $t('dashboardPage.status') }}
          </div>
        </template>

        <template #head(buy_date)="data">
          <div>
            {{ $t('dashboardPage.buyDate') }}
          </div>
        </template>

        <template #head(expired_date)="data">
          <div>
            {{ $t('dashboardPage.expiredDate') }}
          </div>
        </template>
        <template #cell(status)="data">
          <div class="span-container">
            <img src="@/assets/images/icons/success-new.svg" alt="success" />
            <span class="span-success">
              {{
                $t(`${data.value}`)
              }}
            </span>
          </div>
        </template>

        <template #cell(token)="data">
          <div class="coin-container">
            <p>
              {{
                TruncateToDecimals2(data.value)
              }}
            </p>
            <img src="@/assets/images/icons/token-table.svg" alt="usdt" />
          </div>
        </template>

        <template #cell(usdt)="data">
          <div class="coin-container">
            <p>
              {{
                TruncateToDecimals2(data.value)
              }}
            </p>
            <img src="@/assets/images/icons/usdt-table.svg" alt="usdt" />
          </div>
        </template>

        <template #cell(expired_date)="data">
          <div class="coin-container">
            <p v-if="data.value">
              {{
                getDateTime3(data.value)
              }}
            </p>
            <p v-else>
              N/A
            </p>
          </div>
        </template>
        <template #cell(buy_date)="data">
          <div class="coin-container">
            <p v-if="data.value">
              {{
                getDateTime3(data.value)
              }}
            </p>
            <p v-else>
              N/A
            </p>
          </div>
        </template>
      </b-table>

      <b-pagination
          v-model="currentPage"
          :total-rows="RevenueHistory.totalResults"
          :per-page="perPage"
          aria-controls="table-revenue"
          pills
          align="right"
          id="table-pagination-revenue"
        ></b-pagination>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      fields: [
        {
          key: '_id',
          label: 'Id',
        },
        {
          key: 'userEmail',
          label: 'Email'
        },
        {
          key: 'packageName',
          label: 'Package name',
        },
        {
          key: 'price',
          label: 'USDT',
        },
        {
          key: 'token',
          label: 'Token',
        },
        {
          key: 'status',
          label: 'Status',
        },
        {
          key: 'buy_date',
          label: 'Buy Date',
        },
        {
          key: 'expired_date',
          label: 'Expired Date',
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 4,
    }
  },
  computed: {
    ...mapGetters({
      UserInfo: 'info/UserInfo',
      PresaleInfo: 'presale/PresaleInfo',
      RevenueHistory: 'presale/RevenueHistory'
    }),

    params() {
      return {
        type: 'group',
        presaleId: this.PresaleInfo._id,
        page: this.currentPage,
        limit: this.perPage,
      };
    }
  },
  mounted() {
    this.$store.dispatch('presale/req_getPurchaseHistory', this.params);
  },
  watch: {
    PresaleInfo() {
      this.$store.dispatch('presale/req_getPurchaseHistory', this.params);
    },
    currentPage() {
      this.$store.dispatch('presale/req_getPurchaseHistory', this.params);
    },
  }
}
</script>

<style scoped lang="scss">
.heading__secondary {
  font-size: 22px;
  font-weight: 600;
  color: #fff;
}

.revenue {
  padding: 32px 74px;
  display: grid;
  gap: 40px;
  background: linear-gradient(#2c572d, #2c572d) padding-box,
    linear-gradient(to left, #A6ED8D, #078C04) border-box;
  border-radius: 8px;
  border: 2px solid transparent;
  position: relative;
  color: white;

  &__heading {
    display: flex;
    justify-content: space-between;
    gap: 16px;

    &--sub {
      color: #AAFF95;
      font-weight: 600;
      font-size: 18px;
    }
  }

}

.span-container {
  background-color: #87E14F;
  padding: 4px 8px;
  border-radius: 8px;
  margin: auto 0;
  width: fit-content;
  display: flex;
  gap: 4px;
}

.span-success {
  color: #08270C;
  font-size: 12px;
}

.coin-container {
  color: white;
  display: flex;
  gap: 12px;
  align-items: center;

  p {
    margin-bottom: 0;
    font-weight: 500;
    font-size: 14px;
  }
}

@media screen and (max-width: 500px) {
  .revenue {
    gap: 24px;
    padding: 12px 24px;
  }
}
</style>

<style lang="scss">
.table-striped tbody tr {
  border-radius: 8px;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #16391A;
  border-radius: 8px;
  color: #AAFF95;
  padding: 16px 32px;
}

.table-striped tbody tr:nth-of-type(even) {
  background-color: transparent;
  color: white;
  padding: 16px 32px;
}

.table th,
.table td {
  padding: 16px 32px;
  vertical-align: middle;
  text-align: center;
}

table {
  thead {
    tr {
      th {
        div {
          color: white;
          font-weight: 500;
          font-size: 16px;
          display: inline-block;
          text-wrap: nowrap;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        font-size: 14px;
        font-weight: 500;
        text-wrap: nowrap;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  table {
    thead {
      tr {
        th {
          div {
            font-size: 16px;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          font-size: 14px;
        }
      }
    }
  }
}
</style>

<style lang="scss">
#table-pagination-revenue {
  .page-item.active .page-link {
    background-color: #36f27e;
    border-color: #36f27e;
  }

  .page-link {
    color: #36f27e;
    font-weight: 600;
  }

  .page-item.active .page-link {
    z-index: 1;
    color: #fff;
    background-color: #36f27e;
    border-color: #36f27e;
  }
}
</style>
