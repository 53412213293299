const axios = require('../axios.config').default;

export default {
    namespaced: true,
    state: () => ({
        airdropMissions: {},
        missions: {},
    }),
    getters: {
        AirdropMissions: (state) => state.airdropMissions,
        Missions: (state) => state.missions,
    },
    actions: {
        async airdrop_missions({ commit }) {
            return axios.get('/mission/airdrops').then((res) => {
                if (res && res.data) {
                    commit('SET_AIRDROP_MISSIONS', res.data[0]);
                }
                return res.data;
            });
        },
        async send_telegram({ commit }, input) {
            return axios.post('/mission/join-telegram', input).then((res) => {
                if (res.status) {
                    commit('SET_AIRDROP_MISSIONS', res.data);
                }
            });
        },
        async send_twitter({ commit }, input) {
            return axios.post('/mission/follow-twitter', input).then((res) => {
                if (res.status) {
                    commit('SET_AIRDROP_MISSIONS', res.data);
                }
            });
        },
        async send_app({ commit }, input) {
            return axios.post('/mission/download-app', input).then((res) => {
                if (res.status) {
                    commit('SET_AIRDROP_MISSIONS', res.data);
                }
            });
        },
        async check_deposit({ commit }) {
            return axios
                .post('/mission/deposit', { account: 'lir' })
                .then((res) => {
                    if (res.status) {
                        commit('SET_AIRDROP_MISSIONS', res.data);
                    }
                    return res;
                });
        },
        async claim_airdrop() {
            return axios.get('/mining/claim-airdrop');
        },
        async get_missions({ commit, rootGetters }) {
            if (rootGetters['info/UserInfo']) {
                return axios
                    .post('/mission/mana-day', {
                        user: rootGetters['info/UserInfo'].email,
                        // user: 'ntdchi99+5@gmail.com'
                    })
                    .then((res) => {
                        if (res?.data) {
                            commit('SET_MISSIONS_LIST', res.data);
                        }
                    });
            }
            return false;
        },
    },
    mutations: {
        SET_AIRDROP_MISSIONS(state, data) {
            state.airdropMissions = data;
        },
        SET_MISSIONS_LIST(state, data) {
            state.missions = data;
        },
    },
};
