import { render, staticRenderFns } from "./ListBuyers.vue?vue&type=template&id=3e9e2bae&scoped=true&"
import script from "./ListBuyers.vue?vue&type=script&lang=js&"
export * from "./ListBuyers.vue?vue&type=script&lang=js&"
import style0 from "./ListBuyers.vue?vue&type=style&index=0&id=3e9e2bae&prod&scoped=true&lang=scss&"
import style1 from "./ListBuyers.vue?vue&type=style&index=1&id=3e9e2bae&prod&lang=scss&"
import style2 from "./ListBuyers.vue?vue&type=style&index=2&id=3e9e2bae&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e9e2bae",
  null
  
)

export default component.exports