<template>
  <div class="xnode-item">
    <div class="buy__header">
      <p class="buy__header--title"></p>
      <p class="buy__header--desc">{{ name }}</p>
    </div>

    <div class="buy__price">
      <p class="buy__price--title">Price:</p>
      <p v-if="!discountPrice" class="buy__price--desc">{{ price }}$</p>
      <p v-else class="buy__price--discount">
        <span class="buy__price--head">{{ price }}$</span>
        <span class="buy__price--discount">{{ discountPrice }}$</span>
      </p>
    </div>

    <div class="buy__token">
      <p class="buy__token--title">Token</p>
      <p class="buy__token--desc">{{ token }}</p>
    </div>
    <button class="btn__primary" @click="$bvModal.show(idNode)">Buy Now</button>

    <b-modal :id="idNode" centered hide-footer hide-header size="md">
      <svg @click="$bvModal.hide(idNode)" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
        viewBox="0 0 24 24" class="icon-close">
        <path fill="currentColor"
          d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12z" />
      </svg>
      <div class="buy__confirm">
        <p>{{ $t('vesting.areYouSure') }}</p>

        <div class="buy__buttons">
          <button class="btn__primary" @click="buyPackage">
            {{ $t('presale.confirm') }}
          </button>
          <button class="btn__primary" @click="$bvModal.hide(idNode)">
            {{ $t('presale.cancel') }}
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      isOpen: false
    }
  },
  props: {
    price: Number,
    name: String,
    token: Number,
    idNode: String,
    discountPrice: Number | null,
    presaleId: String,
    packageId: String
  },
  computed: {
    ...mapGetters({
      UserInfo: 'info/UserInfo',
    })
  },
  methods: {
    buyPackage() {
      this.$store.dispatch('presale/req_postBuyPackage', {
        packageId: this.packageId
      }).then(() => {
        this.$store.dispatch('info/req_getInfo').then(() => {

          this.$bvModal.hide(this.idNode);
        })
      })

    },
    openModalBuy() {
      this.$bvModal.show(this.idNode);

      // this.$store.dispatch('presale/req_postBuyToken', {
      //   id: this.PresaleInfo._id,
      // });
    },
  },
}
</script>

<style lang="scss" scoped>
.xnode-item {
  border: 2px solid #7FDF44;
  border-radius: 24px;
  padding: 16px 32px;

  @media screen and (max-width: 768px) {
    padding: 16px 16px;
  }
}

.buy {
  &__header {
    display: flex;
    flex-direction: column;
    gap: 2px;

    &--title {
      font-weight: 500;
      font-size: 20px;
    }

    &--desc {
      font-weight: 500;
      font-size: 18px;
    }
  }

  &__price {
    display: flex;
    gap: 2px;
    align-items: center;
    justify-content: space-between;

    &--title {
      font-weight: 600;
      font-size: 20px;
    }

    &--desc {
      font-weight: 500;
      font-size: 18px;
    }

    &--discount {
      display: flex;
      gap: 8px;
      align-items: center;
      justify-content: space-between;
    }

    &--head {
      font-weight: 600;
      font-size: 16px;
      text-decoration: line-through;
      opacity: 0.5;
    }

    &--discount {
      font-weight: 600;
      font-size: 20px;
    }

  }

  &__token {

    display: flex;
    gap: 2px;
    justify-content: space-between;

    &--title {
      font-weight: 600;
      font-size: 20px;
    }

    &--desc {
      font-weight: 500;
      font-size: 18px;
    }

  }
}

.btn__primary {
  background: linear-gradient(to left, #8BF04D, #2E6D08);
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  padding: 12px 24px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  outline: none;
  width: 100%;

  p {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 1024px) {
  .buy {
    &__item {
      flex-direction: column;
    }
  }
}
</style>

<style lang="scss">
#xnode-confirm-0 {
  .modal-content .modal-body {
    position: relative;
    border-radius: 8px;
    background-color: white;

    .icon-close {
      position: absolute;
      top: 5%;
      right: 3%;
      cursor: pointer;
    }

    .buy {
      &__confirm {
        p {
          font-size: 18px;
          font-weight: 600;
        }
      }

      &__buttons {
        display: flex;
        gap: 16px;
        justify-content: center;
      }
    }
  }
}

#xnode-confirm-1 {
  .modal-content .modal-body {
    position: relative;
    border-radius: 8px;
    background-color: white;

    .icon-close {
      position: absolute;
      top: 5%;
      right: 3%;
      cursor: pointer;
    }

    .buy {
      &__confirm {
        p {
          font-size: 18px;
          font-weight: 600;
        }
      }

      &__buttons {
        display: flex;
        gap: 16px;
        justify-content: center;
      }
    }
  }
}

#xnode-confirm-2 {
  .modal-content .modal-body {
    position: relative;
    border-radius: 8px;
    background-color: white;

    .icon-close {
      position: absolute;
      top: 5%;
      right: 3%;
      cursor: pointer;
    }

    .buy {
      &__confirm {
        p {
          font-size: 18px;
          font-weight: 600;
        }
      }

      &__buttons {
        display: flex;
        gap: 16px;
        justify-content: center;
      }
    }
  }
}

#xnode-confirm {
  .modal-content .modal-body {
    position: relative;
    border-radius: 8px;
    background-color: white;

    .icon-close {
      position: absolute;
      top: 5%;
      right: 3%;
      cursor: pointer;
    }

    .buy {
      &__confirm {
        p {
          font-size: 18px;
          font-weight: 600;
        }
      }

      &__buttons {
        display: flex;
        gap: 16px;
        justify-content: center;
      }
    }
  }
}
</style>
