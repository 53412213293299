const axios = require('../axios.config').default;

export default {
    namespaced: true,
    state: () => ({
        voucherList: null,
        userVoucherList: [],
        filterdVoucherList: [],
    }),

    getters: {
        VoucherList: (state) => state.voucherList,
        UserVoucherList: (state) => state.userVoucherList,
        FilteredUserVoucherList: (state) => state.filterdVoucherList
    },

    actions: {
        async voucherList({ commit }) {
            return axios.get('/voucher/voucher-settings').then((res) => {
                if (res && res.data) {
                    commit('SET_VOUCHER_LIST', res.data.results);
                    console.log(res.data.results);
                }
            });
        },

        async userVoucher({ commit }, input) {
            return axios
                .get('/voucher/user', {
                    params: { page: input.page },
                })
                .then((res) => {
                    if (res && res.data) {
                        commit('SET_USER_VOUCHER_LIST', res.data);
                        console.log(res.data);
                    }
                });
        },

        async filteredUserVoucher({ commit }, input) {
            return axios
                .get('/voucher/user', {
                    params: { page: input.page, type: input.type },
                })
                .then((res) => {
                    if (res && res.data) {
                        commit('SET_FILTERED_USER_VOUCHER_LIST', res.data);
                        console.log('Filtered User voucher list: ', res.data);
                    }
                });
        },

        async buyVoucher(context, input) {
            return axios.post('/voucher/buy', input).then((res) => {
                console.log(res);
            });
        },

        async useVoucherXin(context, params) {
            return axios.get(`/voucher/change-xin/${params}`).then((res) => {
                if (res && res.data) {
                    console.log(res.data);
                }
            });
        },

        async voucherType() {
            return axios.get('/voucher/type').then((res) => {
                if (res && res.data) {
                    console.log(res.data);
                }
            });
        },
    },

    mutations: {
        SET_VOUCHER_LIST(state, data) {
            // console.log('Voucher list: ', data.results);
            state.voucherList = data;
        },

        SET_USER_VOUCHER_LIST(state, data) {
            // console.log('Voucher list: ', data.results);
            state.userVoucherList = data;
        },
        SET_FILTERED_USER_VOUCHER_LIST(state, data) {
            state.filterdVoucherList = data;
        }
    },
};
