<template>
  <div>
    <div class="banner" @click="() => {
      this.$bvModal.show('banner-view')
    }">
      <img v-if="!isMobile" src="@/assets/images/background/banner-desktop.png" alt="banner" />
      <img v-else src="@/assets/images/background/banner-mobile.png" alt="banner" />
    </div>


    <b-modal id="banner-view" centered hide-footer hide-header size="md">
      <svg @click="() => {
        this.$bvModal.hide('banner-view')
        }" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
        viewBox="0 0 24 24" class="icon-close">
        <path fill="currentColor"
          d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12z" />
      </svg>
      <div class="buy__confirm" v-if="Banner">
        <div>
          <div class="buy__header">
            <p>Program for member</p>
          </div>

          <div class="buy__body">
            <p>
              {{ Banner.comment }}
            </p>

            <div class="price">
              <p>
                Price: 
                <span class="old">
                  ${{ Banner.price }}
                </span>
                <span class="current"> 
                  ${{ Banner.discountPrice }}
                </span>
              </p>
            </div>


            <div class="buy__make">
              <div class="buy__make--heading">
                <p>Make sure to join our group</p>
              </div>

              <div>
                <ul>
                  <li>ECH AI Global</li>
                </ul>
              </div>

              <!-- <p>Free $100 package with $35$ free activation</p> -->
            </div>
            <p class="buy__link--join">Link to our group</p>
            <div class="buy__link">
              <div class="buy__link--logo">
                <svg xmlns="http://www.w3.org/2000/svg" width="1.4em" height="1.4em" viewBox="0 0 24 24">
                <g fill="none" stroke="currentColor" stroke-width="1.5">
                  <path d="M22 12a10 10 0 1 1-20.001 0A10 10 0 0 1 22 12z" />
                  <path d="M16 12c0 1.313-.104 2.614-.305 3.827c-.2 1.213-.495 2.315-.867 3.244c-.371.929-.812 1.665-1.297 2.168c-.486.502-1.006.761-1.531.761c-.525 0-1.045-.259-1.53-.761c-.486-.503-.927-1.24-1.298-2.168c-.372-.929-.667-2.03-.868-3.244A23.614 23.614 0 0 1 8 12c0-1.313.103-2.614.304-3.827s.496-2.315.868-3.244c.371-.929.812-1.665 1.297-2.168C10.955 2.26 11.475 2 12 2c.525 0 1.045.259 1.53.761c.486.503.927 1.24 1.298 2.168c.372.929.667 2.03.867 3.244C15.897 9.386 16 10.687 16 12z" />
                  <path stroke-linecap="round" d="M2 12h20" />
                </g>
              </svg>
              </div>
              <div class="buy__link--item">
                <a href="https://xintel.app.link/giM5J2VcjKb" target="_blank">
                  https://xintel.app.link/giM5J2VcjKb
                </a>
              </div>
            </div>
          </div>

        </div>

        <div class="buy__buttons">
          <button class="btn__secondary" @click="() => {
            this.$bvModal.hide('banner-view')
            this.$bvModal.show('banner-confirm')
          }">
            Claim Airdrop
          </button>
          <button class="btn-cancel" @click="() => {
            this.$bvModal.hide('banner-view')
          }">
            Cancel
          </button>
        </div>
      </div>
    </b-modal>

    <b-modal id="banner-confirm" centered hide-footer hide-header size="md">
      <svg @click="() => {
        this.$bvModal.hide('banner-confirm')
        }" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
        viewBox="0 0 24 24" class="icon-close">
        <path fill="currentColor"
          d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12z" />
      </svg>
      <div class="buy__confirm">
        <div class="buy__header">
          <p class="buy__header--heading">Buy Node</p>
          <p class="buy__header--content">Are you sure you want to claim airdrop?</p>
        </div>

        <div class="buy__buttons">
          <button class="btn__secondary" @click="buyPromotion">
            Confirm
          </button>
          <button class="btn-cancel" @click="() => {
            this.$bvModal.hide('banner-confirm')
          }">
            Cancel
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    isMobile() {
      return this.windowWidth < 524;
    },
    ...mapGetters({
      Banner: 'info/Banner',
    }),
  },
  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    buyPromotion() {
      this.$store.dispatch('info/post_buyPromotion', {
        id: this.Banner._id,
      });
      this.$bvModal.hide('banner-view');
      this.$bvModal.hide('banner-confirm');
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.$store.dispatch('info/get_bannerInfo');
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
}
</script>

<style lang="scss" scoped>
  .banner {
    cursor: pointer;

    img {
      width: -webkit-fill-available;
    }
  }
</style>

<style lang="scss">
  #banner-view {
    .btn-cancel {
      background: #fff;
      color: #23873D;
      font-weight: 600;
      font-size: 14px;
      padding: 8px 16px;
      border-radius: 8px;
      border: 1px solid #2E6D08;
      cursor: pointer;
      outline: none;

      @media (max-width: 524px) {
        font-size: 12px;
      }
    }
    .btn__secondary {
      background: linear-gradient(to left, #8BF04D, #2E6D08);
      color: #fff;
      font-weight: 600;
      font-size: 14px;
      padding: 8px 16px;
      border-radius: 8px;
      border: none;
      cursor: pointer;
      outline: none;

      @media (max-width: 524px) {
        font-size: 12px;
      }

      p {
        margin-bottom: 0;
      }
    }
    .buy__buttons {
      display: flex;
      gap: 20px;
    }
    .modal-content {
      background-color: white;
      border: none;
    }

    .modal-body {
      background-color: white;
      padding: 0;
      border-radius: 8px;
    }

    .icon-close {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }

    .buy__confirm {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 20px;
      gap: 20px;

      @media (max-width: 524px) {
        padding: 10px;
      }

      h1 {
        font-size: 24px;
        font-weight: 700;
        color: #000;
      }
    }

    .buy__header {
      p {
        font-size: 24px;
        font-weight: 700;
        color: #23872d;

        @media (max-width: 524px) {
          font-size: 16px;
        }
      }
    }

    .buy__link--join {
      font-size: 16px !important;
      font-weight: 500 !important;

      @media (max-width: 524px) {
        font-size: 12px;
      }
    }

    .buy__body {
      p {
        font-size: 18px;
        font-weight: 600;
        color: #000;

        @media (max-width: 524px) {
          font-size: 12px;
        }
      }
      .price {
        .old {
          text-decoration: line-through;
          color: #a3a3a3;
          font-size: 0.875em;
        }
        .current {
          color: #2E6D08;
          font-weight: 700;
          font-size: 1.125em;
          padding: 0px 5px;
        }
      }
    }


    .buy__link {
      display: flex;
      gap: 20px;
      align-items: center;
      background-color: #8BF04D;
      padding: 4px 8px;
      border-radius: 8px;

      @media (max-width: 524px) {
        gap: 10px;
        padding: 4px;
      }


      .buy__link--logo {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .buy__link--item {
        width: 100%;
        a {
          font-size: 18px;
          font-weight: 600;
          color: #23872d;
          background: #fff;
          padding: 4px 8px;
          border-radius: 8px;

          @media (max-width: 524px) {
            font-size: 12px;
          }
        }
      }
    }

    .buy__make {
      margin-top: 8px;

      &--heading {
        display: grid;
        grid-template-columns: max-content max-content;
        gap: 8px;
        align-items: center;
      }

      p {
        font-size: 18px;
        font-weight: 500;
        color: #000;

        @media (max-width: 524px) {
          font-size: 12px;
        }
      }

      ul {
        padding: 0;
        list-style: none;

        li {
          font-size: 18px;
          font-weight: 600;
          color: #23872d;

          @media (max-width: 524px) {
            font-size: 12px;
          }
        }
      }
    }
  }

  #banner-confirm {
    .btn-cancel {
      background: #fff;
      color: #23873D;
      font-weight: 600;
      font-size: 14px;
      padding: 8px 16px;
      border-radius: 8px;
      border: 1px solid #2E6D08;
      cursor: pointer;
      outline: none;

      @media (max-width: 524px) {
        font-size: 12px;
      }
    }
    .btn__secondary {
      background: linear-gradient(to left, #8BF04D, #2E6D08);
      color: #fff;
      font-weight: 600;
      font-size: 14px;
      padding: 8px 16px;
      border-radius: 8px;
      border: none;
      cursor: pointer;
      outline: none;

      @media (max-width: 524px) {
        font-size: 12px;
      }

      p {
        margin-bottom: 0;
      }
    }
    .buy__buttons {
      display: flex;
      gap: 20px;
    }
    .modal-content {
      background-color: white;
      border: none;
    }

    .modal-body {
      background-color: white;
      padding: 0;
      border-radius: 8px;
    }

    .icon-close {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }

    .buy__confirm {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 20px;
      gap: 20px;

      h1 {
        font-size: 24px;
        font-weight: 700;
        color: #000;
      }
    }

    .buy {
      &__header {
        &--heading {
          font-size: 24px;
          font-weight: 700;
          color: #23872d;
        }

        &--content {
          font-size: 18px;
          font-weight: 600;
          color: #000;
        }
      }
    }
  }
</style>
