<template>
    <div class="px-2">
        <Activity />
    </div>
</template>

<script>
import Activity from '@/components/account/Activities.vue';

export default {
    name: 'ActivityPage',
    components: {
        Activity,
    },
};
</script>
