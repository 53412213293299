import { List } from 'echarts';

const axios = require('../axios.config').default;

export default {
  namespaced: true,
  state: () => ({
    info: {
    },
    list: [],
    claimed: {
      current: 1,
      total: 1,
      list: [],
    },
    statistical: {
      totalBought: 0,
      totalLocking: 0,
      totalRemaining: 0,
      totalUnlocked: 0,
      downlineUCON: 0,
      downLineUSDT: 0,
    },
    dataPurchase: [],
    dataRevenue: [],
    dataGroup: [],
    checkPresale: [],
    balanceStatistic: [],
    infoTree: [],
    vestingInfo: [],
    historyVesting: [],
    listInfoClaimed: null,
    listPackage: []
  }),
  getters: {
    PresaleInfo: (state) => state.info,
    List: (state) => state.list,
    Statistical: (state) => state.statistical,
    Claimed: (state) => state.claimed,
    PurchaseHistory: (state) => state.dataPurchase,
    RevenueHistory: (state) => state.dataRevenue,
    GroupHistory: (state) => state.dataGroup,
    CheckPresale: (state) => state.checkPresale,
    BalanceStatistic: (state) => state.balanceStatistic,
    InfoTree: (state) => state.infoTree,
    VestingInfo: (state) => state.vestingInfo,
    HistoryVesting: (state) => state.historyVesting,
    ListInfoClaimed: (state) => state.listInfoClaimed,
    ListPackage: (state) => state.listPackage
  },
  actions: {
    req_getInfoPreSales: async ({ commit }) => {
      return axios.get('/presale/get-info').then((response) => {
        if (response.data) {
          commit('SET_PRE_SALE_INFO', response.data);
        }
      });
    },
    req_getInfoTree: async ({ commit }) => {
      return axios.get('/presale/get-info-tree').then((response) => {
        commit('SET_INFO_TREE', response.data);
      });
    },
    req_getBuyHistory: ({ commit }) => {
      axios.post('/presale/unlock-schedule').then((response) => {
        if (response) {
          commit('SET_PRE_SALE_USER', response.data);
        }
      });
    },
    req_getListClaimed: ({ commit }, input) => {
      axios
        .get('/presale/history-claim', {
          params: input,
        })
        .then((response) => {
          commit('SET_LIST_CLAIMED', response.data);
        });
    },
    req_postBuyToken: async ({ dispatch }, input) => {
      return axios.post('/presale/buy-presale', input).then((res) => {
        // dispatch('req_getBuyHistory');
        // dispatch('req_getInfoPreSales');
        return res;
      });
    },
    req_postClaimToken: ({ dispatch }, input) => {
      axios.post('/presale/claim', input).then(() => {
        dispatch('req_getBuyHistory');
        dispatch('req_getInfoPreSales');
      });
    },
    req_getPurchaseHistory: ({ commit }, input) => {
      return axios.get('/presale/histories', {
        params: {
          type: input.type,
          presaleId: input.presaleId,
          page: input.page ?? 1,
          limit: input.limit ?? 4

        }
      }).then((response) => {

        if (input.type === 'me') {
          commit('SET_HISTORY_PURCHASE', response.data);
        }
        else if (input.type === 'group') {
          commit('SET_HISTORY_REVENUE', response.data);
        }
        else {
          commit('SET_HISTORY_GROUP', response.data);
        }

        return response.data;
      });
    },

    req_getPostCheckToken: ({ commit }, input) => {
      return axios.post('/presale/check-presale', {
        id: input.id
      }).then((response) => {
        if (response.data) {
          commit('SET_CHECK_PRESALE', response.data);
        }
        return response.data;
      });
    },
    req_getBalanceStatistic: ({ commit }) => {
      return axios.get('user/balance-statistical').then((response) => {
        commit('SET_BALANCE_STATISTIC', response.data);
        return response.data;
      });
    },
    req_getVestingInfo: ({ commit }) => {
      return axios.get('/vesting/info').then((response) => {
        commit('SET_VESTING_INFO', response);
      });
    },
    req_getHistoryVesting: ({ commit }) => {
      return axios.get('/vesting/histories').then((response) => {
        commit('SET_HISTORY_VESTING', response.data);
      });
    },
    req_getClaimToken: ({ commit }) => {
      return axios.get('/vesting/claim').then((response) => {
        return response.data;
      });
    },
    req_postNewClaimToken: ({ commit }, input) => {
      return axios.post('/vesting/claim', {
        "vestingId": input.vestingId
      }).then((response) => {
        return response.data;
      });
    },
    req_getListInfoClaimed: ({ commit }, input) => {
      return axios.get('/vesting/info-claim', {
        params: {
          page: input.page ?? 1,
          limit: input.limit ?? 5
        }
      }).then((response) => {
        commit('SET_LIST_VESTING_CLAIM', response.data);
      });
    },
    req_getListPackage: ({ commit }) => {
      return axios.get('/package/list').then((response) => {

        console.log(response)
        commit('SET_LIST_PACKAGE', response.listPackage)
      });
    },
    req_postBuyPackage: ({ commit }, input) => {
      return axios.post('/package/buy', {
        packageId: input.packageId
      }).then((response) => {
        return response;
      });
    }
  },
  mutations: {
    SET_LIST_PACKAGE(state, data) {
      state.listPackage = data;
    },
    SET_LIST_VESTING_CLAIM(state, data) {
      state.listInfoClaimed = data;
    },
    SET_HISTORY_VESTING(state, data) {
      state.historyVesting = data;
    },
    SET_VESTING_INFO(state, data) {
      state.vestingInfo = data;
    },
    SET_PRE_SALE_INFO(state, data) {
      state.info = data;
    },
    SET_INFO_TREE(state, data) {
      state.infoTree = data;
    },
    SET_PRE_SALE_USER(state, data) {
      const {
        totalBought,
        totalLocking,
        totalRemaining,
        totalUnlocked,
        unlockSchedule,
        totalUnderSale,
      } = data;
      state.list = unlockSchedule;
      state.statistical = {
        totalBought,
        totalLocking,
        totalRemaining,
        totalUnlocked,
        downLineUSDT: totalUnderSale.totalUSDT,
        downlineUCON: totalUnderSale.amountUCON
      };
    },
    SET_LIST_CLAIMED(state, data) {
      const { results, page, totalPages } = data;
      state.claimed = {
        current: page,
        total: totalPages,
        list: results,
      };
    },
    SET_HISTORY_PURCHASE(state, data) {
      state.dataPurchase = data;
    },

    SET_HISTORY_REVENUE(state, data) {
      state.dataRevenue = data;
    },

    SET_HISTORY_GROUP(state, data) {
      state.dataGroup = data;
    },

    SET_CHECK_PRESALE(state, data) {
      state.checkPresale = data;
    },

    SET_BALANCE_STATISTIC(state, data) {
      state.balanceStatistic = data;
    }
  },
};
