import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import i18n from '@/locales';
import Layout from './layouts/Master.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '*',
        redirect: { name: 'Dashboard' },
    },
    {
        path: '/',
        component: () => import('./views/ScriptView.vue'),
    },
    {
        path: '/',
        component: Layout,
        redirect: '/account',
        meta: {
            authRequired: true,
        },
        children: [
            {
                path: 'member',
                name: 'Member',
                component: () => import('./views/MemberView.vue'),
                meta: {
                    authRequired: true,
                    title: 'User Referral Link',
                },
            },
            {
                path: 'dashboard',
                name: 'Dashboard',
                component: () => import('@/router/views/DashboardView.vue'),
                meta: {
                    title: 'Dashboard',
                },
            },
            {
                path: 'vesting',
                name: 'Vesting',
                component: () => import('@/router/views/VestingView.vue'),
                meta: {
                    title: 'Vesting',
                },
            },
            {
                path: 'presale',
                name: 'Presale',
                component: () => import('@/router/views/PresaleView.vue'),
                meta: {
                    title: 'Presale',
                },
            },
            {
                path: 'wallet',
                name: 'Wallet',
                component: () => import('@/router/views/WalletView.vue'),
                meta: {
                    title: 'Wallet',
                },
            },
            {
                path: 'account',
                name: 'Account',
                component: () => import('@/router/views/AccountView.vue'),
                meta: {
                    title: 'Account',
                },
            },
            {
                path: 'activity',
                name: 'Activity',
                component: () => import('@/router/views/ActivityView.vue'),
                meta: {
                    title: 'Activities',
                },
            },
        ],
    },
    {
        path: '/',
        component: () => import('./layouts/Authentication.vue'),
        meta: {
            title: 'Login Marketplace',
        },
        children: [
            {
                path: '/login',
                name: 'Login',
                component: () => import('./views/Auth/Login.vue'),
                meta: {
                    title: 'Login Marketplace',
                },
            },
            {
                path: '/register',
                name: 'Register',
                component: () => import('./views/Auth/Register.vue'),
                meta: {
                    title: 'Register Marketplace',
                },
            },
            {
                path: '/forgot',
                name: 'Forgot',
                component: () => import('./views/Auth/Forgot.vue'),
                meta: {
                    title: 'Forgot Marketplace',
                },
            },
            {
                path: '/active-mail',
                name: 'Active-mail',
                component: () => import('./views/Auth/ActiveEmail.vue'),
                meta: {
                    title: 'Active Email Marketplace',
                },
            },
            {
                path: '/register-wallet',
                name: 'Register-wallet',
                component: () => import('./views/Auth/RegisterWallet.vue'),
                meta: {
                    title: 'Register Wallet Marketplace',
                },
            },
        ],
    },
];

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }
        return { x: 0, y: 0 };
    },
    linkActiveClass: 'active',
    linkExactActiveClass: 'active-children',
});
router.beforeEach((routeTo, routeFrom, next) => {
    const publicPages = ['/login', '/register', '/forgot', '/resend'];
    const authpage = publicPages.includes(routeTo.path);
    const isLogin = store.getters['auth/loggedIn'];
    if (routeTo.matched.some((m) => m.meta.comingSoon)) {
        store.commit('core/INFO_MESSAGE', i18n.t('comingSoon'), {
            root: true,
        });
        if (routeFrom) {
            next(routeFrom);
        } else {
            next({ name: 'Dashboard' });
        }
        return;
    }
    if (routeTo.matched.some((m) => m.meta.authRequired)) {
        if (isLogin) {
            next();
        } else {
            next({ name: 'Login' });
        }
    } else if (isLogin) {
        if (authpage) {
            next({ name: 'Account' });
        } else {
            next();
        }
    } else {
        next();
    }
});
export default router;
