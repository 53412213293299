<template>
  <div class="purchase">
    <div class="purchase__heading">
      <p class="heading__secondary">
        {{ $t('presale.nftPurchasedHistory') }}
      </p>

      <p class="purchase__heading--sub">NFT: {{ TruncateToDecimals2(PurchaseHistory.totalNFT) }}</p>
    </div>

    <div class="purchase__cont">
      <b-table id="table-purchase" striped hover :items="PurchaseHistory.results" borderless :fields="fields" responsive show-empty>
        <template #head(email)="data">
          <div>
            {{ $t('dashboardPage.email') }}
          </div>
        </template>

        <template #head(packageName)="data">
          <div>
            {{ $t('dashboardPage.packageName') }}
          </div>
        </template>

        <template #head(status)="data">
          <div>
            {{ $t('dashboardPage.status') }}
          </div>
        </template>

        <template #head(buy_date)="data">
          <div>
            {{ $t('dashboardPage.buyDate') }}
          </div>
        </template>

        <template #head(expired_date)="data">
          <div>
            {{ $t('dashboardPage.expiredDate') }}
          </div>
        </template>
        <template #cell(status)="data">
          <div class="span-container">
            <img src="@/assets/images/icons/success-new.svg" alt="success" />
            <span class="span-success">
              {{
                $t(`${data.value}`)
              }}
            </span>
          </div>
        </template>

        <template #cell(token)="data">
          <div class="coin-container">
            <p>
              {{
                TruncateToDecimals2(data.value)
              }}
            </p>
            <img src="@/assets/images/icons/token-table.svg" alt="usdt" />
          </div>
        </template>

        <template #cell(usdt)="data">
          <div class="coin-container">
            <p>
              {{
                TruncateToDecimals2(data.value)
              }}
            </p>
            <img src="@/assets/images/icons/usdt-table.svg" alt="usdt" />
          </div>
        </template>

        <template #cell(expired_date)="data">
          <div class="coin-container">
            <p v-if="data.value">
              {{
                getDateTime3(data.value)
              }}
            </p>
            <p v-else>
              N/A
            </p>
          </div>
        </template>
        <template #cell(buy_date)="data">
          <div class="coin-container">
            <p v-if="data.value">
              {{
                getDateTime3(data.value)
              }}
            </p>
            <p v-else>
              N/A
            </p>
          </div>
        </template>
        <template #cell(code)="data">
          <div class="code-container">
            <p>
              {{
                data.value
              }}
            </p>

            <b-button
              variant="success"
              size="sm"
              class="btn__secondary"

              v-clipboard:copy="data.value"
              v-clipboard:success="onCopy"
            >
              <svg
                width="12"
                height="12"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_3560_1200)">
                    <path
                        d="M21.75 24C22.9927 24 24 22.9927 24 21.75V8.25C24 7.00734 22.9927 6 21.75 6H8.25C7.00734 6 6 7.00734 6 8.25V21.75C6 22.9927 7.00734 24 8.25 24H21.75ZM8.25 4.5C6.18225 4.5 4.5 6.18225 4.5 8.25V18H2.25C1.00734 18 0 16.9927 0 15.75V2.25C0 1.00734 1.00734 0 2.25 0H15.75C16.9927 0 18 1.00734 18 2.25V4.5H8.25Z"
                        fill="#fff"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_3560_1200">
                        <rect
                            width="24"
                            height="24"
                            fill="white"
                            transform="matrix(1 0 0 -1 0 24)"
                        />
                    </clipPath>
                </defs>
            </svg>
            </b-button>
          </div>
        </template>

        <template #cell(isActivated)="data">
          <div>
            <svg v-if="data.value" xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 48 48">
              <defs>
                <mask id="ipSSuccess0">
                  <g fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="4">
                    <path fill="#fff" stroke="#fff" d="m24 4l5.253 3.832l6.503-.012l1.997 6.188l5.268 3.812L41 24l2.021 6.18l-5.268 3.812l-1.997 6.188l-6.503-.012L24 44l-5.253-3.832l-6.503.012l-1.997-6.188l-5.268-3.812L7 24l-2.021-6.18l5.268-3.812l1.997-6.188l6.503.012z" />
                    <path stroke="#000" d="m17 24l5 5l10-10" />
                  </g>
                </mask>
              </defs>
              <path fill="#36f27e" d="M0 0h48v48H0z" mask="url(#ipSSuccess0)" />
            </svg>
            <svg v-else xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24">
              <path fill="#f93939" d="m8.4 17l3.6-3.6l3.6 3.6l1.4-1.4l-3.6-3.6L17 8.4L15.6 7L12 10.6L8.4 7L7 8.4l3.6 3.6L7 15.6zm3.6 5q-2.075 0-3.9-.788t-3.175-2.137T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22" />
            </svg>
          </div>
        </template>
      </b-table>
      <b-pagination
          v-model="currentPage"
          :total-rows="PurchaseHistory.totalResults"
          :per-page="perPage"
          aria-controls="table-purchase"
          pills
          align="right"
          id="table-pagination-purchase"
        ></b-pagination>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      fields: [
        {
          key: 'code',
          label: 'Package code',
        },
        {
          key: 'isActivated',
          label: 'Active',
        },
        {
          key: '_id',
          label: 'Id',
        },
        {
          key: 'packageName',
          label: 'Package name',
        },
        {
          key: 'price',
          label: 'USDT',
        },
        {
          key: 'token',
          label: 'Token',
        },
        {
          key: 'status',
          label: 'Status',
        },
        {
          key: 'buy_date',
          label: 'Buy Date',
        },
        {
          key: 'expired_date',
          label: 'Expired Date',
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 4,
    };
  },
  computed: {
    ...mapGetters({
      UserInfo: 'info/UserInfo',
      PresaleInfo: 'presale/PresaleInfo',
      PurchaseHistory: 'presale/PurchaseHistory',
    }),

    params() {
      return {
        type: 'me',
        presaleId: this.PresaleInfo._id,
        page: this.currentPage,
        limit: this.perPage,
      };
    }
  },
  methods: {
    onCopy() {
        this.$toastr.s('Copy Package Code Success', 'Successfully');
    },
  },
  mounted() {
    this.$store.dispatch('presale/req_getPurchaseHistory', this.params);
  },
  watch: {
    PresaleInfo() {
      this.$store.dispatch('presale/req_getPurchaseHistory', this.params);
    },
    currentPage() {
      this.$store.dispatch('presale/req_getPurchaseHistory', this.params);
    },
  }
};
</script>

<style scoped lang="scss">
.heading__secondary {
  font-size: 22px;
  font-weight: 600;
  color: #fff;
}

.purchase {
  padding: 32px 74px;
  display: grid;
  gap: 40px;
  background: linear-gradient(#2c572d, #2c572d) padding-box,
    linear-gradient(to left, #A6ED8D, #078C04) border-box;
  border-radius: 8px;
  border: 2px solid transparent;
  position: relative;
  color: white;

  &__heading {
    display: flex;
    justify-content: space-between;
    gap: 16px;

    &--sub {
      color: #AAFF95;
      font-weight: 600;
      font-size: 18px;
    }
  }

}

.span-container {
  background-color: #87E14F;
  padding: 4px 8px;
  border-radius: 8px;
  margin: auto 0;
  width: fit-content;
  display: flex;
  gap: 4px;
}

.span-success {
  color: #08270C;
  font-size: 12px;
}

.coin-container {
  color: white;
  display: flex;
  gap: 12px;
  align-items: center;

  p {
    margin-bottom: 0;
    font-weight: 500;
    font-size: 14px;
  }
}

.code-container {
  display: flex;
  gap: 12px;
  align-items: center;

  p {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 500px) {
  .purchase {
    gap: 24px;
    padding: 12px 24px;
  }
}
</style>

<style lang="scss">
.table-striped tbody tr {
  border-radius: 8px;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #16391A;
  border-radius: 8px;
  color: #AAFF95;
  padding: 16px 32px;
}

.table-striped tbody tr:nth-of-type(even) {
  background-color: transparent;
  color: white;
  padding: 16px 32px;
}

.table th,
.table td {
  padding: 16px 32px;
  vertical-align: middle;
  text-align: center;
}

table {
  thead {
    tr {
      th {
        div {
          color: white;
          font-weight: 500;
          font-size: 14px;
          display: inline-block;
          text-wrap: nowrap;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        font-size: 16px;
        font-weight: 500;
        text-wrap: nowrap;
      }
    }
  }
}
</style>

<style lang="scss">
#table-pagination-purchase {
  .page-item.active .page-link {
    background-color: #36f27e;
    border-color: #36f27e;
  }

  .page-link {
    color: #36f27e;
    font-weight: 600;
  }

  .page-item.active .page-link {
    z-index: 1;
    color: #fff;
    background-color: #36f27e;
    border-color: #36f27e;
  }
}
</style>
