import VueI18n from 'vue-i18n';
import Vue from 'vue';
import en from './lang/en.json';
import vi from './lang/vi.json';
import ja from './lang/ja.json';
import ko from './lang/ko.json';
import zh from './lang/zh.json';
import ru from './lang/ru.json';

const messages = {
    en,
    vi,
    ja,
    ko,
    zh,
    ru
};

Vue.use(VueI18n);
export default new VueI18n({
    locale: 'en',
    fallbackLocale: 'en',
    messages,
});
