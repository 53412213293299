<template>
  <div class="main">

    <!-- <div class="countdown">
      <div class="countdown__container">
        <div class="countdown__item">
          <p class="countdown__num" v-for="(char, index) in days.split('')" :key="`${index}day`">{{ char }}</p>
          <p class="countdown__num">:</p>

          <p class="countdown__num" v-for="(char, index) in hours.split('')" :key="`${index}hour`">{{ char }}</p>
          <p class="countdown__num">:</p>

          <p class="countdown__num" v-for="(char, index) in minutes.split('')" :key="`${index}minute`">{{ char }}</p>
          <p class="countdown__num">:</p>

          <p class="countdown__num" v-for="(char, index) in seconds.split('')" :key="`${index}second`">{{ char }}</p>
        </div>
      </div>
    </div> -->

    <div class="progress-bar">
      <div class="progress__container" :style="{ width: `${getProgressBar}%` }"></div>
    </div>

    <div class="progress__content">
      <div class="progress__content--item">
        <p class="text__white">
          {{ $t('presale.packageSold') }}
        </p>
        <span class="text__white">/</span>
        <p class="text__white">
          {{ $t('presale.totalPackages') }}
        </p>
      </div>

      <div class="progress__content--item">
        <p class="text__white">{{ PresaleInfo.soldCount }}</p>
        <!-- <p v-else class="text__white">N/A</p> -->
        <span class="text__green">/</span>
        <p class="text__green">{{ PresaleInfo.maxPresale }}</p>
        <!-- <p v-else class="text__white">N/A</p> -->
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      totalSeconds: 10, // Set your countdown time here in seconds
      now: 0,
      days: '00',
      hours: '00',
      minutes: '00',
      seconds: '00',
      isComplete: false,
      progress: 100,
      intervalId: null,
    }
  },
  computed: {
    ...mapGetters({
      UserInfo: 'info/UserInfo',
      PresaleInfo: 'presale/PresaleInfo',
    }
    ),
    getProgressBar() {
      let result = 0;
      if (this.PresaleInfo.maxPresale && this.PresaleInfo.soldCount) {
        // result = ((this.PresaleInfo.maxPresale - this.PresaleInfo.soldCount) / this.PresaleInfo.maxPresale) * 100;
        result = (this.PresaleInfo.soldCount / this.PresaleInfo.maxPresale) * 100;
      }
      return result;
    },
    getSeconds() {
      const timeStart = new Date(this.PresaleInfo?.timeStart);
      const timeEnd = new Date(this.PresaleInfo?.timeEnd);
      let differenceInSeconds = 0;
      if (this.now && timeEnd && timeStart) {
        if (timeStart - this.now > 0) {
          differenceInSeconds = (timeStart - this.now) / 1000;

        } else if (timeEnd - this.now > 0) {
          differenceInSeconds = (timeEnd - this.now) / 1000;
        }
      }

      return differenceInSeconds;
    },
  },
  created() {
    this.now = new Date();
    this.startCountdown();

    this.intervalId = setInterval(() => {
      this.now = new Date();

      this.progress -= 100 / this.getSeconds; // Decrease by 1/60th of the total width every second

      if (this.progress < 1) {
        this.progress = 0; // Set progress to 0 if it's less than 1
      }
    }, 1000);

    setTimeout(() => {
      clearInterval(this.intervalId); // Stop the interval after 60 seconds
    }, 10000000);
  },
  methods: {
    // startCountdown() {
    //   const countdownInterval = setInterval(() => {
    //     if (this.totalSeconds <= 0) {
    //       clearInterval(countdownInterval);
    //     } else {
    //       this.totalSeconds -= 1;
    //       this.days = String(Math.floor(this.totalSeconds / (60 * 60 * 24))).padStart(2, '0');
    //       this.hours = String(Math.floor((this.totalSeconds % (60 * 60 * 24)) / (60 * 60))).padStart(2, '0');
    //       this.minutes = String(Math.floor((this.totalSeconds % (60 * 60)) / 60)).padStart(2, '0');
    //       this.seconds = String(Math.floor(this.totalSeconds % 60)).padStart(2, '0');
    //     }
    //   }, 1000);
    // },
    startCountdown() {
      const countdownInterval = setInterval(() => {
        this.days = String(Math.floor(this.getSeconds / (60 * 60 * 24))).padStart(2, '0');
        this.hours = String(Math.floor((this.getSeconds % (60 * 60 * 24)) / (60 * 60))).padStart(2, '0');
        this.minutes = String(Math.floor((this.getSeconds % (60 * 60)) / 60)).padStart(2, '0');
        this.seconds = String(Math.floor(this.getSeconds % 60)).padStart(2, '0');
      }, 1000);
    },
  },
}
</script>

<style lang="scss" scoped>
.text__green {
  color: #78D361;
}

.text__white {
  color: white;
}

.main {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.countdown {
  width: 100%;

  z-index: 5;

  &__container {
    width: 100%;
  }

  &__item {
    display: flex;
    justify-content: space-between;
  }

  &__num {
    background: linear-gradient(to bottom, #7FDF44, #34760C);
    color: #fff;
    font-weight: 700;
    font-size: 44px;
    border-radius: 16px;
    padding: 16px 0px;
    min-width: 72px;
    text-align: center;
  }
}

.progress {
  z-index: 5;

  &__container {
    width: 100%;
    height: 100%;
    background: linear-gradient(to left, #8BF04D, #2E6D08);
    transition: width 1s linear;
  }

  &-bar {
    width: 100%;
    height: 28px;
    background: #2E6D08;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 0 10px #BCFEAB;
  }

  &--complete {
    width: 0;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    width: 100%;

    p,
    span {
      font-weight: 500;
      font-size: 18px;
      margin-bottom: 0;

      .text__green {
        color: #78D361;
      }
    }

    &--item {
      display: flex;
      gap: 8px;
    }
  }

  &__text {
    color: white;
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 0;

    &--active {
      color: #78D361;
      font-weight: 600;
      font-size: 20px;
      margin-bottom: 0;
    }
  }
}

@media screen and (max-width: 1550px) {
  .countdown {
    &__num {
      font-size: 32px;
      padding: 16px 0px;
      min-width: 68px;
    }
  }

  .progress {
    &-bar {
      height: 20px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .countdown {
    &__num {
      font-size: 24px;
      padding: 12px 0px;
      min-width: 44px;
    }
  }

  .progress {
    &-bar {
      height: 18px;
    }
  }
}

@media screen and (max-width: 768px) {
  .countdown {
    &__num {
      font-size: 24px;
      padding: 12px 0px;
      min-width: 40px;
    }
  }
}

@media screen and (max-width: 500px) {
  .main {
    gap: 16px;
  }

  .countdown {
    &__num {
      font-size: 16px;
      padding: 8px 0px;
      min-width: 28px;
    }
  }

  .progress {
    &__content {

      p,
      span {
        font-size: 14px;
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .countdown {
    &__num {
      min-width: 24px;
    }
  }

  .progress {
    &__content {

      p,
      span {
        font-size: 12px;
      }
    }
  }
}
</style>
