<script>
import { mapGetters } from 'vuex';
import Paginate from '@/components/shared/Paginate.vue';
import VueQr from 'vue-qr';

export default {
    components: {
        Paginate,
        VueQr,
    },
    data() {
        return {
            search: {
                search: '',
                page: 1,
                address: null,
            },
            fields: [
                {
                    key: 'refId',
                    sortable: false,
                    label: 'refID',
                    class: 'text-center',
                },
                {
                    key: 'email',
                    sortable: false,
                    label: 'email',
                    class: 'text-center',
                },
                // {
                //     key: 'ref',
                //     label: 'Referral',
                //     sortable: false,
                //     class: 'text-center',
                // },
                {
                    key: 'sponsor',
                    label: 'sponsor',
                    class: 'text-center',
                },
                {
                    key: 'f',
                    label: 'f',
                    class: 'text-center',
                },
                {
                    key: 'createAt',
                    label: 'registerDate',
                },
            ],
            mobileFields: [
                {
                    key: 'refId',
                    sortable: false,
                    label: 'refID',
                    class: 'text-left',
                },
                // {
                //     key: 'email',
                //     label: 'Email',
                //     sortable: false,
                //     class: 'text-center',
                // },
                {
                    key: 'f',
                    label: 'f',
                    class: 'text-center',
                },
                {
                    key: '#',
                    label: 'action',
                    class: 'text-center',
                },
            ],
            currentPage: 1,
            totalPages: 10,
            renderComponent: true,
            options2: {
                useEasing: true,
                useGrouping: true,
                separator: ',',
                decimal: '.',
                prefix: '',
                suffix: ' USDT',
                decimalPlaces: 0,
            },
            delay: 2000,
            staticUser: {
                member: 0,
                usdt: 0,
            },
            comQuery: {
                page: 1,
            },
            comFields: [
                {
                    key: 'amount',
                    label: 'amount',
                    class: 'text-center',
                },
                {
                    key: 'currency',
                    label: 'currency',
                    class: 'text-center',
                },
                {
                    key: 'createdAt',
                    label: 'createdDate',
                    class: 'text-center',
                },
                {
                    key: '#',
                    label: 'more',
                    class: 'text-center',
                },
            ],
            option: {
                tooltip: {
                    trigger: 'item',
                    formatter: ({ name, value, percent, seriesName }) => {
                        return `${seriesName} <br /> ${name}: ${this.TruncateToDecimals2(
                            value,
                        )} USDT (${percent}%)`;
                    },
                },
                legend: {
                    orient: 'horizontal',
                    bottom: 'bottom',
                    textStyle: {
                        fontFamily: 'Inter',
                        color: '#fff',
                    },
                },
            },
        };
    },
    computed: {
        url() {
            return `${window.location.origin}/register?sponsor=${this.UserInfo.refId}`;
        },
        ...mapGetters({
            UserInfo: 'info/UserInfo',
            Member: 'member/Member',
            CommissionHistory: 'member/CommissionHistory',
            Statistic: 'member/Statistic',
            MaxOut: 'mining/MaxOut',
            InfoTree: 'presale/InfoTree',
        }),
        MyReference() {
            return Object.entries(this.Member.refs);
        },
        BuyNodeCommissions() {
            return this.Member.commissions.find(
                (el) => el._id === 'comm_buy_node',
            );
        },
        BuyNodeIncomeCommissions() {
            return this.Member.commissions.find(
                (el) => el._id === 'comm_buy_node_incom',
            );
        },
        ClaimCommissions() {
            return this.Member.commissions.find(
                (el) => el._id === 'comm_claim',
            );
        },
        ClaimIncomeCommissions() {
            return this.Member.commissions.find(
                (el) => el._id === 'comm_claim_incom',
            );
        },
        ComputedData() {
            // eslint-disable-next-line prefer-destructuring
            let MaxOut = Number(this.MaxOut);
            const data = [];

            if (this.BuyNodeCommissions && this.BuyNodeCommissions.total) {
                data.push({
                    value: this.BuyNodeCommissions.total,
                    name: this.$i18n.t('directBuyCoM'),
                    itemStyle: {
                        color: '#5589B9',
                    },
                });
                MaxOut -= this.BuyNodeCommissions.total;
            }

            if (
                this.BuyNodeIncomeCommissions &&
                this.BuyNodeIncomeCommissions.total
            ) {
                data.push({
                    value: this.BuyNodeIncomeCommissions.total,
                    name: this.$i18n.t('indirectBuyCoM'),
                    itemStyle: {
                        color: '#0ea5e9',
                    },
                });
                MaxOut -= this.BuyNodeIncomeCommissions.total;
            }

            if (this.ClaimCommissions && this.ClaimCommissions.usdt) {
                data.push({
                    value: this.ClaimCommissions.usdt,
                    name: this.$i18n.t('directClaimCoM'),
                    itemStyle: {
                        color: '#0f766e',
                    },
                });
                MaxOut -= this.ClaimCommissions.usdt;
            }

            if (
                this.ClaimIncomeCommissions &&
                this.ClaimIncomeCommissions.usdt
            ) {
                data.push({
                    value: this.ClaimIncomeCommissions.usdt,
                    name: this.$i18n.t('indirectClaimCoM'),
                    itemStyle: {
                        color: '#14b8a6',
                    },
                });
                MaxOut -= this.ClaimIncomeCommissions.usdt;
            }

            data.push({
                value: MaxOut,
                name: this.$i18n.t('remainingCommission'),
                itemStyle: {
                    color: '#78E8F7',
                    label: false,
                },
            });

            if (this.MaxOut) {
                return data;
            }
            return [];
        },
        ComputedOptions() {
            return {
                ...this.option,
                series: [
                    {
                        name: this.$i18n.t('commissions'),
                        type: 'pie',
                        radius: '50%',
                        data: this.ComputedData,
                        emphasis: {
                            itemStyle: {
                                shadowBlur: 10,
                                shadowOffsetX: 0,
                                shadowColor: 'rgba(0, 0, 0, 0.5)',
                            },
                        },
                    },
                ],
                title: {
                    text: this.$i18n.t('commissionChart'),
                    left: 'center',
                    textStyle: {
                        fontFamily: 'Inter',
                        color: '#fff',
                    },
                },
            };
        },
    },
    methods: {
        onCopy() {
            this.$toastr.s('Copy Link Referral Success', 'Successfully');
        },
        CopyAddress(string) {
            if (window.navigator.clipboard) {
                window.navigator.clipboard
                    .writeText(string)
                    .then(() => {
                        this.$toastr.s('Address copied', 'Successfully');
                    })
                    .catch(() => {
                        this.$toastr.e('Failed to copy', 'Error');
                    });
            } else {
                this.$toastr.e('Failed to copy', 'Error');
            }
        },
        onError() {
            this.$toastr.e(
                'Copy Link Referral Fail,Please Try Again',
                'Failed',
            );
        },
        onSearch(type) {
            if (type === 'reset') {
                this.search = {
                    search: '',
                    page: 1,
                };
            }
            this.$store.dispatch('member/req_getListMember', this.search);
            this.forceRerender();
            this.search.page = 1;
        },
        onChangePage(payload) {
            this.search.page = payload;
            this.$store.dispatch('member/req_getListMember', this.search);
        },
        forceRerender() {
            // Remove my-component from the DOM
            this.renderComponent = false;

            this.$nextTick(() => {
                // Add the component back in
                this.renderComponent = true;
            });
        },
        async StatisUser() {
            const { register } = this.$store.state.contract.managers;
            const { address } = this.UserInfo;
            if (!register.cont) return;
            const data = await register.cont.methods
                .countReferral(address)
                .call();
            if (data) {
                console.log(data);
                this.staticUser.member = data;
                this.staticUser.usdt = this.TruncateToDecimals2(
                    parseInt(data, 0) * 2.5,
                    '',
                    4,
                );
            }
        },
        changeComPage(payload) {
            this.comQuery.page = payload;
            this.$store.dispatch(
                'member/req_getCommissionHistory',
                this.comQuery,
            );
        },
    },
    watch: {
        UserInfo: {
            handler() {
                this.$store.dispatch('member/req_getListMember', {
                    // address: newVal.address,
                });
                // this.StatisUser();
                this.$store.dispatch('member/req_getDownline');
                // this.search.address = newVal.address;
                this.$store.dispatch('member/req_getCommissionStat');
                this.$store.dispatch(
                    'member/req_getCommissionHistory',
                    this.comQuery,
                );
            },
        },
    },
    mounted() {
        const self = this;
        if (self.UserInfo) {
            this.$store.dispatch('member/req_getListMember', {
                // address: self.UserInfo.address,
            });
            this.$store.dispatch('member/req_getDownline');
            this.$store.dispatch('member/req_getCommissionStat');
            this.$store.dispatch(
                'member/req_getCommissionHistory',
                this.comQuery,
            );
            // if (this.UserInfo.address) {
            //     this.StatisUser();
            // }
        }
        // this.$store.dispatch('mining/max_out');

        this.$store.dispatch('presale/req_getInfoTree');
    },
};
</script>
<template>
    <b-container class="member-tab">
        <div class="statistical">
            <div class="d-flex align-items-center mb-2">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                >
                    <path
                        d="M13.125 20H8.75C7.42392 20 6.15215 19.4732 5.21447 18.5355C4.27678 17.5979 3.75 16.3261 3.75 15C3.75 13.6739 4.27678 12.4021 5.21447 11.4645C6.15215 10.5268 7.42392 10 8.75 10H13.125C13.2908 10 13.4497 9.93415 13.5669 9.81694C13.6842 9.69973 13.75 9.54076 13.75 9.375C13.75 9.20924 13.6842 9.05027 13.5669 8.93306C13.4497 8.81585 13.2908 8.75 13.125 8.75H8.75C7.0924 8.75 5.50269 9.40848 4.33058 10.5806C3.15848 11.7527 2.5 13.3424 2.5 15C2.5 16.6576 3.15848 18.2473 4.33058 19.4194C5.50269 20.5915 7.0924 21.25 8.75 21.25H13.125C13.2908 21.25 13.4497 21.1842 13.5669 21.0669C13.6842 20.9497 13.75 20.7908 13.75 20.625C13.75 20.4592 13.6842 20.3003 13.5669 20.1831C13.4497 20.0658 13.2908 20 13.125 20ZM10 15C10 15.1658 10.0658 15.3247 10.1831 15.4419C10.3003 15.5592 10.4592 15.625 10.625 15.625H19.375C19.5408 15.625 19.6997 15.5592 19.8169 15.4419C19.9342 15.3247 20 15.1658 20 15C20 14.8342 19.9342 14.6753 19.8169 14.5581C19.6997 14.4408 19.5408 14.375 19.375 14.375H10.625C10.4592 14.375 10.3003 14.4408 10.1831 14.5581C10.0658 14.6753 10 14.8342 10 15ZM21.25 8.75H16.875C16.7092 8.75 16.5503 8.81585 16.4331 8.93306C16.3158 9.05027 16.25 9.20924 16.25 9.375C16.25 9.54076 16.3158 9.69973 16.4331 9.81694C16.5503 9.93415 16.7092 10 16.875 10H21.25C22.5761 10 23.8479 10.5268 24.7855 11.4645C25.7232 12.4021 26.25 13.6739 26.25 15C26.25 16.3261 25.7232 17.5979 24.7855 18.5355C23.8479 19.4732 22.5761 20 21.25 20H16.875C16.7092 20 16.5503 20.0658 16.4331 20.1831C16.3158 20.3003 16.25 20.4592 16.25 20.625C16.25 20.7908 16.3158 20.9497 16.4331 21.0669C16.5503 21.1842 16.7092 21.25 16.875 21.25H21.25C22.9076 21.25 24.4973 20.5915 25.6694 19.4194C26.8415 18.2473 27.5 16.6576 27.5 15C27.5 13.3424 26.8415 11.7527 25.6694 10.5806C24.4973 9.40848 22.9076 8.75 21.25 8.75Z"
                        fill="#aaff87"
                    />
                    <defs>
                        <linearGradient
                            id="paint0_linear_57_1580"
                            x1="2.5"
                            y1="15"
                            x2="27.5"
                            y2="15"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop offset="0.48" stop-color="#aaff87" />
                            <stop offset="1" stop-color="#aaff87" />
                        </linearGradient>
                    </defs>
                </svg>
                <div class="title p-0 pl-2">{{ $t('referralLink') }}</div>
            </div>
            <b-row class="overflow-hidden mx-n1">
                <b-col
                    cols="12"
                    sm="6"
                    lg="3"
                    class="my-1 px-1"
                    v-if="BuyNodeCommissions"
                >
                    <div class="box-statistical">
                        <div class="text">
                            <div class="text-title">
                                {{ $t('directBuyCoM') }}
                            </div>
                            <div class="text-content">
                                {{
                                    TruncateToDecimals2(
                                        BuyNodeCommissions.total,
                                    )
                                }}
                                USDT
                            </div>
                        </div>
                    </div>
                </b-col>
                <b-col
                    cols="12"
                    sm="6"
                    lg="3"
                    class="my-1 px-1"
                    v-if="BuyNodeIncomeCommissions"
                >
                    <div class="box-statistical">
                        <div class="text">
                            <div class="text-title">
                                {{ $t('indirectBuyCoM') }}
                            </div>
                            <div class="text-content">
                                {{
                                    TruncateToDecimals2(
                                        BuyNodeIncomeCommissions.total,
                                    )
                                }}
                                USDT
                            </div>
                        </div>
                    </div>
                </b-col>
                <b-col
                    cols="12"
                    sm="6"
                    lg="3"
                    class="my-1 px-1"
                    v-if="ClaimCommissions"
                >
                    <div class="box-statistical">
                        <div class="text">
                            <div class="text-title">
                                {{ $t('directClaimCoM') }}
                            </div>
                            <div class="text-content">
                                <div>
                                    {{
                                        TruncateToDecimals2(
                                            ClaimCommissions.total,
                                        )
                                    }}
                                    ECH
                                </div>
                                ~
                                <div>
                                    {{
                                        TruncateToDecimals2(
                                            ClaimCommissions.usdt,
                                        )
                                    }}
                                    USDT
                                </div>
                            </div>
                        </div>
                    </div>
                </b-col>
                <b-col
                    cols="12"
                    sm="6"
                    lg="3"
                    class="my-1 px-1"
                    v-if="ClaimIncomeCommissions"
                >
                    <div class="box-statistical">
                        <div class="text">
                            <div class="text-title">
                                {{ $t('indirectClaimCoM') }}
                            </div>
                            <div class="text-content">
                                <div>
                                    {{
                                        TruncateToDecimals2(
                                            ClaimIncomeCommissions.total,
                                        )
                                    }}
                                    ECH
                                </div>
                                ~
                                <div>
                                    {{
                                        TruncateToDecimals2(
                                            ClaimIncomeCommissions.usdt,
                                        )
                                    }}
                                    USDT
                                </div>
                            </div>
                        </div>
                    </div>
                </b-col>
                <b-col cols="12" sm="6" lg="3" class="my-1 px-1">
                    <div class="box-statistical">
                        <div class="text">
                            <div class="text-title">
                                {{ $t('maxoutCoM') }}
                            </div>
                            <div class="text-content">
                                {{ TruncateToDecimals2(MaxOut) }} USDT
                            </div>
                        </div>
                    </div>
                </b-col>
            </b-row>
            <div class="row my-1">
                <div class="col-lg-8">
                    <div class="table-statistical">
                        <div class="row">
                            <div class="col-lg-5 col-xl-6">
                                <div class="text-center">
                                    <VueQr :text="url" class="logo" />
                                </div>
                            </div>
                            <div class="col-lg-7 col-xl-6">
                                <div class="item">
                                    <div>
                                        {{ $t('totalDownline') }}
                                    </div>
                                    <div>
                                      {{
                                        TruncateToDecimals2(InfoTree.totalMember)
                                      }}
                                    </div>
                                </div>
                                <div class="item">
                                    <div>
                                        {{ $t('totalF1') }}
                                    </div>
                                    <div>
                                      {{
                                        TruncateToDecimals2(InfoTree.totalF1)
                                      }}
                                    </div>
                                </div>
                                <div class="item">
                                    <div>
                                        {{ $t('totalF1Sales') }}
                                    </div>
                                    <div>
                                      {{
                                        TruncateToDecimals2(InfoTree.totalF1Sales)
                                      }}
                                      USDT
                                    </div>
                                </div>
                                <div class="item">
                                    <div>
                                        {{ $t('totalGroupSales') }}
                                    </div>
                                    <div>
                                        {{
                                          TruncateToDecimals2(InfoTree.totalGroupSales)
                                        }}
                                        USDT
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6">
                                <div class="ref-link">
                                    {{ url }}
                                </div>
                                <b-button
                                    class="copy"
                                    v-clipboard:copy="url"
                                    v-clipboard:success="onCopy"
                                    v-clipboard:error="onError"
                                >
                                    <svg
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <g clip-path="url(#clip0_3560_1200)">
                                            <path
                                                d="M21.75 24C22.9927 24 24 22.9927 24 21.75V8.25C24 7.00734 22.9927 6 21.75 6H8.25C7.00734 6 6 7.00734 6 8.25V21.75C6 22.9927 7.00734 24 8.25 24H21.75ZM8.25 4.5C6.18225 4.5 4.5 6.18225 4.5 8.25V18H2.25C1.00734 18 0 16.9927 0 15.75V2.25C0 1.00734 1.00734 0 2.25 0H15.75C16.9927 0 18 1.00734 18 2.25V4.5H8.25Z"
                                                fill="#fff"
                                            />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_3560_1200">
                                                <rect
                                                    width="24"
                                                    height="24"
                                                    fill="white"
                                                    transform="matrix(1 0 0 -1 0 24)"
                                                />
                                            </clipPath>
                                        </defs>
                                    </svg>

                                    <div class="text pl-2">
                                        {{ $t('referralLink') }}
                                    </div>
                                </b-button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-4">
                    <div class="chart">
                        <v-chart
                            :option="ComputedOptions"
                            :autoresize="true"
                        ></v-chart>
                    </div>
                </div>
            </div>
        </div>
        <div class="member-list">
            <div
                class="d-flex justify-content-between flex-wrap align-items-start mb-3"
            >
                <div class="d-flex align-items-center">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        viewBox="0 0 30 30"
                        fill="none"
                        class="mr-2"
                    >
                        <path
                            d="M15 6.25C13.8397 6.25 12.7269 6.71094 11.9064 7.53141C11.0859 8.35188 10.625 9.46468 10.625 10.625C10.625 11.7853 11.0859 12.8981 11.9064 13.7186C12.7269 14.5391 13.8397 15 15 15C16.1603 15 17.2731 14.5391 18.0936 13.7186C18.9141 12.8981 19.375 11.7853 19.375 10.625C19.375 9.46468 18.9141 8.35188 18.0936 7.53141C17.2731 6.71094 16.1603 6.25 15 6.25ZM15 8.75C15.4973 8.75 15.9742 8.94754 16.3258 9.29917C16.6775 9.65081 16.875 10.1277 16.875 10.625C16.875 11.1223 16.6775 11.5992 16.3258 11.9508C15.9742 12.3025 15.4973 12.5 15 12.5C14.5027 12.5 14.0258 12.3025 13.6742 11.9508C13.3225 11.5992 13.125 11.1223 13.125 10.625C13.125 10.1277 13.3225 9.65081 13.6742 9.29917C14.0258 8.94754 14.5027 8.75 15 8.75ZM6.875 10C6.0462 10 5.25134 10.3292 4.66529 10.9153C4.07924 11.5013 3.75 12.2962 3.75 13.125C3.75 14.3 4.4125 15.3125 5.3625 15.85C5.8125 16.1 6.325 16.25 6.875 16.25C7.425 16.25 7.9375 16.1 8.3875 15.85C8.85 15.5875 9.2375 15.2125 9.525 14.7625C8.61435 13.5757 8.12209 12.1209 8.125 10.625V10.275C7.75 10.1 7.325 10 6.875 10ZM23.125 10C22.675 10 22.25 10.1 21.875 10.275V10.625C21.875 12.125 21.3875 13.575 20.475 14.7625C20.625 15 20.7875 15.1875 20.975 15.375C21.5514 15.9339 22.3221 16.2475 23.125 16.25C23.675 16.25 24.1875 16.1 24.6375 15.85C25.5875 15.3125 26.25 14.3 26.25 13.125C26.25 12.2962 25.9208 11.5013 25.3347 10.9153C24.7487 10.3292 23.9538 10 23.125 10ZM15 17.5C12.075 17.5 6.25 18.9625 6.25 21.875V23.75H23.75V21.875C23.75 18.9625 17.925 17.5 15 17.5ZM5.8875 18.1875C3.475 18.475 0 19.7 0 21.875V23.75H3.75V21.3375C3.75 20.075 4.6125 19.025 5.8875 18.1875ZM24.1125 18.1875C25.3875 19.025 26.25 20.075 26.25 21.3375V23.75H30V21.875C30 19.7 26.525 18.475 24.1125 18.1875ZM15 20C16.9125 20 19.05 20.625 20.2875 21.25H9.7125C10.95 20.625 13.0875 20 15 20Z"
                            fill="url(#paint0_linear_57_1695)"
                        />
                        <defs>
                            <linearGradient
                                id="paint0_linear_57_1695"
                                x1="0"
                                y1="15"
                                x2="30"
                                y2="15"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop offset="0.48" stop-color="#aaff87" />
                                <stop offset="1" stop-color="#aaff87" />
                            </linearGradient>
                        </defs>
                    </svg>
                    <div class="title p-0">{{ $t('referralList') }}</div>
                </div>
                <!-- <div class="search-member">
                    <b-form-input
                        v-model="search.search"
                        placeholder="Enter Wallet Address Here"
                    ></b-form-input>
                    <b-button @click="onSearch">
                        <div class="icons">
                            <img
                                src="~@/assets/images/icons/search.png"
                                alt=""
                            />
                        </div>
                        Search
                    </b-button>
                </div> -->
            </div>

            <div class="d-none d-lg-block table-responsive">
                <b-table
                    striped
                    hover
                    :items="Member.list"
                    :fields="fields"
                    show-empty
                    thead-class="customer-header"
                >
                    <template #empty>
                        <h4 class="text-center my-4">{{ $t('noReferral') }}</h4>
                    </template>
                    <template #cell(refId)="data">
                        <span>
                            {{ data.value }}
                        </span>
                    </template>
                    <template #cell(sponsor)="data">
                        <span v-if="data.item.sponsors">
                            {{ data.item.sponsors[1] }}
                        </span>
                    </template>
                    <template #cell(f)="data">
                        <span v-if="data.item.sponsors">
                            F{{ data.value }}
                        </span>
                    </template>
                    <template #cell(address)="data">
                        <span>
                            {{ data.item.address }}
                        </span>
                    </template>

                    <template #cell(ref)="data">
                        <span> F{{ data.item.ref }} </span>
                    </template>
                    <template #cell(createAt)="data">
                        <span>
                            {{ getDateTime3(data.item.createdAt) }}
                        </span>
                    </template>
                    <template #head()="data">
                        {{ $t(data.label) }}
                    </template>
                </b-table>
            </div>
            <div class="d-lg-none table-responsive mobile">
                <b-table
                    :items="Member.list"
                    :fields="mobileFields"
                    show-empty
                    striped
                    thead-class="customer-header"
                >
                    <template #cell(#)="row">
                        <b-button
                            size="sm"
                            variant="info"
                            @click="row.toggleDetails"
                            class="px-2 py-0"
                        >
                            {{ row.detailsShowing ? $t('hide') : $t('show') }}
                        </b-button>
                    </template>
                    <template #cell(f)="data">
                        <span v-if="data.item.sponsors">
                            F{{ data.value }}
                        </span>
                    </template>
                    <template #cell(ref)="data">
                        F{{ data.item.ref }}
                    </template>
                    <template #row-details="{ item }">
                        <div class="details">
                            <!-- <div class="d-flex justify-content-between">
                                <div>Address:</div>
                                <button
                                    class="address"
                                    @click="CopyAddress(item.address)"
                                >
                                    {{ item.address }}
                                </button>
                            </div> -->
                            <div class="mobile-data">
                                <div>Email:</div>
                                <div>
                                    {{ item.email }}
                                </div>
                            </div>
                            <div class="mobile-data">
                                <div>{{ $t('createdDate') }}:</div>
                                <div>
                                    {{ getDateTime3(item.createdAt) }}
                                </div>
                            </div>
                            <div class="mobile-data">
                                <div>{{ $t('sponsor') }}:</div>
                                <div v-if="item.sponsors">
                                    {{ item.sponsors[1] }}
                                </div>
                            </div>
                        </div>
                    </template>
                    <template #head()="data">
                        {{ $t(data.label) }}
                    </template>
                </b-table>
            </div>
            <div class="table-paginate">
                <Paginate
                    @current="onChangePage"
                    :totalPages="Member.total"
                    v-if="renderComponent"
                />
            </div>
        </div>
        <div class="com-history">
            <div class="title">{{ $t('commissionHistory') }}</div>
            <div class="table-responsive" v-if="CommissionHistory.results">
                <b-table
                    striped
                    hover
                    :items="CommissionHistory.results"
                    :fields="comFields"
                    show-empty
                    thead-class="customer-header"
                >
                    <template #empty>
                        <h4 class="text-center my-4">
                            {{ $t('noCommission') }}
                        </h4>
                    </template>
                    <template #cell(createdAt)="data">
                        <span>
                            {{ getDateTime3(data.value) }}
                        </span>
                    </template>
                    <template #cell(comment)="data">
                        <div class="comment">
                            {{ data.value }}
                        </div>
                    </template>
                    <template #cell(currency)="{ value }">
                        <img
                            v-if="value === 'ECH' || value === 'bECH'"
                            src="@/assets/images/logo/ech.png"
                            width="30px"
                        />
                        <img
                            v-else
                            src="@/assets/images/logo/usdt.png"
                            width="30px"
                        />
                    </template>
                    <template #cell(#)="row">
                        <b-button
                            size="sm"
                            variant="info"
                            @click="row.toggleDetails"
                            class="px-2 py-0"
                        >
                            {{ row.detailsShowing ? 'Hide' : 'Show' }}
                        </b-button>
                    </template>
                    <template #row-details="{ item }">
                        <div class="px-2 text-center">
                            {{ item.comment }}
                        </div>
                    </template>
                    <template #head()="data">
                        {{ $t(data.label) }}
                    </template>
                </b-table>
            </div>
            <div class="table-paginate">
                <Paginate
                    v-if="CommissionHistory.results"
                    @current="changeComPage"
                    :totalPages="CommissionHistory.totalPages"
                />
            </div>
        </div>
    </b-container>
</template>
<style lang="scss">
.member-tab {
    .header-icon {
        max-width: 55px;
        width: 100%;
        height: 100%;

        img {
            width: 100%;
            height: auto;
            object-fit: scale-down;
        }
    }

    .title {
        font-size: clamp(1.2em, 4vw, 1.8em);
        padding-bottom: 10px;
        position: relative;
        letter-spacing: 0.6px;
        width: max-content;
        // margin: 0 0 25px;
        font-weight: 600;
        background: #aaff95;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .statistical {
        margin-top: 15px;
    }

    .member-list {
        margin-top: 30px;
    }

    .search-member {
        max-width: 467px;
        height: 48px;
        width: 100%;
        border-radius: 128px;
        padding: 0 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        margin-left: auto;
        gap: 10px;

        input {
            outline: none;
            border: none;
            height: 40px;
            width: 90%;
            border-radius: 12px;
            background: transparent;
            border: 1px solid #d8d8d8;
            color: #fff;

            &:focus {
                box-shadow: none;
            }
        }

        button {
            border-radius: 100vw;
            background: linear-gradient(50deg, #0087c8 14.98%, #00d1ff 106.07%);
            box-shadow: 0px 2px 11.6px 1px rgba(176, 176, 176, 0.25) inset;
            display: flex;
            gap: 5px;
        }
    }

    .box-input-group {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        gap: 5px;

        .link-input {
            border: none;
            max-width: 570px;
            width: 100%;
            padding: 5px;
            word-wrap: break-word;
            background: transparent;
            box-shadow: 0px 0px 5px 1px #0000001e;
            // opacity: 0.5;
            border-radius: 12px;
            outline: none;
            color: #a6a6a6;
        }

        .copy {
            background-image: linear-gradient(
                50deg,
                #0087c8 14.98%,
                #00d1ff 106.07%
            );
        }

        svg {
            margin-right: 5px;
        }

        input {
            outline: none;
            border: 1px solid #00c2ff;
            background: linear-gradient(180deg, #00172a 0%, #00000b 100%);
            height: 40px;
            width: 80%;
            border-radius: 0;
            color: #fff;
            border: none;
        }

        button {
            max-width: 170px;
            width: 100%;
            height: 42px;
            border-radius: 267px;
            background: rgba(0, 255, 255, 0.15);
            font-weight: 600;
            font-size: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: capitalize;
            border: none;
        }
    }

    .table-responsive {
        background: linear-gradient(#2c572d, #2c572d) padding-box,
            linear-gradient(to left, #a6ed8d, #078c04) border-box;
        border: 2px solid transparent;
        border-radius: 22px;

        .customer-header {
            background-color: transparent;
            text-align: center;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #fff;
        }

        .table th,
        .table td {
            border: none;
        }

        table {
            thead {
                tr {
                    th {
                        white-space: nowrap;
                    }
                }
            }

            tbody {
                tr {
                    height: 70px;
                    border-radius: 12px;
                    background: transparent;

                    &:nth-child(even) {
                        background: #e1e1e175;
                    }

                    td {
                        background: none;
                        height: 100%;
                        padding: 0.75rem;
                        vertical-align: middle;
                        color: #fff;
                        font-weight: 500;

                        .mobile-data {
                            display: flex;
                            justify-content: space-between;
                            flex-wrap: wrap;
                            line-height: 1;
                            padding: 5px 0px;

                            > div:last-child {
                                color: #fff;
                            }
                        }

                        > span {
                            height: 70px;
                            display: flex;
                            padding: 0 0.75rem;
                            align-items: center;
                            margin-bottom: 0;
                            justify-content: center;
                            backdrop-filter: blur(15.899999618530273px);
                        }
                    }
                }
            }
        }
    }

    .table-statistical {
        background: linear-gradient(#2c572d, #2c572d) padding-box,
            linear-gradient(to left, #a6ed8d, #078c04) border-box;
        border: 2px solid transparent;
        border-radius: 22px;
        padding: 15px;
        color: #fff;
        position: relative;
        z-index: 999;

        .logo {
            max-width: 165px;
            width: 100%;
            margin: 30px auto;
        }

        .ref-link {
            background-color: #16381a;
            box-shadow: 0px 0px 5px 1px #0000001e;
            border-radius: 6px;
            max-width: 465px;
            min-width: 0;
            margin: auto;
            display: block;
            width: 100%;
            border: none;
            padding: 6px 10px;
            word-wrap: break-word;
            text-align: center;
            font-size: 14px;
            line-height: 1;
        }

        .copy {
            background: #078c04;
            width: 100%;
            max-width: 465px;
            border-radius: 100vw;
            font-weight: 600;
            font-size: 18px;
            margin: auto;
            margin-top: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: capitalize;
            border: none;
            color: #fff;
            transition: 0.2s;

            &:hover {
                opacity: 0.8;
            }
        }

        .item {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            padding: 10px 0px;

            > div:last-child {
                color: #aaff87;
            }
        }

        .plane-1,
        .plane-2,
        .plane-3 {
            position: absolute;
            z-index: -999;
        }

        .plane-1 {
            top: 35px;
            left: 0;

            img {
                width: 150px;
            }
        }

        .plane-2 {
            bottom: 0;
            right: 0;

            img {
                width: 150px;
            }
        }

        .plane-3 {
            top: 15px;
            right: 50%;
        }

        .col-lg-7 {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    .box-statistical {
        background: linear-gradient(#2c572d, #2c572d) padding-box,
            linear-gradient(to left, #a6ed8d, #078c04) border-box;
        border: 2px solid transparent;
        height: 100%;
        outline: none;
        padding: 15px;
        position: relative;
        border-radius: 22px;

        .icons {
            flex: 0 0 70px;
            width: 70px;
            height: 100%;

            img {
                width: 50px;
                height: auto;
            }
        }

        .text {
            color: #fff;
            z-index: 1;
            flex-grow: 1;
            font-size: 14px;

            .text-title {
                font-size: 1em;
                font-style: italic;
                font-weight: 500;
                margin-bottom: 15px;
                text-transform: capitalize;
                display: flex;
                justify-content: space-between;
            }

            .text-content {
                font-size: 1.125em;
                font-weight: 700;
                display: flex;
                justify-content: space-between;
                gap: 5px;

                &.bound {
                    color: #0f766e;
                }

                > span {
                    min-width: 0;
                    word-wrap: break-word;
                }

                > div:last-child {
                    flex-shrink: 0;
                }
            }

            &.bound {
                .text-content {
                    color: #fff;
                }

                .text-title {
                    color: #fff;
                }
            }

            @media (min-width: 992px) {
                font-size: 16px;
            }
        }
    }

    .chart {
        display: flex;
        justify-content: center;
        align-items: center;
        background: linear-gradient(#2c572d, #2c572d) padding-box,
            linear-gradient(to left, #a6ed8d, #078c04) border-box;
        border: 2px solid transparent;
        border-radius: 22px;
        height: 100%;

        @media (max-width: 769px) {
            margin-top: 10px;
        }

        @media (max-width: 418px) {
            padding: 15px;
        }
    }
}

.echarts {
    width: 80%;
    height: 80%;
    aspect-ratio: 1;

    @media (max-width: 418px) {
        width: 100%;
        height: 375px;
    }
}
</style>
