<template>
    <div class="authen-container">
        <div class="left">
            <router-view></router-view>
        </div>
        <div class="right">
            <h3>
              {{ $t('authentication.heading') }}
            </h3>
            <p>
              {{ $t('authentication.subHeading') }}
            </p>
            <p>
              {{ $t('authentication.content') }}
            </p>
            <img src="../../assets/images/background/laptop.png" alt="" />
        </div>
        <NotificationModal />
    </div>
</template>

<script>
import NotificationModal from '@/components/shared/NotificationModal.vue';

export default {
    components: {NotificationModal}
};
</script>

<style lang="scss" scoped>
.authen-container {
    display: flex;
    min-height: 100vh;

    .left {
        width: 50%;
    }

    .right {
        width: 50%;
        padding: 40px;
        position: relative;
        z-index: 1;
        background: #164F1D;

        h3 {

            font-size: 30px;
            font-weight: 600;
            color: #fff;
            max-width: 560px;
            margin: auto;
            text-transform: uppercase;
        }

        p {
            margin: 3rem auto 2rem;

            font-size: 16px;
            font-weight: 400;
            color: #c6c6c6;
            max-width: 560px;
        }

        img {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 100%;
            z-index: -1;
        }
    }

    @media (max-width: 1025px) {
        flex-direction: column;
        overflow-y: hidden;
        .left {
            width: 100%;
            height: 100vh;
        }

        .right {
            display: none;
        }
    }

    @media (max-width: 990px) {
        .right {
            padding: 7.6rem 3rem 0 3rem;
        }
    }
}
</style>
