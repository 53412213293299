const axios = require('../axios.config').default;

export default {
    namespaced: true,
    state: () => ({
        member: {
            total: 1,
            list: [],
            totalRef: 0,
            totalRefF1: 0,
            refs: {},
            commissions: [],
        },
        statistical: {},
        statisticalMonth: {},
        statisticalWeek: {},
        comissionHistory: {},
        topThree: {}
    }),

    getters: {
        Member: (state) => state.member,
        CommissionHistory: (state) => state.comissionHistory,
        Statistic: (state) => state.statistical,
        StatisticWeek: (state) => state.statisticalWeek,
        StatisticMonth: (state) => state.statisticalMonth,
        TopThreeTree: (state) => state.topThree
    },

    actions: {
        req_getListMember: ({ commit }, input) => {
            axios
                .get('/user/get-downlines', {
                    params: {
                        search: input.search || null,
                        page: input.page || 1,
                        limit: 4,
                        address: input.address,
                    },
                })
                .then((res) => {
                    commit('SET_LIST_MEMBER', res.data);
                });
        },
        req_getDownline: ({ commit }) => {
            axios.get('/user/ref-statistic').then((res) => {
                commit('SET_DOWNLINE', res.data);
            });
        },
        req_getCommissionStat: ({ commit }) => {
            axios.get('/user/commission-statistic').then((res) => {
                commit('SET_COMMISSION_STAT', res.data);
            });
        },
        req_getCommissionHistory: ({ commit }, input) => {
            axios
                .get('/user/activity-history', {
                    params: { type: 'commission', limit: 5, page: input.page },
                })
                .then((res) => {
                    commit('SET_HISTORY', res.data);
                });
        },
        req_getStatisticalMonth: ({ commit}) => {
          axios.get('/statistical/statistical-month').then((res) => {
            commit('SET_STATISTICAL_MONTH', res.data);
          });
        },
        req_getStatisticalWeek: ({ commit}) => {
          axios.get('/statistical/statistical-week').then((res) => {
            commit('SET_STATISTICAL_WEEK', res.data);
          });
        },
        req_topThreeTree: ({ commit }) => {
            axios.get('/statistical/top3-tree').then((res) => {
                commit('SET_LIST_TOP_TREE', res.data);
            });
        }
    },
    mutations: {
        SET_LIST_MEMBER(state, data) {
            state.member.total = data.totalPages;
            state.member.list = data.results;
            state.member.totalRef = data.totalRef;
            state.member.totalRefF1 = data.totalRefF1;
        },
        SET_DOWNLINE(state, data) {
            state.member.refs = data;
        },
        SET_COMMISSION_STAT(state, data) {
            state.member.commissions = data;
        },
        SET_HISTORY(state, data) {
            state.comissionHistory = data;
        },
        SET_STATISTICAL(state, data) {
            state.statistical = data;
        },
        SET_STATISTICAL_MONTH(state, data) {
          state.statisticalMonth = data;
        },
        SET_STATISTICAL_WEEK(state, data) {
          state.statisticalWeek = data;
        },
        SET_LIST_TOP_TREE(state, data) {
          state.topThree = data;
        }
    },
};
