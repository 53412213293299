<script>
export default {
    data() {
        return {
            showPassword: false,
            showConfirmPassword: false,
            password: '',
            confirmPassword: '',
            email: null,
            sponsor: null,
            pending: false,
        };
    },
    computed: {
        warningText() {
            let myString = '';
            const lowerCaseLetters = /[a-z]/g;
            const upperCaseLetters = /[A-Z]/g;
            const numbers = /[0-9]/g;
            const special = /[!@#$%^&*]/g;
            let firstTime = true;
            if (this.password.length < 8) {
                myString += 'Password must be at least 8 characters long. ';
            }
            if (!this.password.match(lowerCaseLetters)) {
                if (firstTime) {
                    myString += 'Must contain ';
                    firstTime = false;
                    myString += '1 lowercase letter';
                } else {
                    myString += ', 1 lowercase letter';
                }
            }
            if (!this.password.match(upperCaseLetters)) {
                if (firstTime) {
                    myString += 'Must contain ';
                    firstTime = false;
                    myString += '1 uppercase letter';
                } else {
                    myString += ', 1 uppercase letter';
                }
            }
            if (!this.password.match(numbers)) {
                if (firstTime) {
                    myString += 'Must contain ';
                    firstTime = false;
                    myString += 'a number';
                } else {
                    myString += ', a number';
                }
            }
            if (!this.password.match(special)) {
                if (firstTime) {
                    myString += 'Must contain ';
                    firstTime = false;
                    myString += 'a special character';
                } else {
                    myString += ', a special character';
                }
            }
            return myString;
        },
    },

    methods: {
        toggleShow() {
            this.showPassword = !this.showPassword;
        },
        toggleConfirm() {
            this.showConfirmPassword = !this.showConfirmPassword;
        },

        registerEmail() {
            if (this.pending) {
                return;
            }
            this.pending = true;

            if (this.password === this.confirmPassword) {
                this.$store
                    .dispatch('auth/RegisterEmail', {
                        email: this.email,
                        password: this.password,
                        sponsor: this.sponsor ? this.sponsor : undefined,
                    })
                    .then((res) => {
                        if (res.status === true) {
                            this.$router.push({
                                name: 'Active-mail',
                                query: { email: this.email },
                            });
                        }
                        else {
                            this.$router.push({
                                name: 'Register'
                            });

                            this.pending = false;
                        }
                    })
                    .finally(() => {
                        this.pending = false;
                    });
            } else {
                this.$toastr.e('Confirm your password again', 'Oops!');
                this.pending = false;
            }

            console.log(this.pending);
        },
    },
    mounted() {
        if (this.$route.query.sponsor) {
            this.sponsor = this.$route.query.sponsor;
        }
    },
};
</script>

<template>
    <div class="register-container">
        <form @submit.prevent="registerEmail">
            <div class="img">
                <img src="../../../assets/images/logo/logoV3.png" alt="" />
            </div>
            <div class="inputs">
                <input type="email" :placeholder="$t('email')" v-model="email" autocomplete="email" id="email"
                    required />
                <div class="input-pw">
                    <input :type="showPassword ? 'text' : 'password'" :placeholder="$t('password')" v-model="password"
                        id="password" name="password" required autocomplete="new-password"
                        pattern="(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}" />
                    <img v-if="!showPassword" @click="toggleShow" class="eye-open"
                        src="../../../assets/images/icons/ph--eye-fill.svg" alt="" />
                    <img v-else @click="toggleShow" class="eye-close"
                        src="../../../assets/images/icons/ph--eye-slash-fill.svg" alt="" />
                </div>
                <div v-if="warningText.length > 0" class="note">
                    {{ warningText }}
                </div>
                <div class="input-confirm">
                    <input class="confirm-password" :type="showConfirmPassword ? 'text' : 'password'"
                        :placeholder="$t('confirmPassword')" v-model="confirmPassword" id="confirmPassword"
                        name="confirmPassword" required autocomplete="new-password" />
                    <img v-if="!showConfirmPassword" @click="toggleConfirm" class="eye-open"
                        src="../../../assets/images/icons/ph--eye-fill.svg" alt="" />
                    <img v-else @click="toggleConfirm" class="eye-close"
                        src="../../../assets/images/icons/ph--eye-slash-fill.svg" alt="" />
                </div>
                <input type="text" :placeholder="$t('sponsor')" v-model="sponsor" id="sponsor" />
            </div>
            <div class="buttons">
                <button :class="{ pending: pending }" :disabled="pending">
                    {{ $t('register') }}
                </button>
            </div>
            <p>
                {{ $t('alreadyHaveAccount') }}
                <router-link class="router-link" to="/"><strong>{{ $t('login') }}</strong></router-link>
            </p>
        </form>
    </div>
</template>
<style lang="scss" scoped>
.register-container {
    background: linear-gradient(to right,#5BBE67, #2A5830);
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    form {
        flex-basis: 400px;
    }

    .img {
        display: flex;
        justify-content: center;

        >img {
            max-width: 100%;
            width: 200px;
        }
    }

    .inputs {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin: 0 auto;
        margin-top: 1.5rem;

        .input-pw,
        .input-confirm {
            position: relative;

            input {
                width: 100%;
            }

            img {
                position: absolute;
                top: 50%;
                right: 1rem;
                transform: translateY(-50%);
                width: 24px;
                cursor: pointer;
            }
        }

        input {
            padding: 0 1rem;
            border: 1px solid #A6ED8D;
            border-radius: 8px;
            height: 40px;
            background-color: #2C562D;
            color: #fff;
        }

        input:focus-visible {
            outline: none;
        }

        input::placeholder {
            color: #fff;
            font-size: 18px;
            font-style: italic;
            font-weight: 400;
        }
    }

    .buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 1.5rem;
        gap: 1rem;

        button {
            padding-top: 1rem;
            padding-bottom: 1rem;
            width: 70%;
            border-radius: 72px;
            text-transform: uppercase;
            transition: 0.5s;

            &:hover {
                opacity: 0.5;
            }

            &.pending {
                filter: grayscale(1);
            }
        }

        button:nth-child(1) {
            background: linear-gradient(to left, #8BF04D, #2E6D08);
            color: #fff;
            font-size: 18px;
            font-weight: 600;
        }

        button:nth-child(2) {
            border: 1px solid #AAFF95;
            color: #AAFF95;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 7px;

            font-size: 18px;
            font-weight: 600;
        }
    }

    .note {
        color: #ffc107;
        font-size: 14px;
        padding: 0px 5px;
    }

    p {
        margin-top: 1rem;
        text-align: center;
        font-size: 18px;
        color: #fff;

        .router-link {
            transition: 0.5s;

            &:hover {
                color: #fff;
                opacity: 0.5;
            }
        }
    }

    @media (max-width: 1201px) {
        form {
            .inputs {
                width: 100%;
            }
        }
    }

    @media (max-width: 990px) {
        padding: 20px;

        form {
            padding-bottom: 2rem;
            overflow-y: hidden;
            .inputs {
                margin-top: 0;
                input::placeholder {
                    font-size: 16px;
                }

                .input-pw,
                .input-confirm {
                    img {
                        width: 25px;
                    }
                }
            }

            .buttons {
                button {
                    width: 100%;
                    font-size: 16px;
                }
            }

            p {
                font-size: 16px;
            }
        }
    }

    @media (max-width: 376px) {
        padding: 5rem 1rem 3rem 1rem;
    }
}
</style>
