<template>
  <div class="statistic">
    <div class="statistic__content">
      <div class="statistic__qr">
        <img :src="InfoTree.qr" alt="QR Code" />
      </div>
    </div>

    <div class="statistic__body">
      <div class="statistic__item">
        <p class="heading__secondary">
          {{ $t('dashboardPage.sponsor') }}
        </p>
        <p class="text-green">
          {{
            InfoTree.sponsor
          }}

          <button v-clipboard:copy="sponsorUser" v-clipboard:success="onCopySponsor">
            <img src="@/assets/images/icons/copy.svg" alt="Copy" width="12" />
          </button>
        </p>
      </div>

      <div class="statistic__item">
        <p class="heading__secondary">
          {{
            $t('dashboardPage.totalMember')
          }}
        </p>
        <p class="text-green">
          {{
            TruncateToDecimals2(InfoTree.totalMember)
          }}
        </p>
      </div>

      <div class="statistic__item">
        <p class="heading__secondary">
          {{ $t('dashboardPage.totalF1') }}
        </p>
        <p class="text-green">
          {{
            TruncateToDecimals2(InfoTree.totalF1)
          }}
        </p>
      </div>

      <div class="statistic__item">
        <p class="heading__secondary">
          {{ $t('dashboardPage.totalF1Sales') }}:
        </p>
        <p class="text-green">
          {{
            TruncateToDecimals2(InfoTree.totalF1Sales)
          }}
          USDT
        </p>
      </div>

      <div class="statistic__item">
        <p class="heading__secondary">
          {{ $t('dashboardPage.totalGroupSales') }}:
        </p>
        <p class="text-green">
          {{
            TruncateToDecimals2(InfoTree.totalGroupSales)
          }}
          USDT
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      usdtValue: 0,
    };
  },
  computed: {
    ...mapGetters({
      UserInfo: 'info/UserInfo',
      Member: 'member/Member',
      Statistic: 'member/Statistic',
      InfoTree: 'presale/InfoTree',
    }),
    linkUrl() {
      return `https://api.qrserver.com/v1/create-qr-code/?size=500x500&data=${window.location.origin}/register?sponsor=${this.UserInfo.refId}`
    },

    sponsorUser() {
      if (this.InfoTree.sponsor) {
        return this.InfoTree.sponsor.toString();
      }

      return 'N/A';

      // return this.InfoTree.sponsor.toString() || 'no data';
    },
    url() {
      return `${window.location.origin}/register?sponsor=${this.UserInfo.refId}`;
    },
  },
  methods: {
    onCopySponsor() {
      this.$toastr.s('Copy Sponsor Success', 'Successfully');
    },
  },
  watch: {
    UserInfo: {
      handler() {
        this.$store.dispatch('member/req_getDownline');
        this.$store.dispatch('member/req_getStatisticalMonth');
        this.$store.dispatch('member/req_getStatisticalWeek');
        this.$store.dispatch('member/req_topThreeTree');
      },
    },
  },
  mounted() {
    this.$store.dispatch('presale/req_getInfoTree');
  },
};
</script>

<style scoped lang="scss">
.statistic {
  background: linear-gradient(#2c572d, #2c572d) padding-box,
    linear-gradient(to left, #A6ED8D, #078C04) border-box;
  border-radius: 8px;
  border: 2px solid transparent;
  color: white;

  &__qr {
    background-color: #06330c;
    padding: 24px;
    border-radius: 8px;
    border: 1px solid #cacaca;
    margin: 16px 0;
    box-shadow: rgba(22, 158, 10, 1) 0px 5px 15px;

    img {
      width: 100px;
      height: 100px;
    }

  }

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__body {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  &__item {
    display: flex;
    justify-content: space-between;

    p {
      margin-bottom: 0;
    }

    button {
      background: none;
      border: none;
      cursor: pointer;
      outline: none;
    }
  }
}

.text-green {
  color: #AAFF95;
}

@media screen and (max-width: 1024px) {
  .statistic {
    &__body {
      max-width: 324px;
      margin: 0 auto;
    }
  }
}
</style>
