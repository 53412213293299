<template>
  <div class="header">
    <div class="container">
      <div class="account">
        <div class="account-balance">
          <div class="wallet-wrap">
            <div class="window special" @click="dropdown = !dropdown">
              <div class="d-none d-lg-block" v-b-tooltip.hover :title="TruncateToDecimals2(
                UserInfo.ECH + UserInfo.bECH,
                '',
                2,
              )
                ">
                <span v-if="Balance">
                  {{
                    TruncateToDecimals2(
                      UserInfo.ECH + UserInfo.bECH,
                      '',
                      2,
                    )
                  }}
                </span>
              </div>
              <div class="d-lg-none">
                <span v-if="Balance">
                  {{
                    TruncateToDecimals2(
                      UserInfo.ECH + UserInfo.bECH,
                      '',
                      2,
                    )
                  }}
                </span>
              </div>
              <img src="@/assets/images/icons/coin_ECH.png" />
            </div>
            <div class="wallet-dropdown" :class="{ active: dropdown }">
              <div>
                <div>
                  <span v-if="Balance">
                    {{
                      TruncateToDecimals2(
                        UserInfo.ECH + UserInfo.bECH,
                        '',
                        2,
                      )
                    }}
                  </span>
                </div>
                <img src="@/assets/images/logo/ech.png" />
              </div>
              <div>
                <div>
                  <span v-if="Balance">
                    {{
                      TruncateToDecimals2(
                        UserInfo.USDT + UserInfo.bUSDT,
                        '',
                        2,
                      )
                    }}
                  </span>
                </div>
                <img src="@/assets/images/logo/usdt.png" />
              </div>
            </div>
          </div>
          <b-button class="button-deposit-bg" @click="goToWallet">
            {{ $t('deposit') }}
          </b-button>

          <button class="button-header select-lang" id="language-selector" @click="$bvModal.show('language-modal')">
            <img :src="icons[$i18n.locale]" />
            {{ $t('language') }}
          </button>

          <div class="information">
            <p>
              <span>
                {{ UserInfo.email }}
              </span>
              <span>({{ UserInfo.refId }})</span>
            </p>
          </div>

          <button @click="$router.push({ name: 'Account' })" class="avatar">
            <img src="@/assets/images/mockup/avatar.png" />
          </button>
        </div>
      </div>
      <div class="mobile">
        <div class="information-mobile">
          <p>
            <span>
              {{ UserInfo.email }}
            </span>
            <span>({{ UserInfo.refId }})</span>
          </p>
        </div>
        <button class="button-header select-lang" id="language-selector" @click="$bvModal.show('language-modal')">
          <img :src="icons[$i18n.locale]" />
        </button>
        <div class="account-mobile" v-if="accountMobile">
          <div class="account-balance">
            <div class="wallet-wrap">
              <div class="window special" @click="dropdown = !dropdown">
                <div class="d-none d-lg-block" v-b-tooltip.hover :title="TruncateToDecimals2(
                  UserInfo.ECH + UserInfo.bECH,
                  '',
                  2,
                )
                  ">
                  <span v-if="Balance">
                    {{
                      TruncateToDecimals2(
                        UserInfo.ECH + UserInfo.bECH,
                        '',
                        2,
                      )
                    }}
                  </span>
                </div>
                <div class="d-lg-none">
                  <span v-if="Balance">
                    {{
                      TruncateToDecimals2(
                        UserInfo.ECH + UserInfo.bECH,
                        '',
                        2,
                      )
                    }}
                  </span>
                </div>
                <img @click="redirectWallet" src="@/assets/images/logo/ech.png" />
              </div>
              <div class="wallet-dropdown" :class="{ active: dropdown }">
                <div>
                  <div>
                    <span v-if="Balance">
                      {{
                        TruncateToDecimals2(
                          UserInfo.ECH +
                          UserInfo.bECH,
                          '',
                          2,
                        )
                      }}
                    </span>
                  </div>
                  <img src="@/assets/images/logo/ech.png" />
                </div>
                <div>
                  <div>
                    <span v-if="Balance">
                      {{
                        TruncateToDecimals2(
                          UserInfo.USDT +
                          UserInfo.bUSDT,
                          '',
                          2,
                        )
                      }}
                    </span>
                  </div>
                  <img src="@/assets/images/logo/usdt.png" />
                </div>
              </div>
            </div>
          </div>
          <div class="account-balance">
            <div class="wallet-wrap">
              <div class="window special" @click="dropdown = !dropdown">
                <div class="d-none d-lg-block" v-b-tooltip.hover :title="TruncateToDecimals2(
                  UserInfo.ECH + UserInfo.bECH,
                  '',
                  2,
                )
                  ">
                  <span v-if="Balance">
                    {{
                      TruncateToDecimals2(
                        UserInfo.ECH + UserInfo.bECH,
                        '',
                        2,
                      )
                    }}
                  </span>
                </div>
                <div class="d-lg-none">
                  <span v-if="Balance">
                    {{
                      TruncateToDecimals2(
                        UserInfo.USDT + UserInfo.bUSDT,
                        '',
                        2,
                      )
                    }}
                  </span>
                </div>
                <img @click="redirectWallet" src="@/assets/images/logo/usdt.png" />
              </div>
              <div class="wallet-dropdown" :class="{ active: dropdown }">
                <div>
                  <div>
                    <span v-if="Balance">
                      {{
                        TruncateToDecimals2(
                          UserInfo.ECH +
                          UserInfo.bECH,
                          '',
                          2,
                        )
                      }}
                    </span>
                  </div>
                  <img src="@/assets/images/logo/ech.png" />
                </div>
                <div>
                  <div>
                    <span v-if="Balance">
                      {{
                        TruncateToDecimals2(
                          UserInfo.USDT +
                          UserInfo.bUSDT,
                          '',
                          2,
                        )
                      }}
                    </span>
                  </div>
                  <img src="@/assets/images/logo/usdt.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <button @click="$router.push({ name: 'Account' })" class="avatar-mobile">
          <img src="@/assets/images/mockup/avatar.png" />
        </button>
        <button class="mobile-show" @click="accountMobile = !accountMobile">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <g fill="none" stroke="currentColor" stroke-width="1.5">
              <circle cx="5" cy="12" r="2" />
              <circle cx="12" cy="12" r="2" />
              <circle cx="19" cy="12" r="2" />
            </g>
          </svg>
        </button>
      </div>
      <b-modal id="mana-limit-confirm" centered hide-footer hide-header>
        <svg @click="$bvModal.hide('mana-limit-confirm')" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
          viewBox="0 0 24 24">
          <path fill="currentColor"
            d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12z" />
        </svg>
        <h2>Buy Mana Limit</h2>
        <h3>Are you sure you want to increase Mana Limit ?</h3>
        <p>
          Currently the limit is
          <strong>{{ UserInfo.limitMana }}</strong>, do you want to increase Mana Limit for
          <strong>10 USDT</strong> ?
        </p>
        <div>
          <span @click="buyLimitMana">Confirm</span>
          <span @click="$bvModal.hide('mana-limit-confirm')">Cancel</span>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import ru from '@/assets/images/icons/lang/ru.png';
import ko from '@/assets/images/icons/lang/ko.png';
import ja from '@/assets/images/icons/lang/ja.png';
import en from '@/assets/images/icons/lang/en.png';
import vi from '@/assets/images/icons/lang/vi.png';
import zh from '@/assets/images/icons/lang/zh.png';

export default {
  name: 'MyHeader',
  data() {
    return {
      dropdown: false,
      accountMobile: false,
      selectedLanguage: 'English', // Giá trị mặc định
      languages: ['English', 'Vietnamese', 'Spanish', 'French', 'German'],
      icons: {
        ru,
        ko,
        ja,
        en,
        zh,
        vi,
      },
    };
  },
  computed: {
    ...mapGetters({
      isLogin: 'auth/loggedIn',
      Balance: 'wallet/Balance',
      UserInfo: 'info/UserInfo',
      XNode: 'info/XNode',
      AvailableLangs: 'core/AvailableLangs',
    }),

    progressBarPercentage() {
      const percentage =
        (this.UserInfo.mana / this.UserInfo.limitMana) * 100;
      return `${percentage}%`;
    },
  },
  methods: {
    handleClickOutside(e) {
      const pop = document.querySelector('.window.special');
      const but = document.querySelector('.wallet-dropdown');
      if (
        pop &&
        but &&
        !(pop.contains(e.target) || but.contains(e.target))
      ) {
        this.dropdown = false;
      }
    },
    selectLanguage(language) {
      this.selectedLanguage = language;
    },
    redirectWallet() {
      this.$router.push({ name: 'Wallet' });
    },
    buyLimitMana() {
      this.$store
        .dispatch('info/buyMana', { currency: 'USDT' })
        .then(() => {
          this.$store.dispatch('info/req_getInfo');
          this.$bvModal.hide('mana-limit-confirm');
        });
    },
    goToWallet() {
      this.$router.push('/wallet');
    },
    setLocale() {
      window.$cookies.set('lang', this.$root.$i18n.locale);
    },
  },
  mounted() {
    window.addEventListener('click', this.handleClickOutside);
  },
  unmounted() {
    window.removeEventListener('click', this.handleClickOutside);
  },
};
</script>

<style lang="scss" scoped>
.header {
  box-shadow: 0px 6px 6px -6px #00000038;
  position: sticky;
  top: 0px;
  z-index: 2;
  background-color: #134b04;
  position: relative;

  .information {
    color: #fff;
    font-size: 14px;
    display: flex;
    align-content: center;
    gap: 4px;

    p {
      margin-bottom: 0;

      display: flex;
      gap: 8px;
    }
  }

  .information-mobile {
    color: #fff;
    font-size: 12px;
    display: flex;
    align-content: center;

    p {
      margin-bottom: 0;

      display: flex;
      flex-wrap: wrap;
      gap: 4px;
      align-items: center;
    }
  }

  >.container {
    display: flex;
    justify-content: end;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;

    @media (max-width: 768px) {
      max-width: 100%;
    }
  }

  .nod {
    line-height: 1;
    color: #fff;

    .salute {
      font-weight: 700;
      font-size: 14px;

      @media (min-width: 768px) {
        font-size: 16px;
      }
    }

    .page {
      font-size: 12px;
      margin-top: 5px;
    }
  }

  .account {
    display: flex;
    align-items: center;
    gap: 45px;

    .button-deposit-bg {
      background: linear-gradient(to right, #8bf04d, #2e6d08);
      border: none;
      box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.25);
    }

    @media (max-width: 768px) {
      display: none;
    }
  }

  .select-lang {
    padding: 0.375rem;
    color: white;

    >img {
      width: 26px;
      height: 26px;
    }
  }

  .account-balance {
    display: flex;
    align-items: center;
    gap: 15px;
    color: #fff;

    .wallet-wrap {
      position: relative;
      font-family: 700;
      font-size: 16px;
      color: #fafafa;

      img {
        width: 24px;
      }

      .wallet-dropdown {
        position: absolute;
        top: calc(100% + 10px);
        left: 0;
        width: 100%;
        background-color: #c4e3f5;
        border-radius: 12px;
        box-shadow: 1px 1px 4px 1px #00000038;

        >div {
          padding: 4px 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 5px;

          >div {
            min-width: 0;

            >span {
              word-wrap: break-word;
            }
          }

          >img {
            flex-shrink: 0;
          }
        }

        font-size: 14px;

        @media (min-width: 768px) {
          font-size: 16px;
        }

        opacity: 0;
        transition: 0.2s;

        &.active {
          opacity: 1;
        }

        color: #000;
      }
    }

    .button-header {
      border: none;
      border-radius: 6px 6px 6px 6px;
      background: #26562ca6;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }

    .window {
      background: #2c562d;
      border-radius: 12px;
      border: 0.5px solid #fff;
      padding: 4px 10px;
      min-width: 100px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 5px;
      box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.25);

      position: relative;
      cursor: pointer;
      color: #fff;

      @media (min-width: 576px) {
        font-size: 20px;
      }

      >div {
        max-width: 7.5rem;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    .avatar {
      width: 40px;
      aspect-ratio: 1;
      border-radius: 8px;
      border: 1px solid #c7ffac;
      padding: 2px;
      flex-shrink: 0;

      >img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .mobile {
    display: none;
    gap: 15px;

    .button-deposit-bg {
      background: linear-gradient(to right, #8bf04d, #2e6d08);
      border: none;
    }

    .avatar-mobile {
      display: none;
      width: 40px;
      border-radius: 8px;
      border: 2px solid #fff;
      padding: 2px;
      flex-shrink: 0;

      >img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      @media (max-width: 768px) {
        display: block;
      }
    }

    .account-mobile {
      position: absolute;
      right: 0;
      top: 70px;
      display: flex;
      flex-direction: column;
      gap: 15px;
      background: #134b04;
      padding: 10px;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1019607843);
      border-radius: 12px;
      max-width: 220px;
      width: 100%;

      img {
        width: 24px;
      }

      .mana {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #fff;

        >div {
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-radius: 12px;
          border: 1px solid #d8d8d8;
          padding: 4px 10px;
          background: rgba(52, 160, 221, 0.2901960784);
          min-width: 160px;

          svg {
            cursor: pointer;
            font-size: 16px;
          }

          span {
            font-size: 16px;
          }
        }

        @media (max-width: 575px) {
          >div {
            min-width: 150px;
          }
        }
      }

      .account-balance {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        font-size: 16px;
        color: #fafafa;

        span {
          color: #fff;
        }

        .wallet-dropdown {
          position: absolute;
          top: calc(100% + 10px);
          left: 0;
          width: 100%;
          background-color: #c4e3f5;
          border-radius: 12px;
          box-shadow: 1px 1px 4px 1px #00000038;

          >div {
            padding: 4px 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 5px;

            >div {
              min-width: 0;

              >span {
                word-wrap: break-word;
              }
            }

            >img {
              flex-shrink: 0;
            }
          }

          font-size: 14px;

          @media (min-width: 768px) {
            font-size: 16px;
          }

          opacity: 0;
          transition: 0.2s;

          &.active {
            opacity: 1;
          }

          color: #000;
        }

        .window {
          background: #34a0dd4a;
          border-radius: 12px;
          padding: 4px 10px;
          min-width: 150px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 5px;
          box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.25);

          position: relative;
          cursor: pointer;
          color: #000;

          @media (min-width: 576px) {
            font-size: 20px;
            min-width: 160px;
          }

          >div {
            max-width: 7.5rem;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }

        .avatar {
          width: 40px;
          aspect-ratio: 1;
          border-radius: 8px;
          border: 2px solid #fff;
          padding: 2px;
          flex-shrink: 0;

          >img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }

    .mobile-show {
      display: none;

      @media (max-width: 768px) {
        display: block;
        color: #fff;

        svg {
          width: 30px;
        }
      }
    }

    @media (max-width: 768px) {
      display: flex;
    }
  }
}

.progress-bar {
  width: 100%;
  background-color: #033865;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: rgba(61, 217, 232, 0.35) 0px 5px 15px;

  .progress {
    width: 0%;
    height: 20px;
    background: linear-gradient(#0effff, #124de3);
    transition: width 0.3s ease;
  }
}
</style>

<style lang="scss">
#mana-limit-confirm {
  @media (min-width: 320px) {
    .modal-content {
      position: relative;
      width: 100%;
      margin: 0 auto;

      .modal-body {
        border-radius: 12px;

        h2 {
          color: #0087cc;
          text-align: center;
          font-size: 30px;
          margin-bottom: 15px;
        }

        h3 {
          text-align: center;
          font-size: 20px;
          font-weight: 600;
        }

        p {
          font-family: Poppins;
          font-size: 16px;
          font-weight: 500;
          text-align: center;
        }

        svg {
          position: absolute;
          font-size: 30px;
          top: 10px;
          right: 10px;
          cursor: pointer;
        }

        >div {
          display: flex;
          align-content: center;
          justify-content: space-around;
          gap: 15px;
          margin-top: 1rem;

          span {
            cursor: pointer;
            max-width: 200px;
            width: 100%;
            color: white;
            border-radius: 72px;
            padding: 4px 10px;
            font-size: 18px;
            text-transform: uppercase;
            font-family: Poppins;
            font-weight: 600;
            text-align: center;
            transition: 0.2s;

            &:nth-child(1) {
              background: linear-gradient(to right,
                  #1775b8,
                  #91c4e9);
            }

            &:nth-child(2) {
              color: #0087cc;
              border: 1px solid #0087cc;
            }

            &:hover {
              opacity: 0.8;
            }
          }
        }
      }
    }
  }
}

.btn>.dropdown-toggle>.btn-secondary {
  background-color: none;
}

.header .account-balance .wallet-wrap .wallet-dropdown>div>div>span {
  word-wrap: normal;
}

.header .account-balance .wallet-wrap .wallet-dropdown.active {
  opacity: 1;
  width: fit-content;
  min-width: 100px;
}
</style>
