<script>
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            search: {
                page: 1,
                limit: 10,
                type: 'withdraw',
                fromDate: null,
                toDate: null,
                sortBy: null,
                query: '',
            },
            myFields: {
                commission: [
                    {
                        class: 'text-center',
                        label: 'Amount',
                        sortable: true,
                        key: 'amount',
                    },
                    {
                        class: 'text-center',
                        label: 'Currency',
                        sortable: true,
                        key: 'currency',
                    },
                    {
                        class: 'text-center',
                        label: 'Comment',
                        sortable: true,
                        key: 'comment',
                    },
                    {
                        class: 'text-center',
                        label: 'Address',
                        sortable: true,
                        key: 'address',
                    },
                    {
                        class: 'text-center',
                        label: 'From',
                        sortable: true,
                        key: 'from',
                    },
                    {
                        class: 'text-center',
                        label: 'To',
                        sortable: true,
                        key: 'userId',
                    },
                    {
                        class: 'text-center',
                        label: 'Time',
                        sortable: true,
                        key: 'createdAt',
                    },
                    {
                        class: 'text-center',
                        label: 'Status',
                        sortable: true,
                        key: 'status',
                    },
                ],
                withdraw: [
                    {
                        class: 'text-center',
                        label: 'Address',
                        sortable: true,
                        key: 'address',
                    },
                    {
                        class: 'text-center',
                        label: 'Amount',
                        sortable: true,
                        key: 'amount',
                    },
                    {
                        class: 'text-center',
                        label: 'Fee',
                        sortable: true,
                        key: 'fee',
                    },
                    {
                        class: 'text-center',
                        label: 'Currency',
                        sortable: true,
                        key: 'currency',
                    },
                    {
                        class: 'text-center',
                        label: 'Comment',
                        sortable: true,
                        key: 'comment',
                    },
                    {
                        class: 'text-center',
                        label: 'Transaction',
                        sortable: true,
                        key: 'transaction',
                    },
                    {
                        class: 'text-center',
                        label: 'Time',
                        sortable: true,
                        key: 'createdAt',
                    },
                    {
                        class: 'text-center',
                        label: 'Status',
                        sortable: true,
                        key: 'status',
                    },
                ],
                deposit: [
                    {
                        class: 'text-center',
                        label: 'Amount',
                        sortable: true,
                        key: 'amount',
                    },
                    {
                        class: 'text-center',
                        label: 'Fee',
                        sortable: true,
                        key: 'fee',
                    },
                    {
                        class: 'text-center',
                        label: 'Currency',
                        sortable: true,
                        key: 'currency',
                    },
                    {
                        class: 'text-center',
                        label: 'Comment',
                        sortable: true,
                        key: 'comment',
                    },
                    {
                        class: 'text-center',
                        label: 'Transaction',
                        sortable: true,
                        key: 'transaction',
                    },
                    {
                        class: 'text-center',
                        label: 'Time',
                        sortable: true,
                        key: 'createdAt',
                    },
                    {
                        class: 'text-center',
                        label: 'Status',
                        sortable: true,
                        key: 'status',
                    },
                ],
                swap: [
                    {
                        class: 'text-center',
                        label: 'From',
                        sortable: true,
                        key: 'from',
                    },
                    {
                        class: 'text-center',
                        label: 'To',
                        sortable: true,
                        key: 'to',
                    },
                    {
                        class: 'text-center',
                        label: 'Amount from',
                        sortable: true,
                        key: 'amount_from',
                    },
                    {
                        class: 'text-center',
                        label: 'Amount to',
                        sortable: true,
                        key: 'amount_to',
                    },
                    {
                        class: 'text-center',
                        label: 'Fee',
                        sortable: true,
                        key: 'fee',
                    },
                    {
                        class: 'text-center',
                        label: 'Rate',
                        sortable: true,
                        key: 'rate',
                    },
                    {
                        class: 'text-center',
                        label: 'Time',
                        sortable: true,
                        key: 'createdAt',
                    },
                    {
                        class: 'text-center',
                        label: 'Status',
                        sortable: true,
                        key: 'status',
                    },
                ],
                transfer: [
                    {
                        class: 'text-center',
                        label: 'Recieved',
                        sortable: true,
                        key: 'recevied',
                    },
                    {
                        class: 'text-center',
                        label: 'Amount',
                        sortable: true,
                        key: 'amount',
                    },
                    {
                        class: 'text-center',
                        label: 'Fee',
                        sortable: true,
                        key: 'fee',
                    },
                    {
                        class: 'text-center',
                        label: 'Currency',
                        sortable: true,
                        key: 'currency',
                    },
                    {
                        class: 'text-center',
                        label: 'Time',
                        sortable: true,
                        key: 'createdAt',
                    },
                    {
                        class: 'text-center',
                        label: 'Status',
                        sortable: true,
                        key: 'status',
                    },
                ],
                mint: [
                    {
                        class: 'text-center',
                        label: 'Amount',
                        sortable: true,
                        key: 'amount',
                    },
                    {
                        class: 'text-center',
                        label: 'Claimed',
                        sortable: true,
                        key: 'claimed',
                    },
                    {
                        class: 'text-center',
                        label: 'Currency',
                        sortable: true,
                        key: 'currency',
                    },
                    {
                        class: 'text-center',
                        label: 'Comment',
                        sortable: true,
                        key: 'comment',
                    },
                    {
                        class: 'text-center',
                        label: 'Time',
                        sortable: true,
                        key: 'createdAt',
                    },
                    {
                        class: 'text-center',
                        label: 'Status',
                        sortable: true,
                        key: 'status',
                    },
                ],
                receive: [
                    {
                        class: 'text-center',
                        label: 'From',
                        sortable: true,
                        key: 'from',
                    },
                    {
                        class: 'text-center',
                        label: 'Amount',
                        sortable: true,
                        key: 'amount',
                    },
                    {
                        class: 'text-center',
                        label: 'Currency',
                        sortable: true,
                        key: 'currency',
                    },
                    {
                        class: 'text-center',
                        label: 'Comment',
                        sortable: true,
                        key: 'comment',
                    },
                    {
                        class: 'text-center',
                        label: 'Time',
                        sortable: true,
                        key: 'createdAt',
                    },
                    {
                        class: 'text-center',
                        label: 'Status',
                        sortable: true,
                        key: 'status',
                    },
                ],
                historynode: [
                    {
                        label: 'type',
                        sortable: true,
                        key: 'type',
                    },
                    {
                        class: 'text-center',
                        label: 'Amount',
                        sortable: true,
                        key: 'price',
                    },
                    {
                        class: 'text-center',
                        label: 'token',
                        sortable: true,
                        key: 'token',
                    },
                    {
                        class: 'text-center',
                        label: 'packageName',
                        sortable: true,
                        key: 'packageName',
                    },
                    {
                        class: 'text-center',
                        label: 'boughtDate',
                        sortable: true,
                        key: 'buy_date',
                    },
                    {
                        class: 'text-center',
                        label: 'Status',
                        sortable: true,
                        key: 'status',
                    },
                ],
                claim: [
                    {
                        class: 'text-center',
                        label: 'Amount',
                        sortable: true,
                        key: 'amount',
                    },
                    {
                        class: 'text-center',
                        label: 'Claimed',
                        sortable: true,
                        key: 'claimed',
                    },
                    {
                        class: 'text-center',
                        label: 'Currency',
                        sortable: true,
                        key: 'currency',
                    },
                    {
                        class: 'text-center',
                        label: 'Comment',
                        sortable: true,
                        key: 'comment',
                    },
                    {
                        class: 'text-center',
                        label: 'Time',
                        sortable: true,
                        key: 'createdAt',
                    },
                    {
                        class: 'text-center',
                        label: 'Status',
                        sortable: true,
                        key: 'status',
                    },
                ],
            },
            myMobileFields: {
                commission: [
                    {
                        class: 'text-center',
                        label: 'Amount',
                        sortable: true,
                        key: 'amount',
                    },
                    {
                        class: 'text-center',
                        label: 'Currency',
                        sortable: true,
                        key: 'currency',
                    },
                    {
                        class: 'text-center',
                        label: 'From',
                        sortable: true,
                        key: 'from',
                    },
                    {
                        class: 'text-center',
                        label: 'Status',
                        sortable: true,
                        key: 'status',
                    },
                    {
                        key: '#',
                        label: 'More',
                        class: 'text-center',
                    },
                ],
                withdraw: [
                    {
                        class: 'text-center',
                        label: 'Amount',
                        sortable: true,
                        key: 'amount',
                    },
                    {
                        class: 'text-center',
                        label: 'Currency',
                        sortable: true,
                        key: 'currency',
                    },
                    {
                        class: 'text-center',
                        label: 'Status',
                        sortable: true,
                        key: 'status',
                    },
                    {
                        key: '#',
                        label: 'More',
                        class: 'text-center',
                    },
                ],
                deposit: [
                    {
                        class: 'text-center',
                        label: 'Amount',
                        sortable: true,
                        key: 'amount',
                    },
                    {
                        class: 'text-center',
                        label: 'Currency',
                        sortable: true,
                        key: 'currency',
                    },
                    {
                        class: 'text-center',
                        label: 'Status',
                        sortable: true,
                        key: 'status',
                    },
                    {
                        key: '#',
                        label: 'More',
                        class: 'text-center',
                    },
                ],
                swap: [
                    {
                        class: 'text-center',
                        label: 'From',
                        sortable: true,
                        key: 'from',
                    },
                    {
                        class: 'text-center',
                        label: 'To',
                        sortable: true,
                        key: 'to',
                    },
                    {
                        class: 'text-center',
                        label: 'Amount from',
                        sortable: true,
                        key: 'amount_from',
                    },
                    {
                        class: 'text-center',
                        label: 'Amount to',
                        sortable: true,
                        key: 'amount_to',
                    },
                    {
                        class: 'text-center',
                        label: 'Status',
                        sortable: true,
                        key: 'status',
                    },
                    {
                        key: '#',
                        label: 'More',
                        class: 'text-center',
                    },
                ],
                transfer: [
                    {
                        class: 'text-center',
                        label: 'Recieved',
                        sortable: true,
                        key: 'recevied',
                    },
                    {
                        class: 'text-center',
                        label: 'Reciever',
                        sortable: true,
                        key: 'from',
                    },
                    {
                        class: 'text-center',
                        label: 'Amount',
                        sortable: true,
                        key: 'amount',
                    },
                    {
                        class: 'text-center',
                        label: 'Currency',
                        sortable: true,
                        key: 'currency',
                    },
                    {
                        class: 'text-center',
                        label: 'Status',
                        sortable: true,
                        key: 'status',
                    },
                    {
                        key: '#',
                        label: 'More',
                        class: 'text-center',
                    },
                ],
                mint: [
                    {
                        class: 'text-center',
                        label: 'Amount',
                        sortable: true,
                        key: 'amount',
                    },
                    {
                        class: 'text-center',
                        label: 'Claimed',
                        sortable: true,
                        key: 'claimed',
                    },
                    {
                        class: 'text-center',
                        label: 'Currency',
                        sortable: true,
                        key: 'currency',
                    },
                    {
                        class: 'text-center',
                        label: 'Status',
                        sortable: true,
                        key: 'status',
                    },
                    {
                        key: '#',
                        label: 'More',
                        class: 'text-center',
                    },
                ],
                receive: [
                    {
                        class: 'text-center',
                        label: 'From',
                        sortable: true,
                        key: 'from',
                    },
                    {
                        class: 'text-center',
                        label: 'Amount',
                        sortable: true,
                        key: 'amount',
                    },
                    {
                        class: 'text-center',
                        label: 'Currency',
                        sortable: true,
                        key: 'currency',
                    },
                    {
                        class: 'text-center',
                        label: 'Status',
                        sortable: true,
                        key: 'status',
                    },
                    {
                        key: '#',
                        label: 'More',
                        class: 'text-center',
                    },
                ],
                historynode: [
                    {
                        label: 'type',
                        sortable: true,
                        key: 'type',
                    },
                    {
                        class: 'text-center',
                        label: 'Amount',
                        sortable: true,
                        key: 'price',
                    },
                    {
                        class: 'text-center',
                        label: 'token',
                        sortable: true,
                        key: 'token',
                    },
                    {
                        key: '#',
                        label: 'More',
                        class: 'text-center',
                    },
                ],
                claim: [
                    {
                        class: 'text-center',
                        label: 'Amount',
                        sortable: true,
                        key: 'amount',
                    },
                    {
                        class: 'text-center',
                        label: 'Claimed',
                        sortable: true,
                        key: 'claimed',
                    },
                    {
                        class: 'text-center',
                        label: 'Currency',
                        sortable: true,
                        key: 'currency',
                    },
                    {
                        key: '#',
                        label: 'More',
                        class: 'text-center',
                    },
                ],
            },
            today: null,
            toDateSelected: null,
            currentPage: 1,
            totalPages: 10,
            removedTypes: ['nodegift'],
        };
    },
    watch: {
        'search.type': {
            handler(newVal) {
                if (newVal) {
                    this.search.type = newVal;
                    this.$store.dispatch(
                        'history/req_getHistoryWallet',
                        this.search,
                    );
                }
            },
        },

        'search.page': {
            handler(newVal) {
                if (newVal) {
                    this.search.page = newVal;
                    this.$store.dispatch(
                        'history/req_getHistoryWallet',
                        this.search,
                    );
                }
            },
        },
    },
    computed: {
        ...mapGetters({
            History: 'history/History',
            isNetWork: 'contract/isNetWork',
            ListType: 'history/ListType',
            TotalHistory: 'history/TotalHistory',
        }),
        url() {
            if (this.isNetWork === 'testnet') {
                return 'https://testnet.bscscan.com/tx/';
            }
            return 'https://bscscan.com/tx/';
        },
        FilteredTypes() {
            const myArrays = this.ListType;
            for (let i = 0; i < this.removedTypes.length; i += 1) {
                for (let indexy = 0; indexy < myArrays.length; indexy += 1) {
                    if (myArrays[indexy].value === this.removedTypes[i]) {
                        myArrays.splice(indexy, 1);
                        break;
                    }
                }
            }
            return myArrays;
        },
    },
    methods: {
        onChangePage(payload) {
            this.search.page = payload;
            this.$store.dispatch('history/req_getHistoryWallet', this.search);
        },
        onContext(ctx) {
            // The date formatted in the locale, or the `label-no-date-selected` string
            this.formatted = ctx.selectedFormatted;
            // The following will be an empty string until a valid date is entered
            this.selected = ctx.selectedYMD;
        },
        onReset() {
            this.search = {
                page: 1,
                limit: 20,
                type: this.ListType[0].value,
                fromDate: null,
                toDate: null,
                sortBy: null,
                query: '',
            };
            this.onChangePage(1);
        },
        detectSort(ctx) {
            if (ctx.sortBy) {
                this.search.sortBy = `${ctx.sortBy}:${
                    ctx.sortDesc ? 'desc' : 'asc'
                }`;
            }
            this.$store.dispatch('history/req_getHistoryWallet', this.search);
        },

        justSearch() {
            this.$store.dispatch('history/req_getHistoryWallet', this.search);
        },
    },
    created() {
        this.$store.dispatch('history/req_getHistoryWalletType').then((res) => {
            if (res.status) {
                [this.search.type] = res.data;
            }
        });
        this.today = new Date();
    },
};
</script>
<template>
    <!-- <b-container class="activity-tab" v-if="ListType"> -->
    <b-container class="activity-tab activity mt-5" v-if="FilteredTypes">
        <div class="activity-list">
            <div class="filter-history">
                <label for="">{{ $t('activityType') }}</label>
                <div class="group-hist">
                    <select
                        id="group-hist__select"
                        :key="FilteredTypes.length"
                        class="form-control select-type"
                        v-model="search.type"
                    >
                        <option
                            :value="item.value"
                            v-for="item in FilteredTypes"
                            :key="item.value"
                        >
                            <!-- {{ item.text }} -->
                            {{ $t(`${item.text}`) }}
                        </option>
                    </select>
                    <!-- <div class="mi-input">
                        Search:
                        <form @submit.prevent="justSearch" class="wrap">
                            <input type="text" v-model="search.query" />
                            <button class="submit" type="submit">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="100%"
                                    height="100%"
                                    viewBox="0 0 24 24"
                                >
                                    <g fill="none" stroke="currentColor">
                                        <circle cx="11" cy="11" r="6" />
                                        <path
                                            stroke-linecap="round"
                                            d="m20 20l-3-3"
                                        />
                                    </g>
                                </svg>
                            </button>
                        </form>
                    </div> -->
                </div>
            </div>
            <div class="d-none d-lg-block table-responsive mt-4">
                <b-table
                    id="table-activity"
                    striped
                    hover
                    :items="History.list"
                    :fields="myFields[search.type]"
                    show-empty
                    thead-class="customer-header"
                    no-local-sorting
                    borderless
                    @sort-changed="detectSort"
                >
                    <template #empty>
                        <h4 class="text-center my-3">{{ $t('noData') }}</h4>
                    </template>
                    <template #head()="{ label }">
                        <div>{{ $t(label) }}</div>
                    </template>
                    <template #head(amount)>
                        <div>{{ $t('amount') }}</div>
                    </template>

                    <template #head(currency)>
                        <div>{{ $t('currency') }}</div>
                    </template>

                    <template #head(comment)>
                        <div>{{ $t('comment') }}</div>
                    </template>

                    <template #head(address)>
                        <div>{{ $t('address') }}</div>
                    </template>

                    <template #head(from)>
                        <div>{{ $t('from') }}</div>
                    </template>

                    <template #head(userId)>
                        <div>{{ $t('to') }}</div>
                    </template>

                    <template #head(createdAt)>
                        <div>{{ $t('time') }}</div>
                    </template>

                    <template #head(status)>
                        <div>{{ $t('status') }}</div>
                    </template>

                    <template #head(fee)>
                        <div>{{ $t('fee') }}</div>
                    </template>

                    <template #head(transaction)>
                        <div>{{ $t('transaction') }}</div>
                    </template>

                    <template #head(to)>
                        <div>{{ $t('to') }}</div>
                    </template>

                    <template #head(amount_from)>
                        <div>{{ $t('amountFrom') }}</div>
                    </template>

                    <template #head(amount_to)>
                        <div>{{ $t('amountTo') }}</div>
                    </template>

                    <template #head(rate)>
                        <div>{{ $t('rate') }}</div>
                    </template>

                    <template #head(recevied)>
                        <div>{{ $t('received') }}</div>
                    </template>

                    <template #head(action)>
                        <div>{{ $t('action') }}</div>
                    </template>

                    <template #head(claimed)>
                        <div>{{ $t('claimed') }}</div>
                    </template>

                    <template #cell(transaction)="data">
                        <a
                            v-if="data.item.transaction"
                            target="_blank"
                            :href="`${url}${data.item.transaction}`"
                        >
                            {{
                                data.item.transaction.slice(0, 5) +
                                '...' +
                                data.item.transaction.slice(
                                    data.item.transaction.length - 10,
                                    data.item.transaction.length,
                                )
                            }}
                        </a>
                    </template>
                    <template #cell(createdAt)="data">
                        {{
              Number.isInteger(data.item.createdAt) ? getDateTime(
                data.item.createdAt * 1000)
                : getDateTime3(data.item.createdAt),

                        }}
                    </template>
                    <template #cell(status)="data">
                        <span
                            :class="
                                data.item.status ? 'success' : 'text-danger'
                            "
                        >
                            {{ data.item.status ? 'Success' : 'Failed' }}
                        </span>
                    </template>
                    <template #cell(action)="data">
                        <div class="text-uppercase">
                            {{ data.item.action }}
                        </div>
                    </template>
                    <template #cell(currency)="{ value }">
                        <img
                            v-if="value === 'ECH' || value === 'bECH'"
                            src="@/assets/images/logo/ech.png"
                            width="30px"
                        />
                        <img
                            v-else
                            src="@/assets/images/logo/usdt.png"
                            width="30px"
                        />
                    </template>
                    <template #cell(amount)="{ value }">
                        {{ TruncateToDecimals2(value, '', 4) }}
                    </template>
                    <template #cell(expired)="data">
                        {{
              Number.isInteger(data.item.createdAt) ? getDateTime(
                data.item.createdAt * 1000)
                : getDateTime3(data.item.createdAt),

                        }}
                    </template>
                    <template #cell(fee)="{ item, value }">
                        {{ value }}
                        <template v-if="item.to">
                            <img
                                v-if="item.to === 'ECH' || item.to === 'bECH'"
                                src="@/assets/images/logo/ech.png"
                                width="30px"
                            />
                            <img
                                v-else
                                src="@/assets/images/logo/usdt.png"
                                width="30px"
                            />
                        </template>
                    </template>
                    <template #cell(from)="{ value }">
                        <template v-if="search.type !== 'commission'">
                            <img
                                v-if="value === 'ECH' || value === 'bECH'"
                                src="@/assets/images/logo/ech.png"
                                width="30px"
                            />
                            <img
                                v-else
                                src="@/assets/images/logo/usdt.png"
                                width="30px"
                            />
                        </template>
                        <template v-else>
                            {{ value }}
                        </template>
                    </template>
                    <template #cell(to)="{ value }">
                        <img
                            v-if="value === 'ECH' || value === 'bECH'"
                            src="@/assets/images/logo/ech.png"
                            width="30px"
                        />
                        <img
                            v-else
                            src="@/assets/images/logo/usdt.png"
                            width="30px"
                        />
                    </template>
                    <template #cell(comment)="{ value }">
                        <div class="comment">
                            {{ value }}
                        </div>
                    </template>
                    <template #cell(claimed)="{ value }">
                        <span :class="value === 0 ? 'text-danger' : 'success'">
                            {{ value === 0 ? 'Mined' : 'Claimed' }}
                        </span>
                    </template>
                    <template #cell(buy_date)="{ value }">
                        <span>
                            {{ getDateTime3(value) }}
                        </span>
                    </template>
                    <template #cell(price)="{ value }"> {{ value }}$ </template>
                </b-table>
            </div>
            <div class="d-lg-none mobile table-responsive mt-4">
                <b-table
                    striped
                    hover
                    :items="History.list"
                    :fields="myMobileFields[search.type]"
                    show-empty
                    thead-class="customer-header"
                    no-local-sorting
                    @sort-changed="detectSort"
                >
                    <template #empty>
                        <h4 class="text-center my-3">No Data</h4>
                    </template>
                    <template #row-details="{ item }">
                        <div class="details">
                            <div v-if="item.transaction" class="mobile-data">
                                <div>Transaction:</div>
                                <a
                                    target="_blank"
                                    :href="`${url}${item.transaction}`"
                                >
                                    <span v-if="item.transaction">
                                        {{
                                            item.transaction.slice(0, 5) +
                                            '...' +
                                            item.transaction.slice(
                                                item.transaction.length - 10,
                                                item.transaction.length,
                                            )
                                        }}
                                    </span>
                                </a>
                            </div>
                            <div
                                v-if="typeof item.fee === 'number'"
                                class="mobile-data"
                            >
                                <span>Fee: </span>
                                <span>
                                    {{ item.fee }}
                                    <template v-if="item.currency">
                                        <img
                                            v-if="
                                                item.currency === 'ECH' ||
                                                item.currency === 'bECH'
                                            "
                                            src="@/assets/images/logo/ech.png"
                                            width="30px"
                                        />
                                        <img
                                            v-else
                                            src="@/assets/images/logo/usdt.png"
                                            width="30px"
                                        />
                                    </template>
                                    <template v-else>
                                        <img
                                            v-if="
                                                item.to === 'ECH' ||
                                                item.to === 'bECH'
                                            "
                                            src="@/assets/images/logo/ech.png"
                                            width="30px"
                                        />
                                        <img
                                            v-else
                                            src="@/assets/images/logo/usdt.png"
                                            width="30px"
                                        />
                                    </template>
                                </span>
                            </div>
                            <div v-if="item.createdAt" class="mobile-data">
                                <div>Time of creation:</div>
                                <div>
                                    {{
                                        Number.isInteger(item.createdAt)
                                            ? getDateTime(item.createdAt * 1000)
                                            : getDateTime3(item.createdAt)
                                    }}
                                </div>
                            </div>
                            <div v-if="item.status" class="mobile-data">
                                <span>Status: </span>
                                <span
                                    :class="
                                        item.status ? 'success' : 'text-danger'
                                    "
                                >
                                    {{ item.status ? 'Success' : 'Failed' }}
                                </span>
                            </div>
                            <div v-if="item.comment" class="mobile-data">
                                <span>Comment: </span>
                                <span class="font-weight-bold">
                                    {{ item.comment }}
                                </span>
                            </div>
                            <div v-if="item.currency" class="mobile-data">
                                <span>Currency: </span>
                                <span>
                                    <img
                                        v-if="
                                            item.currency === 'ECH' ||
                                            item.currency === 'bECH'
                                        "
                                        src="@/assets/images/logo/ech.png"
                                        width="18px"
                                    />
                                    <img
                                        v-else
                                        src="@/assets/images/logo/usdt.png"
                                        width="18px"
                                    />
                                </span>
                            </div>
                            <div v-if="item.packageName" class="mobile-data">
                                <span> {{ $t('packageName') }}: </span>
                                <span>
                                    {{ item.packageName }}
                                </span>
                            </div>
                            <div v-if="item.buy_date" class="mobile-data">
                                <span> {{ $t('boughtDate') }}: </span>
                                <span>
                                    {{ getDateTime3(item.buy_date) }}
                                </span>
                            </div>
                        </div>
                    </template>
                    <template #cell(action)="data">
                        <div class="text-uppercase">
                            {{ data.item.action }}
                        </div>
                    </template>
                    <template #cell(status)="data">
                        <span
                            :class="
                                data.item.status ? 'success' : 'text-danger'
                            "
                        >
                            {{ data.item.status ? 'Success' : 'Failed' }}
                        </span>
                    </template>
                    <template #cell(#)="row">
                        <b-button
                            size="sm"
                            variant="info"
                            @click="row.toggleDetails"
                            class="px-2 py-0"
                        >
                            {{ row.detailsShowing ? 'Hide' : 'Show' }}
                        </b-button>
                    </template>
                    <template #cell(currency)="{ value }">
                        <img
                            v-if="value === 'ECH' || value === 'bECH'"
                            src="@/assets/images/logo/ech.png"
                            width="30px"
                        />
                        <img
                            v-else
                            src="@/assets/images/logo/usdt.png"
                            width="30px"
                        />
                    </template>
                    <template #cell(amount)="{ value }">
                        {{ TruncateToDecimals2(value, '', 4) }}
                    </template>
                    <template #cell(from)="{ value }">
                        <template v-if="search.type !== 'commission'">
                            <img
                                v-if="value === 'ECH' || value === 'bECH'"
                                src="@/assets/images/logo/ech.png"
                                width="30px"
                            />
                            <img
                                v-else
                                src="@/assets/images/logo/usdt.png"
                                width="30px"
                            />
                        </template>
                        <template v-else>
                            {{ value }}
                        </template>
                    </template>
                    <template #cell(to)="{ value }">
                        <img
                            v-if="value === 'ECH' || value === 'bECH'"
                            src="@/assets/images/logo/ech.png"
                            width="30px"
                        />
                        <img
                            v-else
                            src="@/assets/images/logo/usdt.png"
                            width="30px"
                        />
                    </template>
                    <template #cell(comment)="{ value }">
                        <div class="comment">
                            {{ value }}
                        </div>
                    </template>
                    <template #cell(price)="{ value }"> {{ value }}$ </template>
                </b-table>
            </div>
        </div>
        <div class="table-paginate">
            <!-- <Paginate @current="onChangePage" :totalPages="History.total" /> -->

            <b-pagination
                v-model="search.page"
                :total-rows="History.totalResults"
                :per-page="search.limit"
                aria-controls="table-activity"
                pills
                align="right"
                id="table-pagination-activity"
            ></b-pagination>
        </div>
    </b-container>
</template>

<style lang="scss" scoped>
.mi-input {
    display: flex;
    align-items: center;
    gap: 5px;
    padding-left: 30px;

    .wrap {
        position: relative;

        > input {
            border: none;
            background-color: #cbd5e1;
            border-radius: 6px;
            color: #111827;
            flex-grow: 1;
            min-width: 0;
            padding: 8px 15px;
        }

        .submit {
            position: absolute;
            top: 2px;
            background-color: #078c04;
            bottom: 2px;
            right: 2px;
            width: 40px;
            padding: 0;
            border-radius: 6px;
            color: white;
        }
    }
}

.activity {
    padding: 30px 15px;
    display: grid;
    gap: 40px;
    background: linear-gradient(#2c572d, #2c572d) padding-box,
        linear-gradient(to left, #a6ed8d, #078c04) border-box;
    border-radius: 8px;
    border: 2px solid transparent;
    position: relative;
    color: white;

    &__heading {
        display: flex;
        justify-content: space-between;
        gap: 16px;

        &--sub {
            color: #aaff95;
            font-weight: 600;
            font-size: 18px;
        }
    }
    @media (min-width: 768px) {
    padding: 32px 74px;
        
    }
}

.group-hist {
    display: flex;
    justify-content: flex-start;
    margin: 0 0 15px;
    padding-right: 5px;
    position: relative;

    .select-type {
        max-width: 275px;
        height: 48px;
        text-transform: capitalize;
    }

    .b-form-datepicker,
    select {
        max-width: 275px;
        height: 40px;
        background: linear-gradient(#234524, #234524) padding-box,
            linear-gradient(to bottom, #7fdf44, #36790e) border-box;
        color: #fff;
        border-radius: 100px;
        border: none;

        option {
            background: #234524;

            &:hover {
                box-shadow: 0 0 10px 100px #36790e inset;
            }
        }

        button {
            &#datepicker,
            &#datepicker-2 {
                color: #fff;

                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;

                color: #dcdcdc;
            }
        }

        label.form-control {
            color: #fff !important;
            display: flex;
            align-items: center;
        }
    }

    img {
        height: 15px;
        width: auto;
        position: absolute;
        right: 7px;
        bottom: 0;
        top: 0;
        margin: auto;
        display: none;
    }
}
</style>

<style lang="scss">
.table-striped tbody tr {
    border-radius: 8px;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: #16391a;
    border-radius: 8px;
    color: #aaff95;
    padding: 16px 32px;
}

.table-striped tbody tr:nth-of-type(even) {
    background-color: transparent;
    color: white;
    padding: 16px 32px;
}

.table th,
.table td {
    padding: 16px 32px;
    vertical-align: middle;
    text-align: center;
}

table {
    thead {
        tr {
            th {
                div {
                    color: white;
                    font-weight: 500;
                    font-size: 14px;
                    display: inline-block;
                    text-wrap: nowrap;
                }
            }
        }
    }

    tbody {
        tr {
            td {
                font-size: 16px;
                font-weight: 500;
                text-wrap: nowrap;
                .mobile-data {
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    line-height: 1;
                    padding: 5px 0px;
                }
            }
            &:nth-of-type(odd) {
                td {
                    .mobile-data {
                        > *:last-child {
                            color: #fff;
                        }
                    }
                }
            }
            &:nth-of-type(even) {
                td {
                    .mobile-data {
                        > *:last-child {
                            color: #aaff95;
                        }
                    }
                }
            }
        }
    }
}

.form-control:focus {
    box-shadow: 0 0 0 0.2rem rgb(24, 94, 0, 0.5);
}

#table-pagination-activity {
    .page-item.active .page-link {
        background-color: #36f27e;
        border-color: #36f27e;
    }

    .page-link {
        color: #36f27e;
        font-weight: 600;
    }

    .page-item.active .page-link {
        z-index: 1;
        color: #fff;
        background-color: #36f27e;
        border-color: #36f27e;
    }
}
</style>
