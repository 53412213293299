/* eslint-disable import/no-cycle */
/* eslint-disable no-shadow */
import store from '@/store';

const axios = require('../axios.config').default;

export default {
    namespaced: true,
    state: () => ({
        address: null,
        info: {
            email: null,
            address: null,
        },
        balance: 0,
        xnode: {},
        master: null,
        banner: null
    }),

    getters: {
        Address: (state) => state.address,
        UserInfo: (state) => state.info,
        Balance: (state) => state.balance,
        XNode: (state) => state.xnode,
        Master: (state) => state.master,
        Banner: (state) => state.banner
    },

    actions: {
        req_getInfo: async ({ commit }) => {
            return axios.get('user/info').then((res) => {
                if (res && res.data) {
                    commit('GET_INFO_SUCCESS', res.data);
                    return res;
                }
                return false;
            });
        },
        get_refreshBalance: ({ dispatch }, input) => {
            if (input === 'ECH') {
                axios.get(`/user/refresh-xin`).then(() => {
                    dispatch('req_getInfo');
                });
            } else {
                axios.get(`/user/refresh-usd`).then(() => {
                    dispatch('req_getInfo');
                });
            }
        },
        async buyMana(context, input) {
            return axios.post('/user/buy-mana', input).then((res) => {
                console.log(res);
            });
        },
        get_masterXNode: async ({ commit }) => {
            return axios.get('masternode/info').then((res) => {
                if (res && res.status) {
                    commit('SET_MASTER', res.data);
                }
                return res.status;
            });
        },
        get_bannerInfo: async ({ commit }) => {
            return axios.get('promotion/info').then((res) => {
                if (res) {
                    commit('SET_BANNER', res.data);
                }
                return res.status;
            });
        },
        post_buyPromotion: async ({ commit }, input) => {
            return axios.post('promotion/buy', {
              promotionId: input.id
            }).then((res) => {
                return res;
            });
        }
    },
    mutations: {
        SET_ADDRESS: (state, data) => {
            state.address = data.address;
        },
        GET_INFO_SUCCESS: (state, data) => {
            state.info = data;
            state.balance =
                Math.round(parseFloat(data.ECH) * 10000) / 10000;
            if (state.info.unknownUser) state.unKnown = true;
            store.commit('wallet/setupWallet', data.address);
        },
        SET_XNODE: (state, data) => {
            state.xnode = data;
        },
        SET_MASTER: (state, data) => {
            state.master = data;
        },
        SET_BANNER: (state, data) => {
            state.banner = data;
        },
    },
};
