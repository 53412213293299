<script>
export default {
    data() {
        return {
            email: null
        };
    },

    methods: {
        forgotPassword() {
            if (!this.email) {
                this.$toastr.e('"Email" is not allowed to be empty', 'Oops!');
                return
            }
            this.$store.dispatch('auth/forgotPassword', { email: this.email }).then(() => {
                this.$router.push({ name: 'Login' })
            })
        }
    },
};
</script>

<template>
    <div class="forgot-container">
        <form @submit.prevent>
            <div class="img">
                <img src="../../../assets/images/logo/logoV3.png" alt="" />
            </div>
            <div class="inputs">
                <input type="email" :placeholder="$t('email')" v-model="email" autocomplete="email" id="email" />
            </div>
            <div class="buttons">
                <button :class="{ pending: pending }" @click="forgotPassword">{{ $t('confirm') }}</button>
            </div>
            <p>
                {{ $t('alreadyHaveAccount') }}
                <router-link class="router-link" to="/login"><strong>{{ $t('login') }}</strong></router-link>
            </p>
        </form>
    </div>
</template>
<style lang="scss">
.forgot-container {
    background: linear-gradient(to right,#5BBE67, #2A5830);
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    form {
        flex-basis: 400px;

        .img {
            display: flex;
            justify-content: center;

            >img {
                max-width: 100%;
                width: 200px;
            }
        }

        .inputs {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            margin: 0 auto;
            margin-top: 1.5rem;

            .input-pw {
                position: relative;

                input {
                    width: 100%;
                    background-color: #2C562D;
                }

                img {
                    position: absolute;
                    top: 50%;
                    right: 1rem;
                    transform: translateY(-50%);
                    width: 24px;
                    cursor: pointer;
                }
            }

            input {
                padding: 0 1rem;
                border: 1px solid #A6ED8D;
                border-radius: 8px;
                height: 40px;
                background-color: #2C562D;
                color: #fff;
            }

            input:focus-visible {
                outline: none;
            }

            input::placeholder {
                color: #fff;
                font-size: 18px;
                font-style: italic;
                font-weight: 400;
            }
        }

        .buttons {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 1.5rem;
            gap: 1rem;

            button {
                padding-top: 1rem;
                padding-bottom: 1rem;
                width: 70%;
                border-radius: 72px;
                text-transform: uppercase;
                font-size: 18px;
                transition: 0.5s;

                &:hover {
                    opacity: 0.5;
                }

                &.pending {
                    filter: grayscale(1);
                }
            }

            button:nth-child(1) {
                background: linear-gradient(to left, #8BF04D, #2E6D08);
                color: #fff;
                font-weight: 600;
            }

            button:nth-child(2) {
                border: 1px solid #AAFF95;
                color: #AAFF95;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 7px;

                font-weight: 600;
            }
        }

        p {
            margin-top: 1rem;
            text-align: center;
            color: #fff;
            font-size: 18px;

            .router-link {
                transition: 0.5s;

                &:hover {
                    color: #fff;
                    opacity: 0.5;
                }
            }
        }

        .forgot {
            display: flex;
            justify-content: center;
            margin-top: 1rem;
            font-size: 18px;
        }
    }

    @media (max-width: 1201px) {
        form {
            .inputs {
                width: 100%;
            }
        }
    }

    @media (max-width: 990px) {
        form {
            .inputs {
                input::placeholder {
                    font-size: 16px;
                }

                .input-pw {
                    img {
                        width: 25px;
                    }
                }
            }

            .buttons {
                button {
                    width: 100%;
                    font-size: 16px;
                }
            }

            p {
                font-size: 16px;
            }
        }
    }

    @media (max-width: 376px) {
        padding: 5rem 1rem 3rem 1rem;
    }
}
</style>
