<template>
  <div class="xnode">
    <div class="xnode__container">
      <div class="xnode__heading">
        <div class="heading__secondary">
          <div>
            Buy Node
          </div>
        </div>
      </div>

      <div class="xnode__content" v-if="ListPackage">
        <XNodeItem v-for="({ id, presaleId, name, token, price, discountPrice }, index) in ListPackage" :key="presaleId"
          :name="name" :token="token" :price="price" :discountPrice="discountPrice" :idNode="`xnode-confirm-${index}`"
          :presaleId="presaleId" :packageId="id" />
      </div>
    </div>
  </div>
</template>

<script>
import XNodeItem from '@/components/presale/XNodeItem.vue';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      nodes: [

        {
          id: 1,
          key: 'xnode-confirm-1',
          title: "XNode1",
          desc: "This is xnode 1",
          price: 500
        },
        {
          id: 2,
          key: 'xnode-confirm-2',
          title: "XNode2",
          desc: "This is xnode 2",
          price: 1000
        },
        {
          id: 3,
          key: 'xnode-confirm-3',
          title: "XNode3",
          desc: "This is xnode 3",
          price: 2000
        },
      ]
    }
  },
  components: {
    XNodeItem
  },
  computed: {
    ...mapGetters({
      ListPackage: 'presale/ListPackage'
    })
  },
  mounted() {
    this.$store.dispatch('presale/req_getListPackage');
  }
}
</script>

<style lang="scss" scoped>
.heading__secondary {
  font-size: 22px;
  font-weight: 600;
  color: #fff;
}

.xnode {
  padding: 32px 74px;
  gap: 40px;
  background: linear-gradient(#2c572d, #2c572d) padding-box,
    linear-gradient(to left, #A6ED8D, #078C04) border-box;
  border-radius: 8px;
  border: 2px solid transparent;
  position: relative;
  color: white;

  @media screen and (max-width: 768px) {
    padding: 32px 32px;

  }

  &__heading {
    display: flex;
    justify-content: space-between;
    gap: 16px;

    &--sub {
      color: #AAFF95;
      font-weight: 600;
      font-size: 18px;
    }
  }

  &__container {
    display: grid;
    gap: 16px;
  }

  &__content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;

    @media screen and (max-width: 1024px) {
      grid-template-columns: 1fr;
    }
  }
}
</style>
