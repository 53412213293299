<template>
    <div class="XIN bg-main-container">
        <div class="container-effect2">
            <!-- <div id="stars20"></div>
            <div id="stars21"></div>
            <div id="stars22"></div>
            <div id="stars32"></div> -->
        </div>
        <Teleport to="body">
            <loader />
        </Teleport>

        <div class="grid-layout">
            <main id="mainContent">
                <StringBanner></StringBanner>
                <router-view />
            </main>
            <MyHeader id="header" />
            <MyNavigation id="navigation" />
        </div>
        <NotificationModal />
        <UpdateSponsor></UpdateSponsor>
        <LanguageModal></LanguageModal>
    </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
// import Web3 from 'web3';
import loader from '@/components/shared/Loader.vue';
import Teleport from 'vue2-teleport';
import MyHeader from '@/components/Layouts/MyHeader.vue';
import MyNavigation from '@/components/Layouts/MyNavigation.vue';
import NotificationModal from '@/components/shared/NotificationModal.vue';
import UpdateSponsor from '@/components/account/UpdateSponsor.vue';
import StringBanner from '@/components/shared/StringBanner.vue';
import LanguageModal from '@/components/shared/LanguageModal.vue';

export default {
    components: {
        loader,
        Teleport,
        MyHeader,
        MyNavigation,
        NotificationModal,
        UpdateSponsor,
        StringBanner,
        LanguageModal
    },
    computed: {
        ...mapGetters({
            isNetWork: 'contract/isNetWork',
            UnKnown: 'info/UnKnown',
            UserInfo: 'info/UserInfo',
        }),
        ChainID() {
            if (this.isNetWork === 'testnet') {
                return ['0x61', '97'];
            }
            return ['0x38', '56'];
        },
        netWorkConfig() {
            const testNet = {
                chainId: '0x61',
                chainName: 'Smart Chain - Testnet',
                nativeCurrency: {
                    name: 'Binance Coin',
                    symbol: 'BNB',
                    decimals: 18,
                },
                rpcUrls: [
                    'https://data-seed-prebsc-2-s2.binance.org:8545/',
                    'https://data-seed-prebsc-1-s1.binance.org:8545/',
                    'https://data-seed-prebsc-5-s5.binance.org:8545/',
                    'https://data-seed-prebsc-3-s3.binance.org:8545/',
                    'https://data-seed-prebsc-4-s4.binance.org:8545/',
                ],
                blockExplorerUrls: ['https://testnet.bscscan.com'],
            };
            const mainNet = {
                chainId: '0x38',
                chainName: 'Smart Chain',
                nativeCurrency: {
                    name: 'Binance Coin',
                    symbol: 'BNB',
                    decimals: 18,
                },
                rpcUrls: [
                    'https://bsc-dataseed.binance.org/',
                    'https://bsc-dataseed1.defibit.io/',
                    'https://bsc-dataseed1.ninicoin.io/',
                ],
                blockExplorerUrls: ['https://bscscan.com'],
            };
            if (this.isNetWork === 'testnet') {
                return testNet;
            }
            return mainNet;
        },
    },
    methods: {
        ...mapMutations(['onLoad', 'outLoad']),
        // async checkWallet() {
        //     // return;
        //     this.onLoad();
        //     if (!window.ethereum) {
        //         this.outLoad();
        //         this.$toastr.e('Please Install Metamask To Join', 'Oops!');
        //         return false;
        //     }
        //     let addrs = [];
        //     try {
        //         addrs = await window.ethereum.request({
        //             method: 'eth_requestAccounts',
        //         });
        //     } catch (error) {
        //         this.outLoad();
        //         if (
        //             error.message ===
        //             'Already processing eth_requestAccounts. Please wait.'
        //         ) {
        //             this.$toastr.e('Please Connect Metamask To Join', 'Oops!');
        //             return false;
        //         }
        //         this.$toastr.e(error.message, 'Oops!');
        //         return false;
        //     }
        //     if (!this.ChainID.includes(window.ethereum.chainId)) {
        //         const params = this.netWorkConfig;
        //         window.ethereum
        //             .request({
        //                 method: 'wallet_addEthereumChain',
        //                 params: [params],
        //             })
        //             .then(() => console.log('Success'))
        //             .catch(() => {
        //                 this.showNotification = true;
        //                 this.message = {
        //                     content:
        //                         'Please switch To The  Binance Smartchain Network!',
        //                     failed: true,
        //                     title: 'Oops...',
        //                 };
        //                 window.ethereum.request({
        //                     method: 'wallet_switchEthereumChain',
        //                     params: [{ chainId: this.ChainID[0] }],
        //                 });
        //             });
        //     }

        //     if (addrs.length > 0) {
        //         this.$store.commit(
        //             'wallet/setupWallet',
        //             Web3.utils.toChecksumAddress(addrs[0]),
        //         );
        //     }
        //     this.outLoad();
        //     return addrs;
        // },
        // setup(address) {
        //     this.$store.commit('wallet/clearWallet');
        //     this.$store.commit(
        //         'wallet/updateWallet',
        //         Web3.utils.toChecksumAddress(address),
        //     );
        // },
    },
    // async mounted() {
    //     const web3 = await this.$store.state.contract.web3;
    //     console.log(web3);
    //     if (web3) {
    //         this.$store.commit('contract/setupTokens');
    //         this.$store.commit('contract/setupManagers');
    //         this.$store.commit('wallet/updateWallet');
    //     }
    //     this.$store.watch(
    //         (state) => state.info.info,
    //         (user) => {
    //             this.setup(user.address);
    //         },
    //     );
    // },
    // async created() {
    //     if (window.ethereum) {
    //         // eslint-disable-next-line no-unused-vars
    //         window.ethereum.on('accountsChanged', async (accounts) => {
    //             // window.location.reload();
    //             // this.$store.commit('auth/LOGOUT_SUCCESS');
    //         });

    //         await this.checkWallet();
    //         // eslint-disable-next-line no-unused-vars
    //         window.ethereum.on('chainChanged', async (chainId) => {
    //             // window.location.reload();
    //             // this.$store.commit('auth/LOGOUT_SUCCESS');
    //         });
    //     }
    //     // document.body.addEventListener('scroll', this.onScroll);
    // },
    // destroyed() {
    //     document.body.removeEventListener('scroll', this.onScroll);
    // },
    mounted() {
        this.$store.dispatch('wallet/get_rates');

        const userRaw = this.$store.getters['auth/loggedIn'];
        if (userRaw !== undefined && userRaw !== null) {
            this.$store.dispatch('info/req_getInfo');
        }
    },
};
</script>

<style lang="scss">
.XIN.bg-main-container {
    background-color: #fdfdfd;
}
.grid-layout {
    display: grid;
    grid-template-areas:
        'header'
        'content';
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: auto minmax(0, 1fr);

    min-height: 100vh;
    #header {
        grid-area: header;
    }
    #mainContent {
        grid-area: content;
        padding-bottom: 100px;
        overflow: hidden;
        background-color: #08270c;
    }
    @media (min-width: 768px) {
        grid-template-areas:
            'navi header'
            'navi content';
        grid-template-columns: minmax(0, 80px) minmax(0, 1fr);
        #navigation {
            grid-area: navi;
        }
    }
    @media (min-width: 992px) {
        grid-template-columns: minmax(0, 240px) minmax(0, 1fr);
    }
}
</style>
