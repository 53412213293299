var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"xnode"},[_c('div',{staticClass:"xnode__container"},[_vm._m(0),(_vm.ListPackage)?_c('div',{staticClass:"xnode__content"},_vm._l((_vm.ListPackage),function(ref,index){
var id = ref.id;
var presaleId = ref.presaleId;
var name = ref.name;
var token = ref.token;
var price = ref.price;
var discountPrice = ref.discountPrice;
return _c('XNodeItem',{key:presaleId,attrs:{"name":name,"token":token,"price":price,"discountPrice":discountPrice,"idNode":("xnode-confirm-" + index),"presaleId":presaleId,"packageId":id}})}),1):_vm._e()])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"xnode__heading"},[_c('div',{staticClass:"heading__secondary"},[_c('div',[_vm._v(" Buy Node ")])])])}]

export { render, staticRenderFns }