<template>
  <div class="buyer">
    <div class="buyer__heading">
      <p class="heading__secondary">
        <!-- {{ $t('vesting.claimHistory') }} -->
        {{ $t('vestingList.listVestings') }}
      </p>
    </div>

    <div class="buyer__cont">
      <b-table id="table-vesting" v-if="ListInfoClaimed" striped hover :items="ListInfoClaimed.results" borderless :fields="fields" responsive show-empty>
        <template #head(claim)="data">
          <div>
            {{ $t('vestingList.Claimed') }}
          </div>
        </template>

        <template #head(price)="data">
          <div>
            {{ $t('vestingList.Price') }}
          </div>
        </template>

        <template #head(distributeToken)="data">
          <div>
            {{ $t('vestingList.Distribute') }}
          </div>
        </template>

        <template #head(status)="data">
          <div>
            {{ $t('vestingList.Status') }}
          </div>
        </template>

        <template #head(created_at)="data">
          <div>
            {{ $t('vestingList.createdAt') }}
          </div>
        </template>

        <template #head(presale_id)="data">
          <div>
            {{ $t('vestingList.Presale') }}
          </div>
        </template>

        <template #cell(status)="data">
          <div :class="data.value === 'claimed' ? 'span-container__success' : 'span-container__error'">
            <img src="@/assets/images/icons/success-new.svg" alt="success" />
            <span :class="data.value === 'claimed' ? 'span-success' : 'span-pending'">
              {{
                $t(`${data.value}`)
              }}
            </span>
          </div>
        </template>

        <template #cell(price)="data">
          <div class="coin-container">
            <p>
              {{
                TruncateToDecimals2(data.value)
              }}
            </p>
            <img src="@/assets/images/icons/token-table.svg" alt="usdt" />
          </div>
        </template>

        <template #cell(token)="data">
          <div class="coin-container">
            <p>
              {{
                TruncateToDecimals2(data.value)
              }}
            </p>
            <img src="@/assets/images/icons/token-table.svg" alt="usdt" />
          </div>
        </template>

        <template #cell(user_before_token)="data">
          <div class="coin-container">
            <p>
              {{
                TruncateToDecimals2(data.value)
              }}
            </p>
            <img src="@/assets/images/icons/token-table.svg" alt="usdt" />
          </div>
        </template>

        <template #cell(user_current_token)="data">
          <div class="coin-container">
            <p>
              {{
                TruncateToDecimals2(data.value)
              }}
            </p>
            <img src="@/assets/images/icons/token-table.svg" alt="usdt" />
          </div>
        </template>

        <template #cell(created_at)="data">
          <div class="coin-container">
            <p v-if="data.value">
              {{
                getDateTime3(data.value)
              }}
            </p>
            <p v-else>
              N/A
            </p>
          </div>
        </template>
        <template #cell(claim)="data">
          <div class="claim">
            <button class="btn__primary" :disabled="data.item.status === 'claimed'" :class="data.item.status === 'claimed' ? 'd-disabled' : 'd-active'" @click="() => {
              openModalConfirm(data.item.id);
            }">
              Claim
            </button>
          </div>
        </template>
      </b-table>

      <b-pagination
          v-if="ListInfoClaimed"
          v-model="currentPage"
          :total-rows="ListInfoClaimed.totalResults"
          :per-page="perPage"
          aria-controls="table-vesting"
          pills
          align="right"
          id="table-pagination-vesting"
        ></b-pagination>
    </div>

    <b-modal id="claim-confirm" centered hide-footer hide-header size="md">
      <svg @click="$bvModal.hide('claim-confirm')" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em"
        viewBox="0 0 24 24" class="icon-close">
        <path fill="currentColor"
          d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12z" />
      </svg>
      <div class="buy__confirm">
        <p>
          {{ $t('presale.areYouSure') }}
        </p>

        <div class="buy__buttons">
          <button class="btn__secondary" @click="claimAction">
            {{ $t('presale.confirm') }}
          </button>
          <button class="btn__secondary" @click="$bvModal.hide('claim-confirm')">
            {{ $t('presale.cancel') }}
          </button>
        </div>
      </div>
    </b-modal>
  </div>

</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      fields: [
        {
          key: 'id',
          label: 'Id',
        },
        {
          key: 'claim',
          label: 'Claim',
        },
        {
          key: 'price',
          label: 'Price'
        },
        {
          key: 'token',
          label: 'Token',
        },
        {
          key: 'distributeToken',
          label: 'Distribute',
        },
        {
          key: 'status',
          label: 'Status',
        },
        {
          key: 'presale_id',
          label: 'Presale Id',
        },
        {
          key: 'created_at',
          label: 'Created At',
        },
      ],
      vestingId: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
    };
  },
  computed: {
    ...mapGetters({
      UserInfo: 'info/UserInfo',
      HistoryVesting: 'presale/HistoryVesting',
      ListInfoClaimed: 'presale/ListInfoClaimed',
    }),

    params() {
      return {
        // type: 'group',
        // presaleId: this.PresaleInfo._id,
        page: this.currentPage,
        limit: this.perPage,
      };
    }
  },
  mounted() {
    this.$store.dispatch('presale/req_getHistoryVesting', this.params);
    this.$store.dispatch('presale/req_getListInfoClaimed', this.params);
  },
  methods: {
    claimAction() {
      this.$store.dispatch('presale/req_postNewClaimToken', {
        vestingId: this.vestingId
      }).then(() => {
        this.$store.dispatch('info/req_getInfo');
        this.$store.dispatch('presale/req_getListInfoClaimed', this.params);
      });
      this.$bvModal.hide('claim-confirm');
    },
    openModalConfirm(id) {
      this.$bvModal.show('claim-confirm');
      this.vestingId = id;
    }
  },
  watch: {
    PresaleInfo() {
      this.$store.dispatch('presale/req_getPurchaseHistory', this.params);
    },

    currentPage() {
      this.$store.dispatch('presale/req_getPurchaseHistory', this.params);
      this.$store.dispatch('presale/req_getListInfoClaimed', this.params);
    },
  }
};
</script>

<style scoped lang="scss">
.heading__secondary {
  font-size: 22px;
  font-weight: 600;
  color: #fff;
}

.d-none {
  display: none;
}

.d-disabled {
  cursor: not-allowed;
  opacity: 0.4;
  background: #2c572d;
}

.d-active {
  background: linear-gradient(to left, #8BF04D, #2E6D08);
  cursor: pointer;
  opacity: 1;
}

.btn__primary {
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  padding: 4px 8px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  outline: none;

  p {
    margin-bottom: 0;
  }
}

.btn__secondary {
  background: linear-gradient(to left, #8BF04D, #2E6D08);
  color: #fff;
  font-weight: 600;
  font-size: 14px;
  padding: 8px 16px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  outline: none;

  p {
    margin-bottom: 0;
  }
}

.buyer {
  padding: 32px;
  display: grid;
  gap: 40px;
  background: linear-gradient(#2c572d, #2c572d) padding-box,
    linear-gradient(to left, #A6ED8D, #078C04) border-box;
  border-radius: 8px;
  border: 2px solid transparent;
  position: relative;
  color: white;

  &__heading {
    display: flex;
    justify-content: space-between;
    gap: 16px;

    &--sub {
      color: #AAFF95;
      font-weight: 600;
      font-size: 18px;
    }
  }

}

.span-container__success {
  background-color: #87E14F;
  padding: 4px 8px;
  border-radius: 8px;
  margin: auto 0;
  width: fit-content;
  display: flex;
  gap: 4px;
}
.span-container__error {
  background-color: #FFD700;
  padding: 4px 8px;
  border-radius: 8px;
  margin: auto 0;
  width: fit-content;
  display: flex;
  gap: 4px;
}

.span-success {
  color: #08270C;
  font-size: 12px;
}

.span-pending {
  color: #713f12;
  font-size: 12px;
}

.coin-container {
  color: white;
  display: flex;
  gap: 12px;
  align-items: center;

  p {
    margin-bottom: 0;
    font-weight: 500;
    font-size: 14px;
  }
}

@media screen and (max-width: 500px) {
  .buyer {
    gap: 24px;
    padding: 12px 24px;
  }
}
</style>

<style lang="scss">
.table-striped tbody tr {
  border-radius: 8px;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #16391A;
  border-radius: 8px;
  color: #AAFF95;
  padding: 16px 32px;
}

.table-striped tbody tr:nth-of-type(even) {
  background-color: transparent;
  color: white;
  padding: 16px 32px;
}

.table th,
.table td {
  padding: 16px 32px;
  vertical-align: middle;
  text-align: center;
}

table {
  thead {
    tr {
      th {
        div {
          color: white;
          font-weight: 500;
          font-size: 16px;
          display: inline-block;
          text-wrap: nowrap;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        font-size: 14px;
        font-weight: 500;
        text-wrap: nowrap;
      }
    }
  }
}
</style>

<style lang="scss">
#table-pagination-vesting {
  .page-item.active .page-link {
    background-color: #36f27e;
    border-color: #36f27e;
  }

  .page-link {
    color: #36f27e;
    font-weight: 600;
  }

  .page-item.active .page-link {
    z-index: 1;
    color: #fff;
    background-color: #36f27e;
    border-color: #36f27e;
  }
}
</style>
